import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import UserImportForm from "./UserImportForm";
import Modal from "react-bootstrap/Modal";

const UserImportModal = ({preloader, isOpen, onHide}) => {
	
	return (
		
		<Modal
			className={'UserImportModal'}
			show={Boolean(isOpen)}
			onHide={() => {
				onHide()
			}}
			size={'lg'}
			// backdrop={'static'}
			// keyboard={false}
		>
			
			<Modal.Header closeButton>
				<Modal.Title>
					{i18next.t(`Import students`)}
				</Modal.Title>
			</Modal.Header>
			
			<Modal.Body>
			
				<UserImportForm
					preloader={preloader}
					onCancel={() => {
						onHide()
					}}
					cancelBtnText={i18next.t(`Закрыть`)}
				/>
			
			</Modal.Body>
			
		</Modal>
		
	)
	
}

UserImportModal.propTypes = {}

export default UserImportModal