import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import FormDataHelper from "../../helpers/FormDataHelper";
import Utils from "../../helpers/Utils";
import axios from "axios";
import Logger from "../../helpers/Logger";
import Form from 'react-bootstrap/Form'
import FilePicker from "./FilePicker";
import importStudentsXlsxFileExample from '../../docs/import-students-example.xlsx'
import importStudentsCsvFileExample from '../../docs/import-students-example.csv'
import FoldableList from "./FoldableList";

const UserImportForm = ({preloader, onSuccess = () => {}, onError = () => {}, onCancel, cancelBtnText = 'Cancel'}) => {
	
	const filesRef = useRef()
	
	const [files, setFiles] = useState([])
	const [importIsDone, setImportIsDone] = useState(0)
	const [techReportData, setTechReportData] = useState(null)
	const [logMaterialId, setLogMaterialId] = useState()
	
	return (
		
		<form
			className={`UsersImportForm`}
			onSubmit={(event) => {
				
				const logName = 'UserImportForm.submit'
				const logAllow = 1
				const logCollapsed = 0
				
				Logger.groupStart(logName, logAllow, logCollapsed)
				
				event.preventDefault()
				
				let formData = new FormData()
				
				FormDataHelper.addFilesFromRef(formData, 'files', filesRef)
				
				let formDataValues = FormDataHelper.getValues(formData)
				Logger.log(formDataValues, 'formDataValues', logAllow)
				
				setImportIsDone(0)
				setTechReportData(null)
				
				preloader?.show()
				
				axios({
					method: 'post',
					url: Utils.apiUrl('users/import'),
					data: formData,
					params: {
						'accessToken': Utils.getUserToken(),
					},
				}).then((response) => {
					
					const logName = 'UserImportForm.submit.ajax.done'
					const logAllow = 1
					const logCollapsed = 0
					
					Logger.groupStart(logName, logAllow, logCollapsed)
					
					preloader?.hide()
					
					Logger.log(response, 'response', logAllow)
					
					// const techReportData = response.data?.importResults
					// Logger.log(techReportData, 'techReportData', logAllow)
					// setTechReportData(techReportData)
					
					setImportIsDone(1)
					setLogMaterialId(response.data)
					
					Logger.groupEnd(logAllow)
				
				}).catch((error) => {
					
					preloader?.hide()
					
					console.log(error)
					window.alert(error)
					
				})
				
				Logger.groupEnd(logAllow)
			
			}}
		>
			
			<div className={`alert alert-info`}>
				<p><b>{i18next.t(`Внимание!`)}</b></p>
				<p>Импорт работает только с файлами <a className={`bold`} href={'https://ru.wikipedia.org/wiki/CSV'} target={'_blank'}>CSV</a></p>
				<p>Любой файл Excel может быть сохранён как CSV при помощи любой программы, открывающей файлы Excel</p>
				<p>
					Бесплатные программы для работы с Excel:
					<ul>
						<li><a href="https://www.onlyoffice.com/ru/desktop.aspx" target={'_blank'}>ONLYOFFICE</a></li>
						<li><a href="https://ru.libreoffice.org/" target={'_blank'}>LibreOffice</a></li>
						{/*<li><a href="https://www.onlyoffice.com/ru/desktop.aspx">Мой Офис (бесплатно, Россия)</a></li>*/}
					</ul>
				</p>
				<p>
					<a href={importStudentsXlsxFileExample} target={'_blank'} download={'StudeRus - ' + i18next.t(`Import students`) + '.xlsx'}>Пример Excel файла</a>
					<br/><a href={importStudentsCsvFileExample} target={'_blank'} download={'StudeRus - ' + i18next.t(`Import students`) + '.csv'}>Пример CSV файла</a>
				</p>
			</div>
			
			
			<fieldset className={`files`}>
				
				<legend>{i18next.t(`Choose files`)}</legend>
				
				<input
					type={'file'}
					multiple={true}
					accept={[
						// 'application/vnd.ms-excel',
						// 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'.csv',
					].join(', ')}
					ref={filesRef}
					required={true}
					onChange={() => {
						
						if (filesRef.current) {
							setFiles(filesRef.current.files)
						}
						
						setTechReportData(null)
						setImportIsDone(0)
						
					}}
				/>
				
				{(files.length > 1) && (
					<ol className={'files-list my-4'}>
						{Array.from(files).map((file) => {
							return (
								<li>{file.name}</li>
							);
						})}
					</ol>
				)}
			
			</fieldset>
			
			{(Boolean(importIsDone)) && (
				
				<div className={`import-report mt-4`}>
					
					{(Boolean(logMaterialId)) && (
						<>
							{/*<b>{i18next.t(`Report`)} #{logMaterialId}</b>*/}
							<div className={`alert alert-success`}>
								
								<p>
									<b>{i18next.t(`Import is done`)}</b>
								</p>
								
								<p>
									{i18next.t(`Report number`)}: <b>{logMaterialId}</b>
								</p>
								
								<p>
									{/*{i18next.t(`Если импорт прошёл некорректно, сообщите этот номер отчета в службу поддержки`)}*/}
									{i18next.t(`Please check the import result. If the import was not successful, please provide the report number to the support service.`)}
								</p>
								
							</div>
						</>
					)}
					
					{(Boolean(techReportData)) && (
						<>
							<FoldableList
								name={i18next.t(`Technical report`)}
							>
								<textarea className={`form-control src-view`} rows={20} readOnly={true}>{JSON.stringify(techReportData, null, "\t")}</textarea>
							</FoldableList>
						</>
					)}
					
				</div>
			
			)}
			
			<div className={`controls mt-3`}>
				
				<hr/>
				
				<div className={`row`}>
					
					<div className={`col`}>
						<button
							type={'submit'}
							className={`my-btn my-btn-primary`}
						>{i18next.t(`Submit`)}</button>
					</div>
					
					{(onCancel) && (
						<div className={`col text-right`}>
							<button
								type={'button'}
								className={`my-btn my-btn-danger`}
								onClick={(event) => {
									onCancel()
								}}
							>{cancelBtnText}</button>
						</div>
					)}
					
					
				</div>
				
				
				
			</div>
		
		</form>
		
	)
	
}

UserImportForm.propTypes = {}

export default UserImportForm