import Config from "./Config";
import Logger from "./Logger";
import i18next from "i18next";

export default class Utils {
	
	static TEAMS_URL = 'https://teams.microsoft.com/dl/launcher/launcher.html';
	
	static mimeTypes = {
		pdf: 'application/pdf',
	};
	
	static keyCodes = {
		enter: 13,
		backspace: 8,
		del: 46,
	};
	
	static isLoc = () => {
		return ['localhost', '127.0.0.1'].includes(window.location.hostname)
	};
	
	static isProd = () => {
		return Config.prodHosts?.includes(window.location.hostname);
	};
	
	static isDev = () => {
		return Config.devHosts?.includes(window.location.hostname);
	};
	
	static isTest = () => {
		return Config.testHosts?.includes(window.location.hostname);
	};
	
	static setTitle = (text, user) => {
		
		let prefixData = [];
		let titleData = [];
		
		if (Utils.isLoc()) {
			prefixData.push('[loc]');
		}
		
		if (Utils.isDev()) {
			prefixData.push('[dev]');
		}
		
		if (Utils.isTest()) {
			prefixData.push('[test]');
		}
		
		const prefix = prefixData.join('');
		
		if (text) {
			titleData.push(text);
		}
		
		titleData.push(Config.projectName);
		
		if (user) {
			
			titleData.push(i18next.t(user.role_name));
			titleData.push(user.getDisplayName(1));
			
			if (Utils.isLoc()) {
				titleData.push('#' + user.id);
			}
			
		}
		
		const title = titleData.join(' | ');
		
		document.title = prefix + ' ' + title;
		
	};
	
	static getUserId = () => {
		return localStorage.getItem('user_id');
	}
	
	static pagesCount = (itemsCount, pageSize) => {
		return Math.ceil(itemsCount / pageSize);
	};
	
	static getEmployeeId = () => {
		return localStorage.getItem('employee_id');
	}
	
	static getUserToken = () => {
		return localStorage.getItem('user_token');
	};
	
	static arrayShuffle = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}
	
	static objToArray = (obj, shuffle = false) => {
		
		let array = [];
		
		Object.getOwnPropertyNames(obj).forEach((attr) => {
			array.push(obj[attr]);
		});
		
		if (shuffle) {
			array = Utils.arrayShuffle(array);
		}
		
		return array;
		
	};
	
	static defaultModelSort = (a, b) => {
		
		if (a.sort > b.sort) {
			return 1;
		}
		
		if (a.sort < b.sort) {
			return -1;
		}
		
		return 0;
		
	};
	
	// src: https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
	static arrayMove = (array, oldIndex, newIndex) => {
		if (newIndex >= array.length) {
			let k = newIndex - array.length + 1;
			while (k--) {
				array.push(undefined);
			}
		}
		array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
		return newIndex;
	};
	
	static arrayMoveUp = (array, index) => {
		
		let newIndex;
		
		if (index > 0) {
			newIndex = index - 1;
		} else {
			newIndex = array.length - 1;
		}
		
		return Utils.arrayMove(array, index, newIndex);
		
	};
	
	static arrayMoveDown = (array, index) => {
		
		let newIndex;
		
		if (index >= array.length - 1) {
			newIndex = 0;
		} else {
			newIndex = index + 1;
		}
		
		return Utils.arrayMove(array, index, newIndex);
		
	};
	
	static arrayGetAttr = (array, attr) => {
		
		let vals = [];
		
		array.forEach((item) => {
			vals.push(item[attr]);
		});
		
		return vals;
		
	};
	
	static arrayPrevIndex = (array, currentIndex) => {
		
		currentIndex = parseInt(currentIndex);
		
		if (isNaN(currentIndex)) {
			currentIndex = 0;
		}
		
		let prevIndex;
		
		if (currentIndex > 0) {
			prevIndex = currentIndex - 1;
		} else {
			prevIndex = array.length - 1;
		}
		
		return prevIndex;
		
	};
	
	static arrayNextIndex = (array, currentIndex) => {
		
		currentIndex = parseInt(currentIndex);
		
		if (isNaN(currentIndex)) {
			currentIndex = 0;
		}
		
		let nextIndex;
		
		if (currentIndex >= array.length - 1) {
			nextIndex = 0;
		} else {
			nextIndex = currentIndex + 1;
		}
		
		return nextIndex;
		
	};
	
	static arrayUp = (array, currentIndex) => {
		
		const logName = 'Utils.arrayUp';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(currentIndex, 'currentIndex', logAllow);
		
		const newIndex = Utils.arrayPrevIndex(array, currentIndex);
		Logger.log(newIndex, 'newIndex', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return Utils.arrayMove(array, currentIndex, newIndex);
		
	};
	
	static arrayDown = (array, currentIndex) => {
		
		const logName = 'Utils.arrayDown';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(currentIndex, 'currentIndex', logAllow);
		
		const newIndex = Utils.arrayNextIndex(array, currentIndex);
		Logger.log(newIndex, 'newIndex', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return Utils.arrayMove(array, currentIndex, newIndex);
		
	};
	
	static isMobile = () => {
		return (function (a, b) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) window.location = b
		})(navigator.userAgent || navigator.vendor || window.opera, 'http://detectmobilebrowser.com/mobile');
	};
	
	// src: https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
	static isJsonString = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	
	static axiosErrorAlert = (error) => {
		
		const logName = 'Utils.axiosErrorAlert';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(error, 'error', logAllow);
		
		let msgData = [error];
		
		if (error.response && Array.isArray(error.response.data)) {
			msgData.push("\n");
			msgData = msgData.concat(error.response.data);
		}
		
		let msg = msgData.map(err => err.message).join("\n");
		
		window.alert(msg);
		
		Logger.groupEnd(logAllow);
		
	};
	
	static pattern = (length, char = '+', sep = ' ') => {
		let pattern = [];
		for (let i = 0; i < length; i++) {
			pattern.push(char);
		}
		pattern = pattern.join(sep);
		return pattern;
	}
	
	static backendUrl = (path) => {
		
		let out = Config.prodBackendUrl
		
		let map = Config.backendUrlMap
		
		if (map) {
			
			let mapResult = map[window.location.hostname]
			
			if (mapResult) {
				out = mapResult
			}
			
		}
		
		return out + (path ? '/' + path : '')
		
	}
	
	static apiUrl = (path, apiVersion = 1) => {
		return Config.backendUrl + '/api/v' + apiVersion + '/' + path;
	};
	
	static splitPdfsAndNotPdfs = (uploadsBindsArray) => {
		
		let pdfs = [];
		let notPdfs = [];
		
		uploadsBindsArray.forEach((uploadBindData) => {
			if (uploadBindData.mime_type == Utils.mimeTypes.pdf) {
				pdfs.push(uploadBindData);
			} else {
				notPdfs.push(uploadBindData);
			}
		});
		
		return {
			pdfs: pdfs,
			notPdfs: notPdfs,
		}
		
	};
	
	static isEqualObjects(a,b) {
		return JSON.stringify(a) === JSON.stringify(b);
	};
	
	// push or splice
	static arrayToggleElement = (array, element) => {
		
		let out = null;
		
		let elemntIndex = array.indexOf(element);
		
		if (elemntIndex >= 0) {
			array.splice(elemntIndex, 1);
			out = 'del';
		} else {
			array.push(element);
			out = 'add';
		}
		
		return array;
		// return out;
		
	};
	
	static copyToClipboard = (content, afterSuccess, afterFail) => {
		
		navigator.permissions.query({name: "clipboard-write"}).then((result) => {
			
			if (result.state === "granted" || result.state === "prompt") {
				
				navigator.clipboard.writeText(content).then(
					
					() => {
						if (afterSuccess) {
							afterSuccess();
						}
					},
					
					() => {
						if (afterFail) {
							afterFail();
						}
					}
				
				);
				
			}
			
		});
		
	}
	
	static showModelCustomErrors = (modelCustomErrors) => {
		
		if (Array.isArray(modelCustomErrors)) {
			
			let out = [];
			
			modelCustomErrors.forEach((errorData, errorIndex) => {
				
				let tpl = errorData[0];
				let params = errorData[1];
				
				let msg = i18next.t(tpl, params);
				
				let errorNum = errorIndex + 1;
				
				out.push(errorNum + ') ' + msg);
				
			});
			
			window.alert(out.join("\n\n"));
			
		}
		
	};
	
	static parseJson = (jsonString, returnOnFail=null, callOnFail) => {
		
		const logName = 'Utils.parseJson';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		try {
			
			Logger.groupEnd(logAllow);
			
			return JSON.parse(jsonString);
			
		} catch (err) {
			
			Logger.log(err, 'err', logAllow);
			
			if (callOnFail) {
				callOnFail(err);
			}
			
			Logger.groupEnd(logAllow);
			
			return returnOnFail;
			
		}
		
	};
	
	static fillArray = (filler, count) => {
		
		const array = []
		
		for (let x = 0; x < count; x++) {
			array.push(filler)
		}
		
		return array
		
	}
	
	static nl2br = (string) => {
		if (string) {
		  return string.split("\n").map((item, key) => {
			return (
			  <span key={key}>
				{item}
				<br />
			  </span>
			);
		  });
		}
	}
	
	static urlToName = (url) => {
		
		let hostToNameMap = {
			'www.youtube.com': 'YouTube (USA)',
			'vk.com': 'VK (Russia)',
			'rutube.ru': 'RuTube (Russia)',
			'www.aparat.com': 'Aparat (Iran)',
			'v.youku.com': 'Youku (China)',
		}
		
		url = new URL(url)
		
		let hostname = url.hostname
		
		return hostToNameMap[hostname] || url
		
	}
	
	static round = (x, decimals = 2) => {
		
		let coef = 10
		
		for (let i = 0; i < decimals - 1; i++) {
			coef *= coef
		}
		
		return Math.round(x * coef) / coef
		
	}
	
}