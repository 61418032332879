import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import Modal from "react-bootstrap/Modal";
import Discuss from "../common/Discuss";
import User from "../../models/User";
import SberGigaChatDialog from "./SberGigaChatDialog";

const SberGigaChatModal = ({isOpen, onHide, user, preloader}) => {
	
	return (
		
		<Modal
			className={'SberGigaChatModal'}
			show={Boolean(isOpen)}
			onHide={() => {
				if (onHide) {
					onHide()
				}
			}}
			size={'lg'}
			// backdrop={'static'}
			// keyboard={false}
		>
			
			<Modal.Header closeButton>
				<Modal.Title>{i18next.t(`AI Chat`)}{/* | {i18next.t(`Sber GigaChat`)}*/}</Modal.Title>
			</Modal.Header>
			
			<Modal.Body>
				
				<SberGigaChatDialog
					user={user}
					preloader={preloader}
				/>
				
			</Modal.Body>
			
		</Modal>
		
	)
	
}

SberGigaChatModal.propTypes = {
	// user: PropTypes.instanceOf(User).isRequired,
	user: PropTypes.object.isRequired,
}

export default SberGigaChatModal