import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Logger from "../../helpers/Logger";
import i18next from "i18next";
import DateHelper from "../../helpers/DateHelper";
import * as Icon from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import MaterialGroupBindForm from "./MaterialGroupBindForm";
import Material from "../../models/Material";
import User from "../../models/User";

const MaterialPreview = ({material, user, isCurrent, onClick = () => {}, onPublic, onUnpublic, onMoveUp, onMoveDown, onArchive, onUnarchive, onCopy, onEdit}) => {
	
	const logName = 'MaterialsPage.materials.map'
	const logAllow = 0
	const logCollapse = 0
	
	Logger.groupStart(logName, logAllow, logCollapse)
	
	Logger.log(material, 'material', logAllow)
	
	let progress = material.isExam() ? 0 : material.getProgress();
	Logger.log(progress, 'progress', logAllow)
	
	let classNameData = [
		'material-preview',
		'list-item',
		'list-item-active',
	];
	
	if (isCurrent) {
		classNameData.push('list-item-current');
	}
	
	if (material.is_del == 1) {
		classNameData.push('list-item-archived');
	}
	
	if (material.is_exam == 1) {
		classNameData.push('is_exam');
	}
	
	if (material.is_trainer == 1) {
		classNameData.push('is_trainer');
	}
	
	if (!material.is_public == 1) {
		classNameData.push('list-item-draft');
	}
	
	let materialDebugInfo = [
		'#' + material.id,
		'created_at: ' + material.created_at,
		'created_by: #' + material.created_by,
	];
	
	if (material.copy_of_id) {
		materialDebugInfo.push('copy of #' + material.copy_of_id);
	}
	
	// отображать студенту только те доступы, которые относятся к его группам
	let userAllowedGroupsBinds = Array.isArray(material.groups_binds) ? material.groups_binds.filter((x) => {
		return user?.groups_ids.includes(x.group_id)
	}) : []
	
	Logger.groupEnd(logAllow)
	
	return (
		
		<div
			key={`material-${material.id}-preview`}
			id={`material-${material.id}-preview`}
			className={classNameData.join(' ')}
			onClick={(event) => {
				onClick(material)
			}}
			data-material-id={material.id}
			data-dynamic-id={material?.dynamic?.id}
		>
			{progress > 0 && (
				<div className="progress-box">
					<div className="progress" style={{
						width: progress + '%',
					}}></div>
				</div>
			)}
			
			<div className={'content'}>
				
				<div className={'title'}>{material.name}</div>
				
				{(material.restricted_access == 1) && (
					<>
						<div className={`small text-muted my-2`}>
							{i18next.t("Restricted access")}
						</div>
					</>
				)}
				
				{(userAllowedGroupsBinds.length > 0) && (
					<div className={`groups-binds access-list my-2`}>
						{userAllowedGroupsBinds.map((materialGroupBind) => {
							
							const logName = 'material.groups_binds.map'
							const logAllow = 0
							const logCollapse = 0
							
							Logger.groupStart(logName, logAllow, logCollapse)
							
							Logger.log(materialGroupBind, 'materialGroupBind', logAllow)
							
							let accessStartDateTime =
								
								DateHelper.formatDate({
									date: materialGroupBind.access_start_unixtime * 1000,
									format: 'd.m.y',
								})
								
								+ ' ' +
								
								DateHelper.formatTime({
									time: materialGroupBind.access_start_unixtime * 1000,
									format: 'h:m',
								})
							
							let accessEndDateTime = materialGroupBind.access_end
								
								?
								
								DateHelper.formatDate({
									date: materialGroupBind.access_end_unixtime * 1000,
									format: 'd.m.y',
								})
								
								+ ' ' +
								
								DateHelper.formatTime({
									time: materialGroupBind.access_end_unixtime * 1000,
									format: 'h:m',
								})
								
								: i18next.t("Not restricted")
							
							Logger.groupEnd(logAllow)
							
							return (
								
								<div className={`group-bind access-list-item small text-muted`}>
									{materialGroupBind.group_name} : {accessStartDateTime} – <b>{accessEndDateTime}</b>
								</div>
							
							)
							
						})}
					</div>
				)}
				
				{user?.can('debugInfo') && (
					<div className={'debug-info'}>
						{materialDebugInfo.join(' | ')}
					</div>
				)}
			
			</div>
			
			{user?.can('manageMaterials') && (
				
				<div className="controls material-preview-controls bottom-controls">
					
					{(onEdit) && (
						<button
							type={'button'}
							className={[
								'material-edit-btn',
								'edit-material-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onEdit(material);
							}}
						><Icon.Pencil/></button>
					)}
					
					{(onMoveUp) && (
						<button
							type={'button'}
							className={[
								'move-up-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onMoveUp(material);
							}}
						><Icon.ArrowUp/></button>
					)}
					
					{(onMoveDown) && (
						<button
							type={'button'}
							className={[
								'move-down-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onMoveDown(material);
							}}
						><Icon.ArrowDown/></button>
					)}
					
					{(onPublic && material.is_public == 0 && material.is_del == 0) && (
						<button
							type={'button'}
							className={[
								'public-material-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onPublic(material);
							}}
							title={i18next.t("Show for students")}
						><Icon.Eye/></button>
					)}
					
					{(onUnpublic && material.is_public == 1 && material.is_del == 0) && (
						<button
							type={'button'}
							className={[
								'unpublic-material-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onUnpublic(material);
							}}
							title={i18next.t("Hide for students")}
						><Icon.EyeSlash/></button>
					)}
					
					{(onArchive && material.is_del == 0) && (
						<button
							type={'button'}
							className={[
								'del-material-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onArchive(material);
							}}
							title={i18next.t("Move to archive")}
						><Icon.Trash/></button>
					)}
					
					{(onUnarchive && material.is_del == 1) && (
						<button
							type={'button'}
							className={[
								'undel-material-btn',
								'my-btn',
								'my-btn-sm',
								// 'btn-primary',
							].join(' ')}
							onClick={(event) => {
								event.stopPropagation();
								onUnarchive(material);
							}}
							title={i18next.t("Return from archive")}
						><Icon.ArrowCounterclockwise/></button>
					)}
					
					{(onCopy) && (
						<button
							type={'button'}
							className={[
								'copy-material-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							title={i18next.t("Copy to") + '...'}
							onClick={(event) => {
								event.stopPropagation();
								onCopy(material)
							}}
						><Icon.ClipboardPlus/></button>
					)}
					
					<button
						type={'button'}
						className={[
							'show-material-page-btn',
							'my-btn',
							'my-btn-sm',
						].join(' ')}
						onClick={(event) => {
							event.stopPropagation();
						}}
						title={i18next.t("Open on a separate page")}
					>
						<Link
							to={'/material/' + material.id}
							target={'_blank'}
						><Icon.ArrowUpRightCircleFill/></Link>
					</button>
					
				</div>
				
			)}
			
		</div>
		
	);
	
}

MaterialPreview.propTypes = {
	material: PropTypes.instanceOf(Material).isRequired,
	user: PropTypes.instanceOf(User),
}

export default MaterialPreview