import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Logger from "../../helpers/Logger";

const FilePicker = ({ref, onChange = () => {}, label = 'Choose files', accept = []}) => {
	
	const logName = 'FilePicker'
	const logAllow = 1
	const logCollapsed = 0
	
	Logger.groupStart(logName, logAllow, logCollapsed)
	
	Logger.log(ref, 'ref', logAllow)
	Logger.log(ref?.current, 'ref?.current', logAllow)
	
	Logger.groupEnd(logAllow)
	
	return (
		
		<div className={`FilePicker`}>
			
			<button
				type={'button'}
				onClick={(event) => {
					
					const logName = 'FilePicker.btn.click'
					const logAllow = 1
					const logCollapsed = 0
					
					Logger.groupStart(logName, logAllow, logCollapsed)
					
					Logger.log(ref, 'ref', logAllow)
					Logger.log(ref?.current, 'ref?.current', logAllow)
					
					ref?.current?.click()
					
					Logger.groupEnd(logAllow)
				}}
			>
				
				<span className={`label`}>
					{label}
				</span>
				
			</button>
			
			{/*<input
				type={'file'}
				multiple={true}
				accept={accept.join(', ')}
				ref={ref}
				required={true}
				onChange={() => {
					onChange()
				}}
				className={``}
			/>*/}
			
		</div>
		
	)
	
}

FilePicker.propTypes = {}

export default FilePicker