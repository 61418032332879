import User from "./User"

class Msg {
	
	static sberGigaChatMsgTargetModelAlias = 'sber-giga-chat'
	
	constructor(data = {}) {
		this.id = data.id
		this.head = data.head
		this.body = data.body
		this.created_at = data.created_at
		this.created_by = data.created_by ? new User(data.created_by) : null
		this.target_model_alias = data.target_model_alias
		this.target_model_id = data.target_model_id
		this.target_user_id = data.target_user_id
	}
	
	isForAi = () => {
		return [
			Msg.sberGigaChatMsgTargetModelAlias,
		].includes(this.target_model_alias)
	}
	
	isCreatedByAi = () => {
		return this.isForAi() && this.target_model_id == 1
	}
	
}

export default Msg