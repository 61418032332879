import './SberGigaChatDialog.css'

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Discuss from "../common/Discuss";
import Msg from "../../models/Msg";

const SberGigaChatDialog = ({user, preloader}) => {
	
	return (
		
		<div className={`SberGigaChatDialog`}>
			
			<Discuss
				user={user}
				preloader={preloader}
				targetModelAlias={Msg.sberGigaChatMsgTargetModelAlias}
				targetUserId={user.id}
				allowClearChat={1}
			/>
			
		</div>
		
	)
	
}

SberGigaChatDialog.propTypes = {}

export default SberGigaChatDialog