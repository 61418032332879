import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import {ProgressBar} from "react-bootstrap";

const PreloaderProgressBar = ({
	msg = i18next.t(`Загрузка`) + '...',
}) => {
	
	return (
		
		<ProgressBar
			className={`preloader-progress-bar`}
			animated
			now={100}
			label={msg}
		/>
	
	)
	
}

PreloaderProgressBar.propTypes = {}

export default PreloaderProgressBar