import "./MaterialsPage.css";

import axios from "axios";
import i18next from "i18next";
import PropTypes from 'prop-types';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import Config from "../../helpers/Config";
import Logger from "../../helpers/Logger";
import Range from "../../helpers/Range";
import Utils from "../../helpers/Utils";
import YouTubeHelper from "../../helpers/YouTubeHelper";
import Course from "../../models/Course";
import Group from "../../models/Group";
import Lesson from "../../models/Lesson";
import Material from "../../models/Material";
import Question from "../../models/Question";
import Theme from "../../models/Theme";
import User from "../../models/User";
import BackBtn from "./BackBtn";
import MaterialForm from "./MaterialForm";
import QuestionForm from "./QuestionForm";
import QuestionView from "./QuestionView";

import { Link } from "react-router-dom";
import VideoLinkHelper from "../../helpers/VideoLinkHelper";
import ListItem from "./ListItem";
import PdfView from "./PdfView";
import QuestionCardForm from "./QuestionCardForm";
import QuestionCardView from "./QuestionCardView";
import QuestionGroupForm from "./QuestionGroupForm";
import QuestionGroupView from "./QuestionGroupView";
import UploadsList from "./UploadsList";
import DateHelper from "../../helpers/DateHelper";
import MaterialCopyModal from "./MaterialCopyModal";
import MaterialPreview from "./MaterialPreview";
import PreloaderProgressBar from "./PreloaderProgressBar";
// import moment from "moment";


class MaterialsPage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			materialFormModalIsOpen: false,
			materialTheoryFormModalIsOpen: false,
			materialTestFormModalIsOpen: false,
			
			currentMaterial: null,
			currentMaterialIndex: 0, // load first
			// currentMaterialIndex: null, // don't load first
			currentMaterialIsLoading: 0,
			
			materials: [],
			materialsActiveOnly: true,
			
			materialsShowArchived: false,
			materialsShowNotPublic: props.user.can('seeMaterialsDrafts'),
			
			materialToEdit: null,
			
			questionFormModalIsOpen: false,
			questionToEdit: null,
			questionToEditIndex: null,
			currentQuestionIndex: 0,
			
			imgViewerIsOpen: false,
			imgViewerCurrentIndex: null,
			imgViewerUrls: [],
			
			homeworks: [],
			currentHomework: null,
			currentHomeworkIndex: null,
			homeworkToEdit: null,
			homeworkToEditIndex: null,
			homeworkFormModalIsOpen: false,
			
			groups: [],
			// questionsStates: [], // менки домашних заданий
			
			useQuestionEditModal: true,
			
			questionCardToEdit: null,
			questionCardFormModalIsOpen: false,
			
			questionGroupFormModalIsOpen: false,
			
			currentVariant: null,
			
			materialSearchName: null,
			
			materialToCopy: null,
			materialCopyModalIsOpen: 0,
			
		};
		
		// this.materialsShowArchivedChbxRef = React.createRef();
		// this.materialsShowNotPublicChbxRef = React.createRef();
		
	}
	
	materials = {
		
		getAll: () => {
			return this.state.materials || [];
		},
		
		getById: (id) => {
			return this.state.materials.find((x) => x.id == id);
		},
		
		getByIndex: (index) => {
			return this.state.materials[index];
		},
		
		getIndexById: (materialId) => {
			return this.state.materials.findIndex(x => x.id == materialId);
		},
		
		loadList: (
			materialsShowArchived = this.state.materialsShowArchived,
			materialsShowNotPublic = this.state.materialsShowNotPublic,
			afterSuccess
		) => {
			
			const logName = 'MaterialsPage.materials.loadList';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			// todo wtf remove setState
			// this.setState((prevState) => {
			// 	return {
			// 		materials: [],
			// 		currentMaterial: null,
			// 		// setCurrentMaterialIndex: null,
			// 	}
			// });
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'themeId': this.props.theme.id,
				'drafts': materialsShowNotPublic ? 1 : 0,
				'archived': materialsShowArchived ? 1 : 0,
				//'progressForUserId': this.props.user.id,
			};
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const user = this.props.user;
			Logger.log(user, 'user', logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials/list'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.loadList.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let materials = response.data.map(materialData => new Material(materialData));
				Logger.log(materials, 'materials [before filtering]', logAllow)
				
				// если не админ
				if (!user.can('manageMaterials') && !user.can('manageMaterialsAccess')) {
					
					// фильтруем материалы по праву доступа
					// let allowedMaterials = materials.filter((material) => {
					// 	return material.hasAccess(user.groups_ids);
					// });
					//
					// materials = allowedMaterials;
					
					Logger.log(materials, 'materials [after filtering]', logAllow);
					
				}
				
				Logger.log(materials, 'materials [filtered]', logAllow);
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				// restore last material v1 // by index
				
				/*let currentMaterialIndex = this.state.currentMaterialIndex;
				
				let theme = this.props.theme;
				
				if (theme) {
					let storedMaterialIndex = Config.getMaterialIndex(theme.id);
					if (storedMaterialIndex) {
						currentMaterialIndex = storedMaterialIndex;
					}
				}
				
				Logger.log(currentMaterialIndex, 'currentMaterialIndex', logAllow);
				
				if (currentMaterialIndex !== null) {
					
					let currentMaterial = materials[currentMaterialIndex];
					Logger.log(currentMaterial, 'currentMaterial', logAllow);
					
					if (currentMaterial) {
						
						currentMaterial.has_details = 0;
						
						this.materials.setCurrentByIndex(currentMaterialIndex);
						
					}
					
				}*/
				
				// restore last material v2 // by id
				
				let currentMaterial = this.state.currentMaterial;
				Logger.log(currentMaterial, 'currentMaterial', logAllow)
				
				let theme = this.props.theme;
				Logger.log(theme, 'theme', logAllow);
				
				if (theme) {
					
					let storedMaterialId = Config.getMaterialId(theme.id);
					Logger.log(storedMaterialId, 'storedMaterialId', logAllow);
					
					if (storedMaterialId) {
						currentMaterial = this.materials.getById(storedMaterialId);
					}
					
				}
				
				Logger.log(currentMaterial, 'currentMaterial', logAllow);
				
				if (!currentMaterial) {
					currentMaterial = materials[0];
				}
				
				if (currentMaterial) {
					
					currentMaterial.has_details = 0;
					
					let currentMaterialIndex = this.materials.getIndexById(currentMaterial.id);
					this.materials.setCurrentByIndex(currentMaterialIndex);
					
					// this.materials.setCurrent(currentMaterial);
					
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentIsLoading: (val) => {
			this.setState((prevState) => {
				return {
					currentMaterialIsLoading: val,
				}
			})
		},
		
		loadOne: (materialId, afterSuccess) => {
			
			const logName = 'MaterialsPage.materials.loadOne';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (Boolean(this.state.currentMaterialIsLoading)) {
				Logger.groupEnd(logAllow);
				return
			}
			
			if (!materialId) {
				window.alert(i18next.t("matrial id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			Logger.log(this.props.preloader, 'this.props.preloader', logAllow);
			
			// if (this.props.preloader) {
			// 	this.props.preloader.show();
			// }
			
			this.materials.setCurrentIsLoading(1)
			
			axios({
				method: 'get',
				url: Utils.apiUrl('materials') + '/' + materialId,
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.loadOne.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				// if (this.props.preloader) {
				// 	this.props.preloader.hide();
				// }
				
				this.materials.setCurrentIsLoading(0)
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				// if (this.props.preloader) {
				// 	this.props.preloader.hide();
				// }
				
				this.materials.setCurrentIsLoading(0)
				
				console.log('error.response = %o', error.response);
				
				if (error.response && error.response.data) {
					window.alert(error.response.data.message);
				} else {
					window.alert(error.message);
				}
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setVariant: (variant) => {
			this.setState({
				currentVariant: variant,
				currentQuestionIndex: 0,
			})
		},
		
		delVariant: (varianId) => {
			
			if (!varianId) {
				return
			}
			
			if (!window.confirm(i18next.t('Shure?'))) {
				return
			}
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/del-variant'),
				params: {
					'accessToken': Utils.getUserToken(),
					'variantId': varianId,
				},
				data: {},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.delVariant.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				let currentMaterial = this.state.currentMaterial
				
				let variants = currentMaterial.variants
				
				let deletedVariantIndex = variants.findIndex((variant) => {
					return variant.id == varianId
				})
				
				// moving questions into material ('without variants' zone)
				// перемещаем задания в материал (в зону 'вне вариантов')
				
				let deletedVariant = variants[deletedVariantIndex]
				let deletedVariantQuestions = deletedVariant?.children
				
				currentMaterial.questions = currentMaterial.questions.concat(deletedVariantQuestions)
				
				// удаляем вариант из материала на клиенте
				
				variants.splice(deletedVariantIndex, 1)
				
				// variants = variants.filter((variant) => {
				// 	return variant.id != varianId
				// })
				
				currentMaterial.variants = variants
				
				this.setState({
					currentMaterial: currentMaterial,
					currentVariant: null,
				})
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				this.props.preloader?.hide()
				console.log(`axiosError = %o`, axiosError)
				window.alert(axiosError)
			})
			
		},
		
		afterCreate: (response) => {
			
			const logName = 'MaterialsPage.materials.afterCreate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = new Material(response.data);
			let materials = this.state.materials;
			materials.push(material);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: material,
					materialFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpdate: (response) => {
			
			const logName = 'MaterialsPage.materials.afterUpdate';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			const material = new Material(response.data);
			const materials = this.state.materials;
			
			let materialIndex = this.materials.getIndexById(material.id);
			
			materials[materialIndex] = material;
			
			this.setState((prevState) => {
				return {
					materials: materials,
					materialFormModalIsOpen: false,
					materialToEdit: null,
				}
			});
			
			if (this.state.currentMaterial) {
				this.setState((prevState) => {
					return {
						currentMaterial: material,
					}
				});
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					materialFormModalIsOpen: !prevState.materialFormModalIsOpen,
				}
			});
		},
		
		// сохранить текущий порядок элементов
		saveSort: (materials) => {
			
			const logName = 'MaterialsPage.materials.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			materials.forEach((material) => {
				ids.push(material.id);
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (material) => {
			
			if (!material) {
				return
			}
			
			let materialIndex = this.materials.getIndexById(material.id)
			
			let materials = this.state.materials;
			let newMaterialIndex = Utils.arrayMoveUp(materials, materialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		moveDown: (material) => {
			
			if (!material) {
				return
			}
			
			let materialIndex = this.materials.getIndexById(material.id)
			
			let materials = this.state.materials;
			let newMaterialIndex = Utils.arrayMoveDown(materials, materialIndex);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterialIndex: newMaterialIndex,
				}
			});
			
			this.materials.saveSort(materials);
			
		},
		
		upd: (material, data, confirmMsg) => {
			
			const logName = 'MaterialsPage.upd';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!material) {
				return;
			}
			
			let materialIndex = this.materials.getIndexById(material.id)
			
			Logger.log(materialIndex, 'materialIndex', logAllow);
			Logger.log(data, 'data', logAllow);
			
			if (confirmMsg) {
				if (!window.confirm(confirmMsg)) {
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					return;
				}
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('materials') + '/' + material.id,
				data: data,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.upd';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let material = new Material(response.data);
				
				let materials = this.state.materials;
				
				materials[materialIndex] = material;
				
				if ((material.is_del == 1 && this.state.materialsShowArchived == 0) || (material.is_public == 0 && this.state.materialsShowNotPublic == 0)) {
					materials.splice(materialIndex, 1);
				}
				
				this.setState((prevState) => {
					return {
						materials: materials,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addCard: () => {
			
			const logName = 'MaterialsPage.materials.addCard';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			
			let currentQuestionIndex = this.state.currentQuestionIndex;
			
			let currentQuestion = material.questions[currentQuestionIndex];
			
			let newCard = new Question();
			
			newCard.view_type_alias = 'card';
			
			if (currentQuestion && currentQuestion.isGroup()) {
				newCard.group_id = currentQuestion.id;
			}
			
			this.setState((prevState) => {
				return {
					questionToEdit: newCard,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addGroup: () => {
			
			const logName = 'MaterialPage.material.addGroup';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			
			let newGroup = new Question();
			
			newGroup.view_type_alias = 'group';
			
			let groups = material.getGroups();
			
			let newGroupNum = groups.length + 1;
			
			newGroup.name = i18next.t("Group") + ' ' + newGroupNum;
			
			this.setState((prevState) => {
				return {
					questionToEdit: newGroup,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		addVariant: () => {
			
			const logName = 'MaterialPage.material.addVariant';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let currentMaterial = this.state.currentMaterial;
			
			if (currentMaterial) {
				
				this.props.preloader?.show()
			
				axios({
					method: 'post',
					url: Utils.apiUrl('materials/add-variant'),
					data: {},
					params: {
						'accessToken': Utils.getUserToken(),
						'materialId': currentMaterial.id,
					},
				}).then((response) => {
					
					const logName = 'MaterialPage.material.addVariant.ajax.done'
					const logAllow = 1
					const logCollapse = 0
					
					Logger.groupStart(logName, logAllow, logCollapse)
					
					Logger.log(response, 'response', logAllow)
					
					let newVariant = new Question(response.data)
					
					currentMaterial.variants.push(newVariant)
					
					this.setState({
						currentMaterial: currentMaterial,
					})

					this.props.preloader?.hide()
					
					Logger.groupEnd(logAllow)
					
				}).catch((axiosError) => {
					
					this.props.preloader?.hide()
					
					window.alert(axiosError)
					
				})
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrent: (material, editMode) => {
			
			const logName = 'MaterialsPage.materials.setCurrent';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(material.name, 'material.name', logAllow);
			Logger.log(material.id, 'material.id', logAllow);
			Logger.log(material, 'material', logAllow);
			
			let materials = this.state.materials;
			
			let materialIndex = this.materials.getIndexById(material.id);
			Logger.log(materialIndex, 'materialIndex', logAllow);
			
			materials[materialIndex] = material;
			
			// localStorage.setItem(Config.coursesCurrentMaterialIdKey, material.id);
			// localStorage.setItem(Config.coursesCurrentMaterialIndexKey, materialIndex);
			
			// save current material for current theme
			if (this.props.theme) {
				let themeId = this.props.theme.id;
				Config.setMaterialIndex(themeId, materialIndex);
				Config.setMaterialId(themeId, material.id);
			}
			
			let storedQuestionIndex = Config.getQuestionIndex(material.id);
			let currentQuestionIndex = storedQuestionIndex ? storedQuestionIndex : 0;
			
			let newState = {
				currentMaterial: material,
				currentMaterialIndex: materialIndex,
				currentQuestionIndex: currentQuestionIndex,
				currentVariant: material.variants.length > 0 ? material.variants[0] : null,
				materials: materials,
				materialToEdit: null,
			};
			
			Logger.log(editMode, 'editMode', logAllow);
			
			if (editMode) {
				Logger.log('set edit mode', null, logAllow);
				newState.materialToEdit = material;
			}
			
			Logger.log(newState, 'newState', logAllow);
			
			this.setState((prevState) => {
				return newState;
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentByIndex: (materialIndex, editMode) => {
			
			const logName = 'MaterialsPage.materials.setCurrentByIndex';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.materials[materialIndex];
			Logger.log(material, 'material', logAllow);
			
			if (!material) {
				return;
			}
			
			this.materials.setCurrent(material, editMode);
			
			if (material.has_details == 1) {
				
				// this.materials.setCurrent(material, editMode);
				
			} else {
				
				this.materials.loadOne(material.id, (response) => {
					
					let material = new Material(response.data);
					
					material.has_details = 1;
					
					this.materials.setCurrent(material, editMode);
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		setCurrentById: (materialId, editMode) => {
			
			if (!materialId) {
				return
			}
			
			let material = this.materials.getById(materialId);
			
			if (!material) {
				return;
			}
			
			this.materials.setCurrent(material, editMode);
			
			if (material.has_details == 1) {
				
				// this.materials.setCurrent(material, editMode);
				
			} else {
				
				this.materials.loadOne(material.id, (response) => {
					
					let material = new Material(response.data);
					
					material.has_details = 1;
					
					this.materials.setCurrent(material, editMode);
					
				});
				
			}
			
		},
		
		prev: () => {
			
			let materials = this.state.materials;
			
			let currentMaterialIndex = this.state.currentMaterialIndex;
			
			let newMaterialIndex = Utils.arrayPrevIndex(materials, currentMaterialIndex);
			
			this.materials.setCurrentByIndex(newMaterialIndex);
			
		},
		
		next: () => {
			
			const logName = 'MaterialsPage.next';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let materials = this.state.materials;
			
			let currentMaterialIndex = this.state.currentMaterialIndex;
			Logger.log(currentMaterialIndex, 'currentMaterialIndex', logAllow);
			
			let newMaterialIndex = Utils.arrayNextIndex(materials, currentMaterialIndex);
			Logger.log(newMaterialIndex, 'newMaterialIndex', logAllow);
			
			this.materials.setCurrentByIndex(newMaterialIndex);
			
			Logger.groupEnd(logAllow);
			
		},
		
		edit: (materialIndex) => {
			this.materials.setCurrentByIndex(materialIndex, 1);
		},
		
		disableUnwantedUseAnswerTemplates: (materialId) => {
			
			const logName = 'MaterialsPage.materials.disableUnwantedUseAnswerTemplates'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			if (!materialId) {
				window.alert(i18next.t("Material ID not provided"))
			}
			
			if (!window.confirm(i18next.t("Shure?"))) {
				return
			}
			
			this.props.preloader?.show()
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/disable-unwanted-use-answer-templates'),
				params: {
					accessToken: Utils.getUserToken(),
					materialId: materialId,
				},
				data: {},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.disableUnwantedUseAnswerTemplates.ajax.done'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				Logger.log(response, 'response', logAllow)
				
				const material = new Material(response.data)
				Logger.log(material, 'material', logAllow)
				
				let touchedQuestionsCount = material.getDisableUnwantedUseAnswerTemplatesData(1, 1)
				Logger.log(touchedQuestionsCount, 'touchedQuestionsCount', logAllow)
				
				window.alert(i18next.t("Questions touched") + ': ' + touchedQuestionsCount)
				
				this.setState({
					currentMaterial: material,
				})
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				const logName = 'MaterialsPage.materials.disableUnwantedUseAnswerTemplates.ajax.fail'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				console.log(`axiosError = %o`, axiosError)
				//window.alert(axiosError)
				
				this.props.preloader?.hide()
				
				Logger.groupEnd(logAllow)
				
			})
			
			Logger.groupEnd(logAllow)
			
		},
		
		copy: (material, targetThemeId) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('materials/copy-to'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					materialId: material.id,
					themeId: targetThemeId,
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.copyMaterial';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (targetThemeId == material.theme_id) {
					let copy = new Material(response.data);
					let materials = this.state.materials;
					materials.push(copy);
					materials.sort(Utils.defaultModelSort);
					this.setState((prevState) => {
						return {
							materials: materials,
						}
					});
				}
				
				this.setState((prevState) => {
					return {
						materialToCopy: null,
						materialCopyModalIsOpen: 0,
					}
				})
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				console.log('error.response = %o', error.response);
				if (error.response && error.response.data) {
					window.alert(error.response.data.message);
				} else {
					window.alert(error.message);
				}
			});
			
		},
		
		copyByPromt: (material) => {
			
			let themeId = window.prompt(i18next.t("Theme ID"), material.theme_id);
			
			if (themeId) {
				this.materials.copy(material, themeId)
			}
			
		},
		
		copyByModal: (material) => {
			
			this.setState((prevState) => {
				return {
					materialToCopy: material,
					materialCopyModalIsOpen: 1,
				}
			})
		
		},
		
	};
	
	questions = {
		
		get: () => {
			
			let questions = []

			const currentMaterial = this.state.currentMaterial
			const currentVariant = this.state.currentVariant
			
			if (currentMaterial) {
				
				if (currentVariant) {
					
					questions = currentVariant.children
					
				} else {
					
					questions = currentMaterial.is_dynamic == 1
						? currentMaterial.dynamic
							? currentMaterial.dynamic.questions
							: currentMaterial.questions
						: currentMaterial.questions
					
				}
				
			}
			
			return questions
			
		},
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					questionFormModalIsOpen: !prevState.questionFormModalIsOpen,
				}
			});
		},
		
		setMaterialAndVariant: (question, oldMaterialId, newMaterialId, variantId) => {
			// todo
		},
		
		setVariant: (question, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.setVariant'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let questionIndex = null
			
			let materials = this.state.materials
			
			if (Array.isArray(materials)) {
				
				let materialIndex = materials.findIndex((material) => {
					return material.id == materialId
				})
				
				let material = materials[materialIndex]
				Logger.log(material, 'material', logAllow)
				
				if (material) {
					
					// удаляем из общего списка
				
					// let materialQuestions = material.questions
					
					questionIndex = material.questions.findIndex((question) => {
						return question.id == question.id
					})
					
					if (questionIndex >= 0) {
						material.questions.splice(questionIndex, 1)
					}
					
					// material.questions = materialQuestions
					// materials[materialIndex] = material
					
					// дабавляем в варианты
					
					let variants = material.variants
					
					if (Array.isArray(variants)) {
						
						let variantIndex = variants.findIndex((variant) => {
							return variant.id == variantId
						})
						
						let variant = variants[variantIndex]
						
						if (variant) {
							
							let variantQuestions = variant.children
							
							if (Array.isArray(variantQuestions)) {
								
								let variantQuestionIndex = variantQuestions.findIndex(x => x.id == question.id)
								Logger.log(variantQuestionIndex, 'variantQuestionIndex', logAllow)
								
								if (variantQuestionIndex >= 0) {
								// if (0) {
									
									variantQuestions[variantQuestionIndex] = question
									
								} else {
									
									variantQuestions.push(question)
									
									variantQuestionIndex = variantQuestions.length - 1
									
								}
								
								variant.children = variantQuestions
								
								variants[variantIndex] = variant
								
								material.variants = variants
								
								questionIndex = variantQuestionIndex
								
							}
							
							materials[materialIndex] = material
					
							this.setState({
								materials: materials,
								currentVariant: variant,
								currentQuestionIndex: questionIndex,
							})
							
						}
						
					}
					
				}
				
			} else {
				
				console.error('materials is not array')
				
			}
			
			Logger.groupEnd(logAllow)
			
			return questionIndex;
			
		},
		
		afterSubmitSuccess: (response, materialId, variantId) => {
			
			const logName = 'MaterialsPage.questions.afterSubmitSuccess';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let currentMaterial = this.state.currentMaterial;
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			
			let currentMaterialQuestions = currentMaterial.questions;
			Logger.log(currentMaterialQuestions, 'questions', logAllow);
			
			let questionData = response.data;
			Logger.log(questionData, 'questionData', logAllow);
			
			let editedQuestion = new Question(questionData);
			Logger.log(editedQuestion, 'question', logAllow);
			
			let isNewQuestion = currentMaterialQuestions.filter(x => x.id == editedQuestion.id).length < 1;
			Logger.log(isNewQuestion, 'isCreated', logAllow);
			
			let currentQuestionIndex = this.state.questionToEditIndex;
			
			let materials = this.state.materials;
			
			if (editedQuestion.isCard()) {
				
				let groupIndex = currentMaterialQuestions.findIndex(x => x.id == editedQuestion.group_id);
				Logger.log(groupIndex, 'groupIndex', logAllow);
				
				currentQuestionIndex = groupIndex;
				
				if (groupIndex >= 0) {
					
					let group = currentMaterialQuestions[groupIndex];
					Logger.log(group, 'group', logAllow);
					
					if (group) {
						
						let cards = group.group_items;
						
						let cardIndex = cards.findIndex(x => x.id == editedQuestion.id);
						Logger.log(cardIndex, 'cardIndex', logAllow);
						
						if (cardIndex >= 0) {
							cards[cardIndex] = editedQuestion;
						} else {
							cards.push(editedQuestion);
						}
						
						group.group_items = cards;
						
					}
					
					currentMaterialQuestions[groupIndex] = group;
					
				}
				
			} else {
				
				// если это НЕ карточка
				
				// определяем целевой материал
				
				let targetMaterial = currentMaterial
				let targetMaterialIndex = this.state.currentMaterialIndex
				
				if (materialId) {
					
					targetMaterialIndex = materials.findIndex((material) => {
						return material.id == materialId
					})
					
					targetMaterial = materials[targetMaterialIndex]
					
				}
				
				if (currentMaterial.id == targetMaterial.id) {
					
					// если МАТЕРИАЛ прежний
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							currentQuestionIndex = this.questions.setVariant(editedQuestion, materialId, variantId)
							
							// this.setState({
							// 	currentVariant: ,
							// })
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий текущего материала
							
							currentMaterial.questions.push(editedQuestion)
							
							currentQuestionIndex = currentMaterial.questions.length - 1
							
							this.setState({
								currentVariant: null,
							})
							
						}
						
					} else {
						
						// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое
						
						// ищем задание в вариантах - и удаляем оттуда
						
						targetMaterial.variants.forEach((variant) => {
							
							let variantQuestionIndex = variant.children.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							if (variantQuestionIndex >= 0) {
								variant.children.splice(variantQuestionIndex, 1)
							}
							
						})
						
						if (variantId) {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// помещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ прежний + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// заменяем это задание внутри текущего материала
							
							let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
								return question.id == editedQuestion.id
							})
							
							currentMaterial.questions[editedQuestionIndex] = editedQuestion
							
						}
						
					}
					
				} else {
					
					// если МАТЕРИАЛ изменился
					
					if (isNewQuestion) {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ новое + ВАРИАНТ не задан
							
							// добавляем в общий список заданий заданного варианта
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
						// переключаемся на этот материал
						currentMaterial = targetMaterial
						
					} else {
						
						// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое
						
						// удаляем задание из текущего материала
						
						let editedQuestionIndex = currentMaterial.questions.findIndex((question) => {
							return question.id == editedQuestion.id
						})
						
						currentMaterial.questions.splice(editedQuestionIndex, 1)
						
						currentQuestionIndex = 0
						
						if (variantId) {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ задан
							
							// перемещаем в заданный вариант
							
							this.questions.setVariant(editedQuestion, materialId, variantId)
							
						} else {
							
							// если МАТЕРИАЛ изменился + ЗАДАНИЕ старое + ВАРИАНТ не задан
							
							// перемещаем в другой материал
							
							targetMaterial.questions.push(editedQuestion)
							
						}
						
					}
					
				}
				
				/*
				
				// старая рабочая версия БЕЗ УЧЁТА ВАРИАНТОВ
				
				if (isNewQuestion) {
				
					// создано новое задание
					
					// добавляем его в текущий список заданий
					questions.push(editedQuestion);
					
					// и переключаемся на него
					currentQuestionIndex = questions.length - 1;
					
				} else {
					
					// изменено старое задание
					
					if (materialId == currentMaterial.id) {
						
						// если задание НЕ перемещёно в другой материал
						questions[currentQuestionIndex] = editedQuestion;
						
					} else {
						
						// если задание перемещёно в другой материал
						
						// удаляем его из текущего материала
						questions.splice(currentQuestionIndex, 1);
						
						// и добавляем в другой

						let targetMaterialIndex = materials.findIndex((material) => {
							return material.id == materialId
						});

						let targetMaterial = materials[targetMaterialIndex];
						
						// todo переписать с использованием findIndex
						// materials.forEach((material, materialIndex) => {
						// 	if (material.id == materialId) {
						// 		targetMaterial = material;
						// 		targetMaterialIndex = materialIndex;
						// 	}
						// });
						
						targetMaterial.questions.push(editedQuestion);
						
						materials[targetMaterialIndex] = targetMaterial;
						
					}
					
				}
				*/
				
			}
			
			currentMaterial.questions = currentMaterialQuestions;
			
			Logger.log(currentMaterial, 'currentMaterial', logAllow);
			materials[this.state.currentMaterialIndex] = currentMaterial;
			
			this.questions.saveSort(currentMaterialQuestions);
			
			this.setState((prevState) => {
				return {
					materials: materials,
					currentMaterial: currentMaterial,
					questionToEdit: null,
					questionFormModalIsOpen: false,
					currentQuestionIndex: currentQuestionIndex,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		del: (bindId, questionIndex) => {
			
			const logName = 'MaterialsPage.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let material = this.state.currentMaterial;
			let question = material.questions[questionIndex];
			let questions = material.questions;
			
			Logger.log(bindId, 'bindId', logAllow);
			Logger.log(questionIndex, 'questionIndex', logAllow);
			
			if (!bindId) {
				window.alert(i18next.t("bind id not provided"));
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (!window.confirm(i18next.t("Hide task #{{id}}?", {id: question.id}))) {
				Logger.groupEnd(logAllow);
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('test-question-binds') + '/' + bindId,
				data: {
					'is_active': 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				this.props.preloader.hide();
				
				questions.splice(questionIndex, 1);
				
				let currentQuestionIndex = this.state.currentQuestionIndex;
				
				if (currentQuestionIndex > questions.length - 1) {
					currentQuestionIndex = questions.length - 1;
				}
				
				this.setState((prevState) => {
					return {
						currentMaterial: material,
						currentQuestionIndex: currentQuestionIndex,
					}
				});
				
			}).catch((error) => {
				this.props.preloader.hide();
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		saveSort: (questions, afterSuccess) => {
			
			const logName = 'MaterialsPage.questions.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			Logger.log(questions, 'questions', logAllow);
			
			questions.forEach((question) => {
				// Logger.log(question, 'question', logAllow);
				if (question) {
					ids.push(question.id);
				}
			});
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'post',
				url: Utils.apiUrl('test-question-binds/save-sort'),
				data: {
					material_id: this.state.currentMaterial.id,
					questions_ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.materials.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				if (afterSuccess) {
					afterSuccess(response);
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			const logName = 'MaterialsPage.questions.moveUp';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			let questions = material.questions;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = questions.length - 1;
			}
			
			Logger.log(questions, 'questions (before move)', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			Logger.log(questions, 'questions (after move)', logAllow);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveDown: (currentIndex) => {
			
			const logName = 'MaterialsPage.moveDown';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			Logger.log(currentIndex, 'currentIndex', logAllow);
			
			let newIndex;
			
			let material = this.state.currentMaterial;
			Logger.log(material, 'material', logAllow);
			
			let questions = material.questions;
			Logger.log(questions, 'questions', logAllow);
			
			if (currentIndex === questions.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			Logger.log(newIndex, 'newIndex', logAllow);
			
			Utils.arrayMove(questions, currentIndex, newIndex);
			
			material.questions = questions;
			
			this.setState((prevState) => {
				return {
					currentMaterial: material,
					currentQuestionIndex: newIndex,
				}
			});
			
			this.questions.saveSort(questions);
			
			Logger.groupEnd(logAllow);
			
		},
		
		edit: (question, questionIndex) => {
			
			const logName = 'MaterialsPage.questions.edit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(question, 'question', logAllow);
			
			this.setState((prevState) => {
				return {
					questionToEdit: question,
					questionToEditIndex: questionIndex,
					questionFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		goto: (questionIndex) => {
			
			let currentMaterial = this.state.currentMaterial;
			
			if (currentMaterial) {
				
				Config.setQuestionIndex(currentMaterial.id, questionIndex);
				
				this.setState((prevState) => {
					return {
						currentQuestionIndex: questionIndex,
					}
				});
				
			}
			
		},
		
		prev: () => {
			// let currentMaterial = this.state.currentMaterial
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayPrevIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
		next: () => {
			// let currentMaterial = this.state.currentMaterial;
			let questions = this.questions.get()
			let currentIndex = this.state.currentQuestionIndex
			let newIndex = Utils.arrayNextIndex(questions, currentIndex)
			this.questions.goto(newIndex)
		},
		
	};
	
	homework = {
		
		formModalToggle: () => {
			this.setState((prevState) => {
				return {
					homeworkFormModalIsOpen: !prevState.homeworkFormModalIsOpen,
				}
			});
		},
		
	};
	
	groups = {
		
		load: () => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('groups'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					'filter[is_active]': 1,
				},
			}).then((response) => {
				
				const logName = 'MaterialsPage.groups.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let groups = [];
				
				response.data.forEach((groupData) => {
					let group = new Group(groupData);
					groups.push(group);
				});
				
				this.setState((prevState) => {
					return {
						groups: groups,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.theme !== prevProps.theme
			|| this.state.materialsShowArchived !== prevState.materialsShowArchived
			|| this.state.materialsShowNotPublic !== prevState.materialsShowNotPublic
		) {
			this.materials.loadList();
		}
	}
	
	componentDidMount() {
		
		this.materials.loadList();
		
		if (this.props.user.is('teacher')) {
			this.groups.load();
		}
		
	}
	
	render() {
		
		const logName = 'MaterialsPage.render'
		const logAllow = 1
		const logCollapsed = 1
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		Logger.log(this.props, 'this.props', logAllow)
		Logger.log(this.state, 'this.state', logAllow)
		
		const user = this.props.user;
		
		const userIsOwner = user.is(['owner'])
		Logger.log(userIsOwner, 'userIsOwner', logAllow)
		
		const currentMaterial = this.state.currentMaterial
		Logger.log(currentMaterial, 'currentMaterial', logAllow)
		
		const currentCourse = this.props.course
		
		let currentMaterialGroupsBindsWithLimitedAccess = currentMaterial
			? currentMaterial.getGroupsBindsWithLimitedAccess(user.groups_ids)
			: []
		
		Logger.log(currentMaterialGroupsBindsWithLimitedAccess, 'currentMaterialGroupsBindsWithLimitedAccess', logAllow)
		
		if (user.isStudent())  {
			currentMaterialGroupsBindsWithLimitedAccess = currentMaterialGroupsBindsWithLimitedAccess
				.filter(x => user.groups_ids.includes(x.group_id))
		}
		
		const currentVariant = this.state.currentVariant
		Logger.log(currentVariant, 'currentVariant', logAllow)
		
		if (currentMaterial) {
			Logger.log(currentMaterial.name, 'currentMaterial.name', logAllow);
			Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
		}
		
		// const answerForm = question.about.replaceAll('___', '<b>INPUT</b>');
		
		let imgUrls = [];
		
		if (this.state.currentMaterial) {
			imgUrls = this.state.currentMaterial.images.map((imgUploadBindData) => {
				return imgUploadBindData.url;
			});
		}
		
		const backBtnTitle = i18next.t("Back to courses, lessons and themes");
		const backBtn = <BackBtn onClick={this.props.back} title={backBtnTitle}/>;
		
		// path
		
		const materialPathData = [
			this.props.lesson.name + ' [#' + this.props.lesson.id + ']',
			this.props.theme.name + ' [#' + this.props.theme.id + ']',
		];
		
		if (currentMaterial) {
			materialPathData.push(currentMaterial.name + ' [#' + currentMaterial.id + ']');
		}
		
		const materialPath = materialPathData.join(' › ');
		
		// title
		
		const currentTheme = this.props.theme;
		const currentThemeNum = this.props.currentThemeIndex + 1;
		const themesCount = this.props.themes.length;
		const themeNum = i18next.t("{{num}} / {{count}}", {
			'num': currentThemeNum,
			'count': themesCount,
		});
		
		const materialTitleData = [
			currentTheme.name,
		];
		
		let currentMaterialNum = this.state.currentMaterialIndex === null ? 0 : this.state.currentMaterialIndex + 1;
		let materialsCount = this.state.materials.length;
		let materialsCounter = i18next.t("{{num}} / {{count}}", {
			'num': currentMaterialNum,
			'count': materialsCount,
		});
		
		if (currentMaterial) {
			materialTitleData.push(materialsCounter + currentMaterial.name + (user.can('debugInfo') ? ' #' + currentMaterial.id : ''));
		}
		
		const materialControls = currentMaterial ? (
			
			<div className="list controls material-controls">
				
				{(this.props.user.can('printMaterials')) &&
					<button
						type={'button'}
						className={[
							'print-btn',
							'my-btn',
							'my-btn-sm',
						].join(' ')}
						onClick={() => {
							window.print();
						}}
					><Icon.Printer/> {i18next.t("Print PDF")}</button>
				}
				
				{(this.props.user.can('manageMaterials') || this.props.user.can('manageMaterialsAccess')) &&
					<button
						type={'button'}
						className={[
							'my-btn',
							'my-btn-sm',
							'edit-material-btn',
							'material-edit-btn',
						].join(' ')}
						onClick={(event) => {
							this.materials.edit(this.state.currentMaterialIndex);
						}}
						disabled={this.state.materialToEdit}
					><Icon.Pencil/> {i18next.t("Edit")}</button>
				}
				
				{(this.props.user.can('manageMaterials')) &&
					
					<>
						
						{/*{this.state.materials.length > 1 && (
							<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									this.materials.moveUp(this.state.currentMaterialIndex);
								}}
							><Icon.ArrowUp/> {i18next.t("Move up")}</button>
						)}*/}
						
						{/*{this.state.materials.length > 1 && (
							<button
								type={'button'}
								className={[
									'my-btn',
									'my-btn-sm',
								].join(' ')}
								onClick={(event) => {
									this.materials.moveDown(this.state.currentMaterialIndex);
								}}
							><Icon.ArrowDown/> {i18next.t("Move down")}</button>
						)}*/}
						
						{/*<button
							type={'button'}
							className={[
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								// event.stopPropagation();
								this.materials.upd(this.state.currentMaterialIndex, {
									is_public: 1,
								});
							}}
						><Icon.Trash/> {i18next.t("Hide")}</button>*/}
						
						<button
							type={'button'}
							className={[
								'add-task-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.setState((prevState) => {
									return {
										questionToEdit: null,
										questionFormModalIsOpen: true,
									}
								});
							}}
						>+ {i18next.t("Task")}</button>
						
						<button
							type={'button'}
							className={[
								'add-card-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								if (this.state.currentMaterial) {
									this.materials.addCard(this.state.currentMaterial.id);
								}
							}}
						>+ {i18next.t("Card")}</button>
						
						<button
							type={'button'}
							className={[
								'add-group-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.materials.addGroup();
							}}
							title={i18next.t("Add group of tasks")}
						>+ {i18next.t("Group")}</button>
						
						<button
							type={'button'}
							className={[
								'add-variant-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.materials.addVariant();
							}}
							title={i18next.t("Add variant")}
						>+ {i18next.t("Variant")}</button>
						
						<button
							type={'button'}
							className={[
								'disable-unwanted-use-answer-templates-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.materials.disableUnwantedUseAnswerTemplates(currentMaterial.id);
							}}
							title={i18next.t("Disable unwanted using of answer templates")}
						>{i18next.t("Dsbl unwntd usg of answr tpls")}</button>
					
					</>
					
				}
			
			</div>
		
		) : null;
		
		const materialForm = (
			<MaterialForm
				model={this.state.materialToEdit}
				themeId={this.props.theme.id}
				cancel={() => {
					this.setState((prevState) => {
						return {
							materialToEdit: null,
							materialFormModalIsOpen: false,
						}
					});
				}}
				afterCreate={this.materials.afterCreate}
				afterUpdate={this.materials.afterUpdate}
				themes={this.props.themes}
				preloader={this.props.preloader}
				user={this.props.user}
				course={this.props.course}
			/>
		);
		
		let pdfs = [];
		let notPdfs = [];
		
		if (currentMaterial) {
			const pdfData = Utils.splitPdfsAndNotPdfs(currentMaterial.files);
			pdfs = pdfData.pdfs;
			notPdfs = pdfData.notPdfs;
		}
		
		let questionToEdit = this.state.questionToEdit;
		
		let questions = this.questions.get()
		Logger.log(questions, 'questions', logAllow);
		
		let currentQuestionIndex = this.state.currentQuestionIndex;
		Logger.log(currentQuestionIndex, 'currentQuestionIndex [raw]', logAllow);
		
		if (currentQuestionIndex >= questions.length) {
			currentQuestionIndex = 0;
		}
		
		Logger.log(currentQuestionIndex, 'currentQuestionIndex [final]', logAllow);
		
		let currentQuestion = questions[currentQuestionIndex];
		Logger.log(currentQuestion, 'currentQuestion', logAllow);
		
		Logger.log(currentMaterial?.questions_page_size, 'currentMaterial?.questions_page_size', logAllow)
		Logger.log(currentMaterial?.is_trainer, 'currentMaterial?.is_trainer', logAllow)
		
		const showQuestionsAsList =
			currentMaterial?.questions_page_size == -1
			// (currentMaterial?.questions_page_size == -1 || currentMaterial?.is_trainer == 1)
			// && (questions.length <= Config.maxQuestionsPageSize)
		
		Logger.log(showQuestionsAsList, 'questionsAsList', logAllow)
		
		const notAnsweredQuestions = questions.filter(x => !x.latest_answer)
		Logger.log(questions, 'questions', logAllow)
		Logger.log(notAnsweredQuestions, 'answeredQuestions', logAllow)
		
		const isExam = currentMaterial?.is_exam == 1
		Logger.log(isExam, 'isExam', logAllow)
		
		// let hasAccess = currentMaterial?.hasAccess(user.groups_ids)
		let hasAccess = true
		Logger.log(hasAccess, 'hasAccess', logAllow)
		
		const isFinished = notAnsweredQuestions.length < 1
		Logger.log(isFinished, 'isFinished', logAllow)
		
		let visibleQuestions = showQuestionsAsList
			? questions
			: currentQuestion
				? [currentQuestion]
				: []
		
		let allMaterials = this.state.materials
		
		let filteredMaterials = allMaterials
		
		if (this.state.materialSearchName) {
			
			// let regex = new RegExp(String.raw`${this.state.materialSearchName}`, "i");
			// Logger.log(regex, 'regex', logAllow)
			
			filteredMaterials = allMaterials.filter((material) => {
				// return regex.test(material.name)
				return material.name.toLowerCase().includes(this.state.materialSearchName.toLowerCase())
			})
			
		}
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialsPage'}>
				
				<div className="content-root row">
					
					<section className="current-material-col col-xl-9 col-lg-8 col-sm-12 order-2 order-md-1">
						
						{/* md+ nav panel */}
						<div className="nav-panel theme-nav-panel page-head-box d-none d-md-block">
							
							<div className={'row'}>
								
								<div className="col-auto back-btn-col align-self-center">
									{backBtn}
								</div>
								
								{/*<div className={'title-col col align-self-center'}>
									<small className={'theme-info text-muted'}>
										{this.props.course.name} &bull; {this.props.lesson.name} &bull; {i18next.t("Theme")} {themeNum}
									</small>
									<h2 className={'theme-name'}>
										{currentTheme.name}
									</h2>
									{(currentMaterial) &&
										<small className={'material-name text-muted'}>{i18next.t("Material")} {materialsCounter} &bull; {currentMaterial.name}</small>
									}
								</div>*/}
								
								<div className={'title-col col align-self-center'}>
									
									<small className={'theme-info text-muted'}>
										
										{this.props.course.name} &bull; {this.props.lesson.name}
										
										{(currentMaterial) && (
											<> &bull; {currentTheme.name}</>
										)}
										
										
									</small>
									
									{(currentMaterial) ? (
										<h2 className={'material-name'}>
											{currentMaterial.name}
										</h2>
									) : (
										<h2 className={'theme-name'}>
											{currentTheme.name}
										</h2>
									)}
									
									{(currentMaterial) &&
										<small className={'material-name text-muted'}>
											{i18next.t("Theme")} {themeNum} &bull; {i18next.t("Material")} {materialsCounter}
										</small>
									}
									
								</div>
								
								<div className={'controls-col col-12 col-md-auto text-center text-md-right mt-3 mt-md-0'}>
									
									{(themesCount > 1) &&
										<>
											<button
												type={'button'}
												className={[
													'prev-theme-btn',
													'my-btn',
													'my-btn-sm',
													// 'btn-primary',
												].join(' ')}
												onClick={this.props.showPrevTheme}
												title={[
													i18next.t("Previous theme"),
													this.props.prevTheme.name,
													(this.props.prevThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + this.props.themes.length,
												].join(' | ')}
											><Icon.ChevronLeft/></button>
											
											<button
												type={'button'}
												className={[
													'next-theme-btn',
													'my-btn',
													'my-btn-sm',
													// 'btn-primary',
												].join(' ')}
												onClick={this.props.showNextTheme}
												title={[
													i18next.t("Next theme"),
													this.props.nextTheme.name,
													(this.props.nextThemeIndex + 1) + ' ' + i18next.t("of") + ' ' + this.props.themes.length,
												].join(' | ')}
											><Icon.ChevronRight/></button>
										</>
									}
									
									<button
										type={'button'}
										className={[
											'reload-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={() => {
											this.materials.loadList();
										}}
										title={i18next.t("Reload")}
									><Icon.ArrowCounterclockwise/></button>
									
									<button
										type={'button'}
										className={[
											'close-theme-btn',
											'my-btn',
											'my-btn-sm',
											// 'btn-primary',
										].join(' ')}
										onClick={this.props.back}
										title={backBtnTitle}
									><Icon.X/></button>
								
								</div>
							
							</div>
						
						</div>
						
						{/* sm- nav panel */}
						<div className="nav-panel mobile-theme-nav-panel page-head-box d-md-none">
							
							<small className={'text-muted'}>
								<b>{i18next.t("Course")}</b>: {this.props.course.name}
								&nbsp;&bull; <b>{i18next.t("Lesson")}</b>: {this.props.lesson.name}
								&nbsp;&bull; {i18next.t("Theme")} {themeNum}
							</small>
							
							<h2>{backBtn} {this.props.theme.name}</h2>
							
							{currentMaterial ? (
								
								<>
									
									{(this.state.materials.length > 1) && (
										
										<>
											
											<div className="list current-material-preview-box">
												
												<ListItem
													onClick={() => {
														this.setState((prevState) => {
															return {
																currentMaterial: null,
															}
														});
													}}
												>
													
													<div className="row align-items-center">
														
														<div className="col name-col">
															{currentMaterial.name}
														</div>
														
														<div className="col-auto change-btn-col">
															<div className="change-btn">
																{i18next.t("Select")}
															</div>
														</div>
													
													</div>
												
												</ListItem>
											
											</div>
											
										</>
									
									)}
								
								</>
							
							) : (
								
								<>
									
									<div className="list materials-list materials-list-mobile">
										
										{this.state.materials.length > 0 ? (
											
											<>
												
												{this.state.materials.map((material, materialIndex) => {
													
													const logName = 'MaterialsPage.materials.map';
													const logAllow = 0;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													// Logger.log(material, 'material', logAllow);
													// Logger.log(material.name, 'material.name', logAllow);
													
													Logger.groupEnd(logAllow);
													
													return (
														<ListItem
															onClick={() => {
																this.materials.setCurrentByIndex(materialIndex);
															}}
														>{material.name}</ListItem>
													);
												})}
											</>
										
										) : (
											
											<>
												<div className={`not-found-msg materials-not-found-msg`}>
													<hr/>
													{i18next.t("Materials not found")}
												</div>
											</>
										
										)}
									
									</div>
								
								</>
							
							)}
						
						</div>
						
						{(currentMaterial && (user.can('manageMaterials') || user.can('manageMaterialsAccess') || hasAccess)) && (
							
							<>
								
								{(Boolean(this.state.currentMaterialIsLoading)) ? (
									
									<PreloaderProgressBar/>
									
								) : (
									
									<div
										className={'material-view'}
										// data-type-alias={currentMaterial.type_alias}
										data-current-question-id={currentQuestion?.id}
									>
										
										{/* material controls */}
										{(
											user.can('printMaterials')
											|| user.can('manageMaterials')
											|| user.can('manageMaterialsAccess')
										) && materialControls}
										
										{/* material form */}
										{(this.state.materialToEdit) && (
											<div className={'list body'}>
												{materialForm}
											</div>
										)}
										
										{/* material dynamic DEBUG info */}
										{(Utils.isLoc() && currentMaterial.is_dynamic == 1 && user.isStudent()) && (
											
											<>
												<div className={`dynamic-debug-info text-center`}>
													<code>currentMaterial.dynamic.id = {currentMaterial?.dynamic?.id}</code>
												</div>
											</>
										
										)}
										
										{/* media content */}
										{(!this.state.materialToEdit && currentMaterial.hasContent()) && (
											
											<div className={'list body'}>
												
												{currentMaterial.youtube_urls && (
													<div className="youtube-widget-box embed-responsive embed-responsive-16by9 mb-2">
														{YouTubeHelper.widget(currentMaterial.youtube_urls)}
													</div>
												)}
												
												{(Array.isArray(currentMaterial.video_links) && currentMaterial.video_links.length > 0) && (
													
													<div className={`video-links`}>
														
														<div className={`section-header`}>{i18next.t('Video')}</div>
														
														<div className={`video-cards`}>
															
															<div className={`row`}>
																
																{currentMaterial.video_links.map((videoLinkData) => {
																	
																	let colSize = 12
																	
																	let videoUrl = videoLinkData.url
																	
																	let videoLinkView = (
																		<div className={`video-link-box`}>
																			<span className={`d-inline-block mr-2`}><Icon.CameraVideo/></span>
																			<a href={videoUrl} target={'_blank'} className={``}>
																				{VideoLinkHelper.urlToName(videoUrl)}
																			</a>
																		</div>
																	)
																	
																	let videoWidget = VideoLinkHelper.makeWidget(videoUrl)
																	
																	return (
																		
																		<>
																			
																			<div className={`col-md-${colSize}`}>
																				
																				<div className={`card video-card mb-3`}>
																					
																					<div className={`card-header`}>
																						{videoLinkView}
																					</div>
																					
																					<div className={`video-widget-box embed-responsive embed-responsive-16by9 preloader-bg`}>
																						{videoWidget}
																					</div>
																				
																				</div>
																			
																			</div>
																		
																		</>
																	
																	)
																	
																})}
															
															</div>
														
														</div>
													
													</div>
												
												)}
												
												{currentMaterial.about && (
													<div className="text question-text" dangerouslySetInnerHTML={{__html: currentMaterial.about}}></div>
												)}
												
												{pdfs.map((uploadBindData) => {
													return (
														<PdfView
															url={uploadBindData.url}
															// url={uploadBindData.url_with_original_name}
															name={i18next.t("Download") + ': ' + uploadBindData.original_name}
															nameUrl={uploadBindData.url_with_original_name}
															// toolbar={true}
														/>
													);
												})}
												
												{currentMaterial.images.length > 0 && (
													
													<div className={'images media-list row'}>
														
														{currentMaterial.images.map((uploadBindData, index) => {
															
															return (
																
																<div className={'media-box img-box col'}>
																	
																	<a href={uploadBindData.url}
																	   className={'img-link'}
																	   target={'_blank'}
																	   key={'img_' + index}
																	>
																		<img src={uploadBindData.preview_url}
																			 alt="img"
																			 onClick={(event) => {
																				 // todo
																			 }}
																			 className={[
																				 'clickable',
																			 ].join(' ')}
																		/>
																	</a>
																	
																	<br/>
																	
																	<small className={'upload-name text-muted'}>{uploadBindData.name}</small>
																
																</div>
															
															)
															
														})}
													
													</div>
												
												)}
												
												{currentMaterial.sounds.length > 0 && (
													
													<div className={'sounds media-list row'}>
														{currentMaterial.sounds.map((uploadData) => {
															return (
																<div className="col media-col sound-col">
																	<div className="media-box sound-box">
																		<audio
																			src={uploadData.url}
																			controls={true}
																		/>
																		<br/><small
																		className={'upload-name text-muted'}>{uploadData.name}</small>
																	</div>
																</div>
															);
														})}
													</div>
												
												)}
												
												{currentMaterial.videos.length > 0 && (
													
													<div className={'videos media-list video-list'}>
														{currentMaterial.videos.map((uploadData) => {
															return (
																<div className="media-box video-box">
																	<video
																		src={uploadData.url}
																		controls={true}
																	/>
																	<br/><small className={'upload-name text-muted'}>{uploadData.name || uploadData.original_name_only}</small>
																</div>
															);
														})}
													</div>
												
												)}
												
												{notPdfs.length > 0 && (
													<div className={'files media-list mt-3'}>
														<div className={`head bold`}>
															{i18next.t('Files')}
														</div>
														<UploadsList uploads={notPdfs}/>
													</div>
												)}
											
											</div>
										
										)}
										
										{/* variants panel */}
										{(currentMaterial.variants.length > 0) && (
											
											<div className={`variants-controls`}>
												
												{(currentMaterial.questions.length > 0 && !user.isStudent()) && (
													<button
														className={[
															'my-btn',
															// 'my-btn-active',
														].join()}
														disabled={!currentVariant}
														onClick={(event) => {
															this.materials.setVariant(null)
														}}
													>{i18next.t('Out of variants')}</button>
												)}
												
												{currentMaterial.variants.map((variant, variantIndex) => {
													
													let debugInfo = user.canAny(['debugInfo', 'smallDebugInfo'])
														? `TestQuestion #${variant.id}`
														: ''
													
													return (
														<div className={`variant-box`}>
															
															<button
																className={[
																	'variant-set-btn',
																	'my-btn',
																].join(' ')}
																onClick={(event) => {
																	this.materials.setVariant(variant)
																}}
																disabled={currentVariant && currentVariant.id == variant.id}
																title={debugInfo}
																data-variant-question-id={variant.id}
															>
																{i18next.t('Variant')} {user.isStudent() ? variant.name : variantIndex + 1}
																{(user.canAny(['debugInfo'])) && (
																	<div>
																		<small className={`text-muted debug-info`}>#{variant.id}</small>
																	</div>
																)}
															</button>
															
															<br/>
															
															{(user.can('manageMaterials')) && (
																<button
																	className={[
																		'variant-del-btn',
																		'del-variant-btn',
																		'my-btn',
																		'my-btn-xs',
																		'my-btn-danger',
																	].join(' ')}
																	onClick={(event) => {
																		this.materials.delVariant(variant.id)
																	}}
																	title={debugInfo}
																>{i18next.t('Delete')}</button>
															)}
														
														</div>
													)
												})}
											
											</div>
										
										)}
										
										{/* limited access warning */}
										{(currentMaterialGroupsBindsWithLimitedAccess.length > 0) && (
											
											<div className={`limited-access-warning-box my-3`}>
												
												<div className={`limited-access-warning list-item orange-box`}>
													
													<b>{i18next.t("Limited access")}</b>
													
													<div className={`groups-binds access-list my-2`}>
														
														{currentMaterialGroupsBindsWithLimitedAccess.map((materialGroupBind) => {
															
															const logName = 'material.groups_binds.map'
															const logAllow = 0
															const logCollapse = 0
															
															Logger.groupStart(logName, logAllow, logCollapse)
															
															Logger.log(materialGroupBind, 'materialGroupBind', logAllow)
															
															// отображать студенту только те доступы, который относятся к его группам
															// if (user.isStudent() && !user.groups_ids.includes(materialGroupBind.group_id)) {
															// 	return null
															// }
															
															// let dateTimeFormat = 'DD.MM.YY hh:mm'
															
															// let accessStartDateTime = moment(materialGroupBind.access_start_unixtime * 1000).format(dateTimeFormat)
															
															// let accessEndDateTime = materialGroupBind.access_end_unixtime
															// 	? moment(materialGroupBind.access_end_unixtime * 1000).format(dateTimeFormat)
															// 	: i18next.t("Not restricted")
															
															let accessStartDateTime =
																
																DateHelper.formatDate({
																	date: materialGroupBind.access_start_unixtime * 1000,
																	format: 'd.m.y',
																})
																
																+ ' ' +
																
																DateHelper.formatTime({
																	time: materialGroupBind.access_start_unixtime * 1000,
																	format: 'h:m',
																})
															
															// let accessEndDateTime = materialGroupBind.access_end
															// 	? materialGroupBind.access_end
															// 	: i18next.t("Not restricted")
															
															let accessEndDateTime =
																
																(materialGroupBind.access_end_date != materialGroupBind.access_start_date
																	
																	? DateHelper.formatDate({
																		date: materialGroupBind.access_end_unixtime * 1000,
																		format: 'd.m.y',
																	})
																	
																	: '')
																
																+ ' ' +
																
																DateHelper.formatTime({
																	time: materialGroupBind.access_end_unixtime * 1000,
																	format: 'h:m',
																})
															
															Logger.groupEnd(logAllow)
															
															return (
																
																<div className={`group-bind access-list-item my-2`}>
																	{i18next.t("Group")} {materialGroupBind.group_name} : {accessStartDateTime} – <b>{accessEndDateTime}</b>
																</div>
															
															)
															
														})}
													
													</div>
												
												</div>
											
											</div>
										
										)}
										
										{/* questions nav controls */}
										{(!(isExam && isFinished) && !showQuestionsAsList && visibleQuestions.length > 0) && (
											
											<div className={'questions-nav-controls row my-3'}>
												
												<div className="col-auto col-md-4 prev-btn-col">
													<button
														type={'button'}
														className={[
															'my-btn',
															'prev-question-btn',
														].join(' ')}
														onClick={(event) => {
															this.questions.prev();
														}}
													><Icon.ChevronLeft/> <span className={'btn-text d-none d-sm-inline'}>{i18next.t("Prev. page")}</span></button>
												</div>
												
												<div className="col col-md-4 goto-btn-col text-center">
													<Dropdown
														className={'goto-menu d-inline-block'}
													>
														
														<Dropdown.Toggle
															className={[
																'my-btn',
																'my-btn-default',
																'my-0',
																'mx-0',
															].join(' ')}
														>{i18next.t("Page")} {currentQuestionIndex + 1} / {questions.length}</Dropdown.Toggle>
														
														<Dropdown.Menu>
															
															{Range.get(0, questions.length - 1).map((newIndex) => {
																const num = newIndex + 1;
																const isCurrent = newIndex == currentQuestionIndex;
																return (
																	<Dropdown.Item
																		onClick={(event) => {
																			this.questions.goto(newIndex)
																		}}
																		disabled={isCurrent}
																	>{num}</Dropdown.Item>
																);
															})}
														
														</Dropdown.Menu>
													
													</Dropdown>
												</div>
												
												<div className="col-auto col-md-4 next-btn-col text-right">
													<button
														type={'button'}
														className={[
															'my-btn',
															'next-question-btn',
														].join(' ')}
														onClick={(event) => {
															this.questions.next();
														}}
													><span className={'btn-text d-none d-sm-inline'}>{i18next.t("Next page")}</span> <Icon.ChevronRight/></button>
												</div>
											
											</div>
										
										)}
										
										{/* questions list */}
										{(!(isExam && isFinished) && visibleQuestions.length > 0) && (
											
											<div className={'questions'}>
												
												{visibleQuestions.map((question, questionIndex) => {
													
													const logName = 'MaterialsPage.questions.map';
													const logAllow = 0;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													Logger.log(question, 'question', logAllow);
													
													questionIndex = showQuestionsAsList ? questionIndex : currentQuestionIndex
													Logger.log(questionIndex, 'questionIndex', logAllow)
													
													let latestAnswer = question.latest_answer;
													Logger.log(latestAnswer, 'latestAnswer', logAllow);
													
													Logger.groupEnd(logAllow);
													
													return (
														
														<div
															key={'question-' + question.id + '-box'}
															className={[
																'question-box',
																'list-item',
																'bg-' + question.bg_style_alias,
																'border-' + question.border_style_alias,
																latestAnswer
																	? currentMaterial.isExam()
																		? 'waiting'
																		: latestAnswer.is_waiting
																			? 'waiting'
																			: latestAnswer.is_right
																				? 'right-answer'
																				: 'wrong-answer'
																	: 'no-answer',
																question.isGroup() ? 'question-group-box' : '',
															].join(' ')}
														>
															
															<div
																className={'question-name text-center font-weight-bold'}
																title={'#' + question.id}
															>
																{i18next.t("Task")}&nbsp;
																{questions.length > 1
																	? i18next.t("{{num}} of {{count}}", {
																		num: questionIndex + 1,
																		count: questions.length
																	})
																	: questionIndex + 1}
															</div>
															
															<>
																
																{(question.isGroup()) &&
																	<QuestionGroupView
																		group={question}
																		user={user}
																		questions={question.group_items}
																		currentMaterial={this.state.currentMaterial}
																		preloader={this.props.preloader}
																		afterSubmit={this.questions.afterSubmitSuccess}
																		editCard={(card, cardIndex) => {
																			this.questions.edit(card);
																			// this.questions.editCard(card, cardIndex);
																		}}
																		afterHideSuccess={(response, cardIndex) => {
																			
																			let currentGroup = currentQuestion;
																			
																			let cards = currentGroup.group_items;
																			
																			// currentGroup.group_items.splice(cardIndex, 1);
																			cards.splice(cardIndex, 1);
																			
																			currentMaterial.questions[currentQuestionIndex] = currentGroup;
																			
																			this.setState((prevState) => {
																				return {
																					currentMaterial: currentMaterial,
																				}
																			});
																			
																		}}
																	/>
																}
																
																{(question.isCard()) &&
																	<QuestionCardView
																		card={question}
																		user={user}
																	/>
																}
																
																{(question.isRegular()) &&
																	<QuestionView
																		key={'question-' + question.id + '-view'}
																		question={question}
																		course={this.props.course}
																		lesson={this.props.lesson}
																		theme={this.props.theme}
																		material_id={currentMaterial.id}
																		// mode={this.props.user.is(['teacher', 'admin', 'owner']) ? 'demo' : 'pass'}
																		mode={'pass'}
																		user={this.props.user}
																		path={materialPath}
																		// num={questionIndex + 1}
																		preloader={this.props.preloader}
																		afterSendAnswer={(response, answer) => {
																			question.latest_answer = answer;
																			let questions = currentMaterial.questions;
																			questions[questionIndex] = question;
																			this.setState((prevState) => {
																				return {
																					currentMaterial: currentMaterial,
																				}
																			});
																		}}
																		reanswers={!currentMaterial.isExam()}
																		showAnswerState={!currentMaterial.isExam()}
																		alert={this.props.alert}
																		weightVisible={currentMaterial.is_weight_visible == 1}
																		questionsCount={currentMaterial.questions.length}
																		// showDiscuss={true}
																		checkAnswerBtnText={
																			currentMaterial.is_exam == 1
																				? i18next.t("Save")
																				: i18next.t("Check")
																		}
																		afterSendAnswerSuccess={(answer) => {
																			if (isExam) {
																				this.questions.next()
																			}
																		}}
																		hideSavedAnswer={isExam}
																	/>
																}
																
																{(user.canAny(['manageMaterials', 'manageHomework'])) && (
																	
																	<>
																		
																		<hr/>
																		
																		<div className="controls bottom-controls question-bottom-controls">
																			
																			{(this.props.user.can('manageMaterials')) &&
																				
																				<>
																					
																					<button
																						type={'button'}
																						className={[
																							'question-edit-btn',
																							'my-btn',
																							'my-btn-sm',
																						].join(' ')}
																						onClick={(event) => {
																							this.questions.edit(question, questionIndex);
																						}}
																						title={'#' + question.id}
																					>
																						<Icon.Pencil/> {i18next.t("Edit")}
																					</button>
																					
																					{questions.length > 1 && (
																						
																						<>
																							
																							<button
																								type={'button'}
																								className={[
																									'question-move-up-btn',
																									'my-btn',
																									'my-btn-sm',
																								].join(' ')}
																								onClick={(event) => {
																									this.questions.moveUp(questionIndex);
																								}}
																							>
																								<Icon.ArrowUp/> {i18next.t("Move up")}
																							</button>
																							
																							<button
																								type={'button'}
																								className={[
																									'question-move-down-btn',
																									'my-btn',
																									'my-btn-sm',
																								].join(' ')}
																								onClick={(event) => {
																									this.questions.moveDown(questionIndex);
																								}}
																							>
																								<Icon.ArrowDown/> {i18next.t("Move down")}
																							</button>
																							
																							<Dropdown
																								className={'sort-menu d-inline-block question-set-position-menu'}
																							>
																								
																								<Dropdown.Toggle
																									className={[
																										// 'btn-unstyled',
																										'question-set-position-btn',
																										'my-btn',
																										'my-btn-sm',
																										'my-btn-default',
																										'my-0',
																									].join(' ')}
																								>
																									{i18next.t("Set position")}
																								</Dropdown.Toggle>
																								
																								<Dropdown.Menu>
																									
																									{Range.get(0, currentMaterial.questions.length - 1).map((newIndex) => {
																										
																										const num = newIndex + 1;
																										
																										const isCurrent = newIndex == questionIndex;
																										
																										return (
																											<Dropdown.Item
																												onClick={(event) => {
																													
																													let questions = currentMaterial.questions;
																													
																													Utils.arrayMove(questions, questionIndex, newIndex);
																													
																													currentMaterial.questions = questions;
																													
																													this.setState((prevState) => {
																														return {
																															currentMaterial: currentMaterial,
																															currentQuestionIndex: newIndex,
																														}
																													});
																													
																													this.questions.saveSort(questions);
																													
																												}}
																												disabled={isCurrent}
																											>{num}</Dropdown.Item>
																										);
																										
																									})}
																								
																								</Dropdown.Menu>
																							
																							</Dropdown>
																						
																						</>
																					
																					)}
																					
																					<button
																						type={'button'}
																						className={[
																							'hide-question-btn',
																							'del-question-btn',
																							'remove-question-btn',
																							'my-btn',
																							'my-btn-sm',
																						].join(' ')}
																						onClick={(event) => {
																							this.questions.del(question.bind_id, currentQuestionIndex);
																						}}
																						disabled={!question.bind_id}
																						title={'bind: ' + question.bind_id}
																					><Icon.Trash/> {i18next.t("Hide")}</button>
																				
																				</>
																				
																			}
																			
																			<Dropdown className={'homework-menu'}>
																				
																				<Dropdown.Toggle
																					className={[
																						// 'btn-unstyled',
																						'my-btn',
																						'my-btn-sm',
																						'my-btn-default',
																					].join(' ')}
																				>
																					{i18next.t("Set as homework")}
																				</Dropdown.Toggle>
																				
																				<Dropdown.Menu>
																					
																					{(this.state.groups.length < 1) && (
																						<Dropdown.Item>
																							<div className={'empty-msg'}>
																								{i18next.t("Groups not found")}
																							</div>
																						</Dropdown.Item>
																					)}
																					
																					{this.state.groups.map((group, groupIndex) => {
																						let questionState = question.states.filter(questionState => questionState.group_id == group.id)[0];
																						let title = questionState && questionState.is_active
																							? i18next.t("UNMARK as homework for this group")
																							: i18next.t("MARK as homework for this group")
																						;
																						let alertMsg = questionState && questionState.is_active
																							? i18next.t("Task '{{name}}' unmarked as homework for group {{group}}", {
																								name: question.name,
																								group: group.name,
																							})
																							: i18next.t("Task '{{name}}' marked as homework for group {{group}}", {
																								name: question.name,
																								group: group.name,
																							})
																						;
																						let showAlert = 0;
																						return (
																							<Dropdown.Item
																								onClick={(event) => {
																									
																									if (questionState) {
																										
																										// toggle existing state
																										
																										if (this.props.preloader) {
																											this.props.preloader.show();
																										}
																										
																										axios({
																											method: 'put',
																											url: Utils.apiUrl('test-question-state') + '/' + questionState.id,
																											data: {
																												is_active: questionState.is_active ? 0 : 1,
																											},
																											params: {
																												'accessToken': Utils.getUserToken(),
																											},
																										}).then((response) => {
																											
																											const logName = 'MaterialsPage.questionState.ajax.done';
																											const logAllow = 1;
																											const logCollapsed = 0;
																											
																											Logger.groupStart(logName, logAllow, logCollapsed);
																											
																											Logger.log(response, 'response', logAllow);
																											
																											let state = response.data;
																											let stateIndex = null;
																											question.states.forEach((currentState, currentStateIndex) => {
																												if (currentState.id == state.id) {
																													stateIndex = currentStateIndex;
																												}
																											});
																											question.states[stateIndex] = state;
																											
																											currentMaterial.questions[questionIndex] = question;
																											
																											this.setState((prevState) => {
																												return {
																													currentMaterial: currentMaterial,
																												}
																											});
																											
																											if (this.props.preloader) {
																												this.props.preloader.hide();
																											}
																											
																											if (showAlert) {
																												window.alert(alertMsg);
																											}
																											
																											Logger.groupEnd(logAllow);
																											
																										}).catch((error) => {
																											Utils.axiosErrorAlert(error);
																										});
																										
																									} else {
																										
																										// create new state
																										
																										if (this.props.preloader) {
																											this.props.preloader.show();
																										}
																										
																										axios({
																											method: 'post',
																											url: Utils.apiUrl('test-question-state'),
																											data: {
																												group_id: group.id,
																												question_id: question.id,
																												material_id: currentMaterial.id,
																												state_alias: 'homework',
																												is_active: 1,
																											},
																											params: {
																												'accessToken': Utils.getUserToken(),
																											},
																										}).then((response) => {
																											
																											const logName = 'MaterialsPage.questionState.ajax.done';
																											const logAllow = 1;
																											const logCollapsed = 0;
																											
																											Logger.groupStart(logName, logAllow, logCollapsed);
																											
																											Logger.log(response, 'response', logAllow);
																											
																											let state = response.data;
																											question.states.push(state);
																											
																											currentMaterial.questions[questionIndex] = question;
																											
																											this.setState((prevState) => {
																												return {
																													currentMaterial: currentMaterial,
																												}
																											});
																											
																											if (this.props.preloader) {
																												this.props.preloader.hide();
																											}
																											
																											if (showAlert) {
																												window.alert(alertMsg);
																											}
																											
																											Logger.groupEnd(logAllow);
																											
																										}).catch((error) => {
																											Utils.axiosErrorAlert(error);
																										});
																									}
																								}}
																								title={title}
																							>
																								{questionState && questionState.is_active == 1 ? (
																									<b>{group.name}</b>
																								) : (
																									group.name
																								)}
																							
																							</Dropdown.Item>
																						);
																					})}
																				
																				</Dropdown.Menu>
																			
																			</Dropdown>
																		
																		</div>
																	
																	</>
																
																)}
															
															</>
															
															{user.can('manageQuestions') && (
																
																<Dropdown className={'question-menu overlay-menu'}>
																	
																	<Dropdown.Toggle></Dropdown.Toggle>
																	
																	<Dropdown.Menu>
																		
																		<Dropdown.Item
																			onClick={(event) => {
																				this.setState((prevState) => {
																					return {
																						questionToEdit: question,
																						questionToEditIndex: questionIndex,
																						questionFormModalIsOpen: true,
																					}
																				});
																			}}
																		>{i18next.t("Edit")}</Dropdown.Item>
																		
																		<Dropdown.Item
																			onClick={(event) => {
																				this.questions.moveUp(questionIndex);
																			}}
																		>{i18next.t("Move up")}</Dropdown.Item>
																		
																		<Dropdown.Item
																			onClick={(event) => {
																				this.questions.moveDown(questionIndex);
																			}}
																		>{i18next.t("Move down")}</Dropdown.Item>
																		
																		<Dropdown.Item
																			onClick={(event) => {
																				this.questions.del(question.bind_id, questionIndex);
																			}}
																		>{i18next.t("Hide")}</Dropdown.Item>
																		
																		{(user.can('copyMaterials')) &&
																			<>
																				<Dropdown.Item
																					onClick={(event) => {
																						
																						let materialId = window.prompt('Copy question to Material ID', currentMaterial.id);
																						
																						if (materialId) {
																							
																							axios({
																								method: 'get',
																								url: Utils.apiUrl('questions/copy'),
																								data: {},
																								params: {
																									accessToken: Utils.getUserToken(),
																									questionId: question.id,
																									materialId: materialId,
																								},
																							}).then((response) => {
																								
																								const logName = 'MaterialsPage.copy.ajax.done';
																								const logAllow = 1;
																								const logCollapsed = 0;
																								
																								Logger.groupStart(logName, logAllow, logCollapsed);
																								
																								Logger.log(response, 'response', logAllow);
																								
																								let copy = new Question(response.data);
																								Logger.log(copy, 'copy', logAllow);
																								
																								Logger.log(copy.material_id, 'copy.material_id', logAllow);
																								Logger.log(currentMaterial.id, 'currentMaterial.id', logAllow);
																								
																								if (copy.material_id == currentMaterial.id) {
																									
																									currentMaterial.questions.push(copy);
																									
																									this.setState((prevState) => {
																										return {
																											currentMaterial: currentMaterial,
																											currentQuestionIndex: currentMaterial.questions.length - 1,
																										}
																									});
																									
																								}
																								
																								window.alert(i18next.t("Copied successfully"));
																								
																								Logger.groupEnd(logAllow);
																								
																							}).catch((error) => {
																								console.log(error);
																								// window.alert(error.response.data.message);
																							});
																							
																						}
																					}}
																				>{i18next.t("Copy to")}...</Dropdown.Item>
																				
																				<Dropdown.Item
																					onClick={(event) => {
																						// todo question copy to
																						let toMaterialId = window.prompt('Material ID', currentMaterial.id);
																						if (toMaterialId) {
																							axios({
																								method: 'get',
																								url: Utils.apiUrl('questions/move'),
																								data: {},
																								params: {
																									'accessToken': Utils.getUserToken(),
																									questionId: question.id,
																									fromMaterialId: currentMaterial.id,
																									toMaterialId: toMaterialId,
																								},
																							}).then((response) => {
																								
																								const logName = 'MaterialsPage.move.ajax.done';
																								const logAllow = 1;
																								const logCollapsed = 0;
																								
																								Logger.groupStart(logName, logAllow, logCollapsed);
																								
																								Logger.log(response, 'response', logAllow);
																								
																								let questionIndex = currentMaterial.questions.indexOf(question);
																								currentMaterial.questions.splice(questionIndex, 1);
																								
																								this.setState((prevState) => {
																									return {
																										currentMaterial: currentMaterial,
																									}
																								});
																								
																								Logger.groupEnd(logAllow);
																								
																							}).catch((error) => {
																								console.log(error);
																								// window.alert(error.response.data.message);
																							});
																						}
																					}}
																				>{i18next.t("Move to")} ...</Dropdown.Item>
																			</>
																		}
																	
																	</Dropdown.Menu>
																
																</Dropdown>
															
															)}
														
														</div>
													
													);
													
												})}
											
											</div>
										
										)}
										
										{(isExam && user.isStudent() && (isFinished || !hasAccess)) && (
											
											<>
												<div className={`list-item list-item-success text-center finished-msg mt-3 list-group-item-success`}>
													
													<div className={`h3 pt-2 bold text-success`}>
														
														{i18next.t("Exam is finished")}
													
													</div>
												
												</div>
											</>
										
										)}
										
										{/*{materialControls}*/}
									
									</div>
									
								)}
								
							</>
							
						)}
					
					</section>
					
					<section className="materials-list-col col-xl-3 col-lg-4 col-sm-0 order-1 order-md-2 d-none d-md-block">
						
						<div className="materials-list-box">
							
							<div className="page-head-box">
								<h2>{i18next.t("Materials")}</h2>
							</div>
							
							<div className="list materials-list materials-list-desktop">
								
								{this.props.user.can('manageMaterials') && (
									
									<div className="controls top-controls materials-controls materials-top-controls">
										
										<button type={'button'}
												className={[
													'add-material-btn',
													'my-btn',
													// 'my-btn-sm',
													'my-btn-wide',
												].join(' ')}
												onClick={(event) => {
													this.setState((prevState) => {
														return {
															materialToEdit: null,
															materialFormModalIsOpen: true,
														}
													});
												}}
											// title={i18next.t("Add material")}
										>+ {i18next.t("Add material")}</button>
									
									</div>
								
								)}
								
								{this.props.user.can('showRemovedContent') && (
									
									<div className={'controls show-removed-controls text-center'}>
										<label htmlFor={'show-removed'}>
											<input
												id={'show-removed'}
												type={'checkbox'}
												checked={this.state.materialsShowArchived}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowArchived: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Archive")}
										</label>
									</div>
								
								)}
								
								{(this.props.user.can('seeMaterialsDrafts')) && (
									
									<div className={'controls show-draft-controls text-center'}>
										<label htmlFor={'show-not-public'}>
											<input
												id={'show-not-public'}
												type={'checkbox'}
												checked={this.state.materialsShowNotPublic}
												onChange={(event) => {
													this.setState((prevState) => {
														return {
															materialsShowNotPublic: event.target.checked,
														}
													});
													// this.materials.load();
												}}
											/> {i18next.t("Drafts")}
										</label>
									</div>
								
								)}
								
								{/* material nav controls */}
								{(allMaterials.length > 1 && this.state.currentMaterialIndex !== null) && (
									
									<div className="materials-nav-controls">
										
										<div className="row">
											
											<div className="col">
												<button
													type={'button'}
													className={[
														'prev-material-btn',
														'my-btn',
														// 'my-btn-sm',
														'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.materials.prev();
													}}
													title={i18next.t("Prev. material")}
												><Icon.ChevronLeft/></button>
											</div>
											
											<div className="col-auto">
												<div className="counter">
													{currentMaterialNum} / {this.state.materials.length}
												</div>
											</div>
											
											<div className="col">
												<button
													type={'button'}
													className={[
														'next-material-btn',
														'my-btn',
														// 'my-btn-sm',
														'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.materials.next();
													}}
													title={i18next.t("Next material")}
												><Icon.ChevronRight/></button>
											</div>
										
										</div>
									
									</div>
									
								)}
								
								{(allMaterials.length > 1) && (
									
									<div className={`material-search-bo mb-3 input-group`}>
										
										{/*<div className="input-group-prepend">
											<span className="input-group-text" id="addon-wrapping">&#128269;</span>
										</div>*/}
										
										<div className="input-group-prepend">
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={() => {
													this.setState((prevState) => {
														return {
															materialSearchName: '',
														}
													})
												}}
											>
												<Icon.X/>
											</button>
										</div>
										
										<input
											type={'text'}
											className={`form-control`}
											// placeholder="&#x1F50D;"
											placeholder={i18next.t("Search by name")}
											value={this.state.materialSearchName}
											onChange={(event) => {
												this.setState((prevState) => {
													return {
														materialSearchName: event.target.value
													}
												})
											}}
											onKeyDown={(event) => {
												
												switch (event.key) {
													
													case 'Enter':
														this.materials.setCurrentById(filteredMaterials[0]?.id)
														break
													
													case 'Escape':
														this.setState((prevState) => {
															return {
																materialSearchName: ''
															}
														})
														break
													
												}
												
											}}
										/>
										
										{/*<div className="input-group-append">
											<button className="btn btn-secondary" type="button">
												<Icon.X/>
											</button>
										</div>*/}
									
									</div>
								
								)}
								
								{(filteredMaterials.length > 0) ? (
									
									<div className={'body materials-list-body'}>
										
										{filteredMaterials.map((material, materialIndex) => {
											return (
												<MaterialPreview
													material={material}
													user={user}
													isCurrent={currentMaterial && material.id === currentMaterial.id}
													onClick={() => {
														this.materials.setCurrentById(material.id, 0)
													}}
													onMoveUp={this.materials.moveUp}
													onMoveDown={this.materials.moveDown}
													onPublic={(material) => {
														this.materials.upd(
															material,
															{is_public: 1},
														)
													}}
													onUnpublic={(material) => {
														this.materials.upd(
															material,
															{is_public: 0},
														)
													}}
													onArchive={(material) => {
														this.materials.upd(
															material,
															{is_del: 1},
															i18next.t("Are you shure?")
														)
													}}
													onUnarchive={(material) => {
														this.materials.upd(
															material,
															{is_del: 0},
														)
													}}
													onCopy={(material) => {
														// this.materials.copyByPromt(material)
														this.materials.copyByModal(material)
													}}
												/>
											)
										})}
										
									</div>
									
								) : (
									
									<div className={'text-center not-found-msg materials-not-found-msg'}>
										<hr/>
										{i18next.t("Materials not found")}
									</div>
									
								)}
								
							</div>
							
						</div>
						
					</section>
					
				</div>
				
				<div className="modals">
					
					<Modal
						className={[
							'material-form-modal',
							'wide-modal',
						].join(' ')}
						show={this.state.materialFormModalIsOpen}
						onHide={this.materials.formModalToggle}
						size={'lg'}
						keyboard={false}
						backdrop={'static'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.materialToEdit ? (
									i18next.t("Edit material #{{id}}", {
										id: this.state.materialToEdit.id,
									})
								) : (
									i18next.t("New material")
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							{materialForm}
						</Modal.Body>
					
					</Modal>
					
					{this.state.useQuestionEditModal && this.state.currentMaterial && (
						
						<Modal
							className={[
								'question-form-modal',
								// 'wide-modal',
							].join(' ')}
							show={this.state.questionFormModalIsOpen}
							onHide={this.questions.formModalToggle}
							size={'xl'}
							keyboard={false}
							backdrop={'static'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{questionToEdit && questionToEdit.id ? (
										<div>
											{i18next.t("Edit task / card / group #{{id}}", {id: questionToEdit.id})}
										</div>
									) : (
										<div>
											{i18next.t("Add task / card / group")}
										</div>
									)}
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								
								{(questionToEdit) ? (
									
									<>
										
										{(questionToEdit.isRegular()) &&
											<QuestionForm
												model={this.state.questionToEdit}
												materialId={this.state.currentMaterial?.id}
												variantId={this.state.currentVariant?.id}
												afterSubmit={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isGroup()) &&
											<QuestionGroupForm
												model={this.state.questionToEdit}
												groups={currentMaterial.getGroups()}
												questions={this.state.questionToEdit?.group_items}
												materialId={this.state.currentMaterial?.id}
												afterSuccess={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
											/>
										}
										
										{(questionToEdit.isCard()) &&
											<QuestionCardForm
												model={this.state.questionToEdit}
												materialId={this.state.currentMaterial?.id}
												afterSuccess={this.questions.afterSubmitSuccess}
												cancel={this.questions.formModalToggle}
												materials={this.state.materials}
												preloader={this.props.preloader}
												skins={this.props.skins}
												groups={currentMaterial.getGroups()}
											/>
										}
									
									</>
								
								) : (
									
									<>
										<QuestionForm
											model={this.state.questionToEdit}
											materialId={this.state.currentMaterial?.id}
											afterSubmit={this.questions.afterSubmitSuccess}
											cancel={this.questions.formModalToggle}
											materials={this.state.materials}
											preloader={this.props.preloader}
											skins={this.props.skins}
										/>
									</>
								
								)}
							
							</Modal.Body>
						
						</Modal>
						
					)}
					
					<MaterialCopyModal
						isOpen={this.state.materialCopyModalIsOpen}
						material={this.state.materialToCopy}
						course={this.props.course}
						themes={this.props.themes}
						onHide={() => {
							this.setState((prevState) => {
								return {
									materialCopyModalIsOpen: 0,
								}
							})
						}}
						onCopy={(material, targetTheme) => {
							this.materials.copy(material, targetTheme.id)
						}}
					/>
					
				</div>
				
			</div>
			
		);
		
	}
	
}

MaterialsPage.propTypes = {
	course: PropTypes.instanceOf(Course).isRequired,
	theme: PropTypes.instanceOf(Theme).isRequired,
	lesson: PropTypes.instanceOf(Lesson).isRequired,
	themes: PropTypes.arrayOf(PropTypes.instanceOf(Theme)),
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User),
	back: PropTypes.func,
	showPrevTheme: PropTypes.func,
	showNextTheme: PropTypes.func,
	prevTheme: PropTypes.instanceOf(Theme),
	prevThemeIndex: PropTypes.number,
	nextTheme: PropTypes.instanceOf(Theme),
	nextThemeIndex: PropTypes.number,
	currentThemeIndex: PropTypes.number,
	alert: PropTypes.object,
	skins: PropTypes.array,
};

MaterialsPage.defaultProps = {
	skins: [],
};

export default withRouter(MaterialsPage);