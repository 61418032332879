import './MaterialForm.css';

import axios from "axios";
import i18next from "i18next";
import PropTypes from 'prop-types';
import React from 'react';

import {Editor} from "@tinymce/tinymce-react";
import Config from "../../helpers/Config";
import DateHelper from "../../helpers/DateHelper";
import FormDataHelper from "../../helpers/FormDataHelper";
import Logger from "../../helpers/Logger";
import Range from "../../helpers/Range";
import Utils from "../../helpers/Utils";
import YouTubeHelper from "../../helpers/YouTubeHelper";
import Material from "../../models/Material";
import MaterialGroupBind from "../../models/MaterialGroupBind";
import Question from "../../models/Question";
import User from "../../models/User";
import AcceptedFilesList from "./AcceptedFilesList";
import FoldableList from "./FoldableList";
import MaterialGroupBindForm from "./MaterialGroupBindForm";
import UploadsGrid from "./UploadsGrid";

import Modal from "react-bootstrap/Modal";

import * as Icon from 'react-bootstrap-icons';
import CourseTree from './CourseTree';
import Course from '../../models/Course';

export default class MaterialForm extends React.Component {
	
	editors = [
		'tiny',
		'textarea',
	];
	
	mode = 'create';
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Material();
		
		this.mode = props.model && props.model.id ? 'update' : 'create';
		
		// create
		// if (!model.id) {
		//     model.is_del = 0;
		//     model.is_public = 0;
		//     model.is_exam = 0;
		//     model.is_weight_visible = 0;
		// }
		
		if (this.props.themeId) {
			model.theme_id = this.props.themeId;
		}
		
		// model.type_alias = Material.TYPE_THEORY_ALIAS;
		model.type_alias = this.props.typeAlias || Material.TYPE_ALIAS_THEORY;
		
		this.state = {
			
			model: model,
			
			typeSelector: 'radio',
			
			aboutEditor: 'tiny',
			
			accessToEditIndex: null,
			
			accessModalIsOpen: false,
			
		};
		
		if (!model.id && Config.allowFormFake()) {
			model.name = i18next.t("__TEST__") + ' ' + DateHelper.dateTime();
		}
		
		// << UPLOADS REFS
		
		this.uploadRefs = {};
		
		Material.uploadAttrs.forEach((attr) => {
			this.uploadRefs[attr] = React.createRef();
		});
		
		// >> UPLOADS REFS
		
		this.tinyEditorRef = React.createRef();
		
		// if (this.mode == 'create') {
		// 	model.is_trainer = 1;
		// }
		
		// model.is_trainer = 1;
		
	}
	
	inputChange = (event) => {
		
		const logName = 'MaterialForm.inputChange';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const target = event.target;
		Logger.log(target, 'target', logAllow);
		
		const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
		Logger.log(value, 'value', logAllow);
		
		const attr = target.getAttribute('name');
		Logger.log(attr, 'attr', logAllow);
		
		model[attr] = value;
		
		if (attr === 'restricted_access' && value == 1) {
			model.is_public = 1
			model.is_del = 0
		}
		
		this.setState({
			model: model,
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	videoLinks = {
		
		upd: (key, attr, val) => {
			
			let model = this.state.model
			
			let videoLinks = model.video_links
			
			if (Array.isArray(videoLinks)) {
				
				let videoLink = videoLinks[key]
				
				if (videoLink) {
					
					videoLink[attr] = val
					
					videoLinks[key] = videoLink
					
					model.video_links = videoLinks
					
					this.setState({
						model: model,
					})
					
				}
				
			}
			
		},
		
		add: () => {
			
			const logName = 'MaterialForm.videoLinks.add'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let model = this.state.model
			Logger.log(model, 'model', logAllow)
			
			let videoLinks = model.video_links
			Logger.log(videoLinks, 'videoLinks', logAllow)
			
			if (Array.isArray(videoLinks)) {
				
				let newVideoLink = {}
				
				videoLinks.push(newVideoLink)
				
				Logger.log(videoLinks, 'videoLinks', logAllow)
				
				model.video_links = videoLinks
				
				this.setState({
					model: model,
				})
				
			}
			
			Logger.groupEnd(logAllow)
			
		},
		
		del: (key) => {
			
			const logName = 'MaterialForm.videoLinks.del'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			let model = this.state.model
			Logger.log(model, 'model', logAllow)
			
			let videoLinks = model.video_links
			Logger.log(videoLinks, 'videoLinks', logAllow)
			
			if (Array.isArray(videoLinks)) {
				
				let videoLink = videoLinks[key]
				
				let url = videoLink.url
				
				let keyView = Number.isInteger(key) ? key + 1 : key
				
				let msg = `${i18next.t("Delete video link")} #${keyView}?${url ? `\n\n${url}` : ''}`
				
				if (url) {
					if (!window.confirm(msg)) {
						return
					}
				}
				
				videoLinks.splice(key, 1)
				
				Logger.log(videoLinks, 'videoLinks', logAllow)
				
				model.video_links = videoLinks
				
				this.setState({
					model: model,
				})
				
			}
			
			Logger.groupEnd(logAllow)
			
		},
		
		up: (videoLinkIndex) => {
			
			let model = this.state.model
			
			let videoLinks = model.video_links
			
			Utils.arrayMoveUp(videoLinks, videoLinkIndex)
			
			model.video_links = videoLinks
			
			this.setState({
				model: model,
			})
			
		},
		
		down: (videoLinkIndex) => {
			
			let model = this.state.model
			
			let videoLinks = model.video_links
			
			Utils.arrayMoveDown(videoLinks, videoLinkIndex)
			
			model.video_links = videoLinks
			
			this.setState({
				model: model,
			})
			
		},
		
	}
	
	submit = (event) => {
		
		const logName = 'MaterialForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state.model, 'this.state.model', logAllow);
		
		event.preventDefault();
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		const form = event.currentTarget;
		const model = this.state.model;
		
		if (form.checkValidity()) {
			
			// const formData = this.getFormData();
			const formData = FormDataHelper.get(
				model,
				[
					'copy_of_id',
				],
				this.uploadRefs,
				(formData) => {
					formData.append('about', this.tinyEditorRef.current.getContent());
					return formData;
				},
				Material.jsonAttrs,
			);
			
			axios({
				method: this.mode == 'update' ? 'put' : 'post',
				url: Utils.apiUrl('materials' + (model.id ? '/' + model.id : '')),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				headers: {
					// 'Content-Type': 'multipart/form-data',
				},
				data: formData,
			}).then((response) => {
				
				const logName = 'MaterialForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				Logger.log(response.data.custom_errors, 'response.data.custom_errors', logAllow);
				
				Utils.showModelCustomErrors(response.data.custom_errors);
				
				if (this.mode == 'create') {
					if (this.props.afterCreate) {
						this.props.afterCreate(response, model);
					}
				} else {
					if (this.props.afterUpdate) {
						this.props.afterUpdate(response, model);
					}
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	uploads = {
		
		del: (uploadBindId) => {
			
			const logName = 'QuestionForm.images.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm(i18next.t("Are you shure?"))) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('upload-binds/' + uploadBindId),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				data: {
					'active': 0,
				},
			}).then((response) => {
				
				const logName = 'QuestionForm.images.del';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let newUpload = response.data;
				let newModel = this.state.model;
				
				Question.uploadAttrs.forEach((attr) => {
					newModel[attr].forEach((image, index) => {
						if (image.id == uploadBindId) {
							newModel.images[index] = newUpload;
						}
					});
				});
				
				this.setState((prevState) => {
					return {
						model: newModel,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				// todo catch
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpd: (response, id, data) => {
			
			let newUpload = response.data;
			let newModel = this.state.model;
			
			Material.uploadAttrs.forEach((attr) => {
				newModel[attr].forEach((upload, index) => {
					if (upload.id == id) {
						newModel[attr][index] = newUpload;
					}
				});
			});
			
			this.setState((prevState) => {
				return {
					model: newModel,
				}
			});
			
		},
		
	};
	
	access = {
		
		add: () => {
			
			let material = this.state.model;
			
			if (material && material.id) {
				
				this.setState((prevState) => {
					return {
						accessToEditIndex: null,
						accessModalIsOpen: true,
					}
				});
				
			}
			
		},
		
		edit: (index) => {
			this.setState((prevState) => {
				return {
					accessToEditIndex: index,
					accessModalIsOpen: true,
				}
			});
		},
		
		del: (index) => {
			
			const logName = 'MaterialForm.access.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm(i18next.t("Shure?"))) {
				return;
			}
			
			let material = this.state.model;
			
			let access = material.groups_binds[index];
			
			if (access && access.id) {
				
				if (this.props.preloader) {
					this.props.preloader.show();
				}
				
				axios({
					method: 'put',
					url: Utils.apiUrl('material-group-bind') + '/' + access.id,
					data: {
						'is_active': 0,
					},
					params: {
						'accessToken': Utils.getUserToken(),
						// id: access.id,
					},
				}).then((response) => {
					
					const logName = 'MaterialForm.access.del.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					material.groups_binds.splice(index, 1);
					
					this.setState((prevState) => {
						return {
							model: material,
						}
					});
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					console.log('error.response = %o', error.response);
					
					if (error.response && error.response.data) {
						window.alert(error.response.data.message);
					} else {
						window.alert(error.message);
					}
					
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		modalToggle: () => {
			this.setState((prevState) => {
				return {
					accessModalIsOpen: !prevState.accessModalIsOpen,
				}
			});
		},
		
	};
	
	render() {
		
		const logName = 'MaterialForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		// Logger.log(this.props, 'this.props', logAllow);
		// Logger.log(this.state, 'this.state', logAllow);
		
		const user = this.props.user;
		
		const model = this.state.model;
		// Logger.log(model, 'model', logAllow)
		Logger.log(model.data, 'model.data', logAllow)
		
		// const youtubePreviewLink = model.getYouTubeVideoPreviewLink();
		
		const aboutEditorPicker = (
			<select
				className={'editor-picker about-editor-picker'}
				onChange={(event) => {
					this.setState((prevState) => {
						return {
							aboutEditor: event.target.value,
						}
					});
				}}
			>
				{this.editors.map(editor => {
					return (
						<option value={editor}>{editor}</option>
					);
				})}
			</select>
		);
		
		let accessToEdit = model.groups_binds[this.state.accessToEditIndex] || new MaterialGroupBind();
		
		accessToEdit.material_id = model.id;
		
		const course = this.props.course
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<>
				
				<form className={'MaterialForm'} onSubmit={this.submit}>
					
					{(user && user.can('manageMaterials')) &&
						
						<>
							
							<div className={'is_public__input-box'}>
								<label htmlFor={'is_public'}>
									<input
										id={'is_public'}
										name={'is_public'}
										type={'checkbox'}
										onChange={this.inputChange}
										checked={model.is_public == 1}
										disabled={model.restricted_access == 1}
									/> {i18next.t("Published")}
								</label>
							</div>
							
							<div className={'is_del__input-box'}>
								<label htmlFor={'is_del'}>
									<input
										id={'is_del'}
										name={'is_del'}
										type={'checkbox'}
										onChange={this.inputChange}
										checked={model.is_del == 1}
										disabled={model.restricted_access == 1}
									/> {i18next.t("Archive")}
								</label>
							</div>
							
							<div className={'is_weight_visible__input-box'}>
								<label htmlFor={'is_weight_visible'}>
									<input
										id={'is_weight_visible'}
										name={'is_weight_visible'}
										type={'checkbox'}
										onChange={this.inputChange}
										checked={model.is_weight_visible == 1}
									/> {i18next.t("Show points for students")}
								</label>
							</div>
							
							<div className={'is_exam__input-box'}>
								
								<label htmlFor={'is_exam'}>
									<input
										id={'is_exam'}
										name={'is_exam'}
										type={'checkbox'}
										value={model.is_exam}
										checked={model.is_exam == 1}
										onChange={this.inputChange}
									/> {i18next.t("Exam")}
								</label>
								
								{/* <small className="form-text text-muted">
									{i18next.t("In the control work mode, the student does not see the result of checking tasks and cannot give an answer more than once")}
								</small> */}
							
							</div>
							
							<div className={'restricted-access__input-box'}>
								
								<label htmlFor={'restricted_access'}>
									<input
										id={'restricted_access'}
										name={'restricted_access'}
										type={'checkbox'}
										value={model.restricted_access}
										checked={model.restricted_access == 1}
										onChange={this.inputChange}
									/> {i18next.t("Restricted access")}
								</label>
								
								{/* 
								<small className="form-text text-muted">
									{i18next.t("In the control work mode, the student does not see the result of checking tasks and cannot give an answer more than once")}
								</small>
								*/}
							
							</div>
							
							<div className={'is_dynamic__input-box'}>
								<label htmlFor={'is_dynamic'}>
									<input
										id={'is_dynamic'}
										name={'is_dynamic'}
										type={'checkbox'}
										checked={model.is_dynamic == 1}
										onChange={this.inputChange}
										disabled={!course}
										title={!course ? i18next.t("Disabled because of course not provided") : ''}
									/> {i18next.t("Individual test")} <span className={`badge badge-danger`}>{i18next.t("New")}!</span>
								</label>
							</div>
							
							{/* количество заданий на одной странице (чекбокс) */}
							{/*
							<div className={'quaetsions-pages-disabled__input-box'}>
								<label htmlFor={'quaetsions-pages-disabled'}>
									<input
										id={'quaetsions-pages-disabled'}
										name={'quaetsions-pages-disabled'}
										type={'checkbox'}
										onChange={(event) => {
											
											let checked = event?.target?.checked
											console.log(`checked [` + typeof checked + `] = %o`, checked)
											
											if (checked) {
												model.questions_page_size = 0
											} else {
												model.questions_page_size = -1
											}
											
											console.log(`model.questions_page_size [` + typeof model.questions_page_size + `] = %o`, model.questions_page_size)
											
											this.setState({
												model: model,
											})
											
										}}
										checked={model.questions_page_size == -1}
									/> {i18next.t("All questions on one page")}
								</label>
							</div>
							*/}
							
							<hr/>
							
							{(model.is_dynamic == 1 && course) && (
								
								<>
									
									<CourseTree
										
										preloader={this.props.preloader}
										
										course={course}
										
										folded={false}
										
										useMaterials={false}
										
										themesLoadParams={{
											addQuestionsCount: 1,
											withQuestionsOnly: 1,
										}}
										
										themesNotFoundMsgMaker={(lesson, course) => {
											return i18next.t("Themes with tasks not found")
										}}
										
										themeHeadMaker={(theme, lesson, course) => {
											
											const logName = 'CourseTree.themeHeadMaker'
											const logAllow = 1
											const logCollapse = 0
											
											Logger.groupStart(logName, logAllow, logCollapse)
											
											Logger.log(theme, 'theme', logAllow)
											Logger.log(theme.questions_count, 'theme.questions_count', logAllow)
											
											let themeQuestionsCount = theme.questions_count
											Logger.log(themeQuestionsCount, 'themeQuestionsCount', logAllow)
											
											let selectedQuestionsCount =
												model
													?.data
													?.dynamic
													?.questionsCountThemeMap[theme.id]
											
											let htmId = `theme-${theme.id}-questions-count`
											
											Logger.groupEnd(logAllow)
											
											return (
												
												<>
													
													{/* <div className={`theme-info`}>
														{i18next.t("Tasks count")}: <b>{theme.questions_count}</b>
													</div> */}
													
													<div className={`form-group`}>
														
														<label
															htmlFor={htmId}
															className={`form-label`}
														>{i18next.t("Included tasks count")} | {i18next.t("of")} <b>{themeQuestionsCount}</b></label>
														
														<div className={`input-group`}>
															
															<input
																id={htmId}
																name={htmId}
																placeholder={``}
																type={`number`}
																min={0}
																max={themeQuestionsCount}
																value={selectedQuestionsCount}
																onChange={(event) => {
																	
																	let questionsCount = event.target.value
																	
																	let material = this.state.model
																	
																	let data = material.data || {}
																	
																	let dynamicData = data.dynamic || {}
																	
																	let questionsCountThemeMap = dynamicData.questionsCountThemeMap || {}
																	
																	if (questionsCount > 0) {
																		
																		questionsCountThemeMap[theme.id] = questionsCount
																		
																	} else {
																		
																		delete questionsCountThemeMap[theme.id]
																		
																	}
																	
																	dynamicData.questionsCountThemeMap = questionsCountThemeMap
																	data.dynamic = dynamicData
																	model.data = data
																	
																	this.setState({
																		model: model,
																	})
																	
																	
																}}
																className={`form-control`}
																required={false}
															/>
															
															{/* 
															<div className={`input-group-append`}>
																<div className={`input-group-text`}>
																	{i18next.t("of")}&nbsp;<b>{themeQuestionsCount}</b>
																</div>
															</div>
															*/}
														
														</div>
													
													</div>
												
												</>
											
											)
											
										}}
										
										themeExtraClassNameMaker={(theme, lesson, course) => {
											
											const logName = 'CourseTree.themeExtraClassNameMaker'
											const logAllow = 1
											const logCollapse = 0
											
											Logger.groupStart(logName, logAllow, logCollapse)
											
											Logger.log(theme, 'theme', logAllow)
											
											let themeQuestionsCountMap = model.getDynamicThemeQuestionsCountMap()
											Logger.log(themeQuestionsCountMap, 'themeQuestionsCountMap', logAllow)
											
											let themesIds = themeQuestionsCountMap
												? Object.keys(themeQuestionsCountMap)
												: []
											Logger.log(themesIds, 'themesIds', logAllow)
											
											let isCurrent = themesIds.includes(theme.id)
											Logger.log(isCurrent, 'isCurrent', logAllow)
											
											Logger.groupEnd(logAllow)
											
											return isCurrent
												? ['selected']
												: []
											
										}}
									
									/>
									
									<hr/>
								
								</>
							
							)}
							
							{/* количество заданий на одной странице (произвольное число) */}
							{/*
							<div className={'form-group questions_page_size'}>
								<label htmlFor={'questions_page_size'}>{i18next.t("Questions page size")}</label>
								<input
									id={'questions_page_size'}
									name={'questions_page_size'}
									type={'number'}
									className={'form-control'}
									value={model.questions_page_size}
									onChange={this.inputChange}
									placeholder={''}
									required={false}
									min={1}
									max={10}
									step={1}
								/>
							</div>
							*/}
							
							{/* количество заданий на одной странице (список) */}
							{/*
							<div className={'form-group questions_page_size'}>
								<label htmlFor={'questions_page_size'}>{i18next.t("Questions page size")}</label>
								<select
									id={'questions_page_size'}
									name={'questions_page_size'}
									className={'form-control'}
									onChange={this.inputChange}
									value={model.questions_page_size}
								>
									{Range.get(1, Config.maxQuestionsPageSize).map((x) => {
										return (
											<option value={x}>{x}</option>
										);
									})}
								</select>
							</div>
							*/}
							
							<div className="form-group name required">
								<label htmlFor={'name'}>{i18next.t("Title")}</label>
								<input
									id={'name'}
									name={'name'}
									type="text"
									className={'form-control'}
									value={model.name || ''}
									onChange={this.inputChange}
									required={true}
								/>
							</div>
							
							<div className="form-group youtube_urls">
								
								<label htmlFor={'youtube_urls'}>{i18next.t("YouTube video URL")}</label>
								
								<input
									id={'youtube_urls'}
									name={'youtube_urls'}
									type="url"
									className={'form-control'}
									value={model.youtube_urls}
									onChange={this.inputChange}
								/>
								
								{model.youtube_urls && (
									<div className={[
										'youtube-widget-box',
										'embed-responsive',
										'embed-responsive-16by9',
										'w-50',
										'mt-2',
									].join(' ')}>
										{YouTubeHelper.widget(this.state.model.youtube_urls)}
									</div>
								)}
							
							</div>
							
							<FoldableList name={i18next.t("Video links")} folded={false}>
								
								<div className={`video-links`}>
									
									{(Array.isArray(model.video_links)) && (
										
										<div className={`items`}>
											
											{model.video_links.map((videoLinkData, videoLinkIndex) => {
												
												return (
													
													<div
														key={`video-link-${videoLinkIndex}`}
														className={`video-link item mb-3`}
													>
														
														<div className={`input-group`}>
															
															<div className={`input-group-prepend num-box item-num-box`}>
																<span className={`input-group-text num item-num`}>
																	{videoLinkIndex + 1}
																</span>
															</div>
															
															<input
																type={'url'}
																className={'form-control'}
																value={videoLinkData.url}
																onChange={(event) => {
																	this.videoLinks.upd(videoLinkIndex, 'url', event.target.value)
																}}
																placeholder={i18next.t("Video link (URL)")}
																// required={true}
															/>
															
															<div className={`input-group-append`}>
																
																<button
																	type={`button`}
																	className={`btn btn-light btn-outline-secondary del-btn`}
																	onClick={(event) => {
																		this.videoLinks.up(videoLinkIndex)
																	}}
																><Icon.ArrowUp/></button>
																
																<button
																	type={`button`}
																	className={`btn btn-light btn-outline-secondary del-btn`}
																	onClick={(event) => {
																		this.videoLinks.down(videoLinkIndex)
																	}}
																><Icon.ArrowDown/></button>
																
																<button
																	type={`button`}
																	className={`btn btn-danger del-btn`}
																	onClick={(event) => {
																		this.videoLinks.del(videoLinkIndex)
																	}}
																><Icon.X/></button>
															
															</div>
														
														</div>
													
													</div>
												
												);
												
											})}
										
										</div>
									
									)}
									
									<div className={`controls text-center`}>
										
										<button
											type={`button`}
											className={`my-btn my-btn-sm my-btn-primary add-btn add-video-link-btn`}
											onClick={(event) => {
												this.videoLinks.add()
											}}
										>+ {i18next.t('Add video link')}</button>
									
									</div>
								
								</div>
							
							</FoldableList>
							
							<hr/>
							
							<div className={'form-group about'}>
								
								<label htmlFor={'about'}>{i18next.t("Text")}</label>
								
								{this.props.user && this.props.user.role_alias === 'owner' &&
									aboutEditorPicker
								}
								
								{(this.state.aboutEditor === 'tiny') && (
									<Editor
										id={'material_' + Date.now()}
										onInit={(evt, editor) => {
											this.tinyEditorRef.current = editor;
										}}
										initialValue={model.about}
										init={Config.tinyMceMainConfig}
									/>
								)}
								
								{this.state.aboutEditor === 'textarea' &&
									<textarea
										id={'about'}
										name={'about'}
										className={'form-control'}
										value={model.about || ''}
										onChange={this.inputChange}
										rows={20}
									/>
								}
							
							</div>
							
							<FoldableList name={i18next.t("Images")}>
								
								<fieldset className={'images'}>
									
									{/*<legend>{i18next.t("Images")}</legend>*/}
									
									{model && model.images.length > 0 && (
										<UploadsGrid
											uploads={model.images}
											afterUpd={this.uploads.afterUpd}
											showControls={true}
											preloader={this.props.preloader}
										/>
									)}
									
									<div className="form-group">
										<input
											id={'images'}
											type={'file'}
											accept={'image/*'}
											ref={this.uploadRefs['images']}
											multiple={true}
										/>
									</div>
								
								</fieldset>
							
							</FoldableList>
							
							<FoldableList name={i18next.t("Hidden images")}>
								
								<fieldset className={'hidden_images'}>
									
									{/*<legend>{i18next.t("Hidden images")}</legend>*/}
									
									{model && Array.isArray(model.hidden_images) && model.hidden_images.length > 0 && (
										<UploadsGrid
											uploads={model.hidden_images}
											afterUpd={this.uploads.afterUpd}
											showControls={true}
											preloader={this.props.preloader}
										/>
									)}
									
									<div className="form-group">
										<input
											id={'hidden_images'}
											name={'hidden_images'}
											type={'file'}
											accept={'image/*'}
											ref={this.uploadRefs['hidden_images']}
											multiple={true}
										/>
									</div>
								
								</fieldset>
							
							</FoldableList>
							
							<FoldableList name={i18next.t("Audio")}>
								
								<fieldset className={'sounds'}>
									
									{/*<legend>{i18next.t("Audio")}</legend>*/}
									
									{model && model.sounds.length > 0 && (
										<UploadsGrid
											uploads={model.sounds}
											afterUpd={this.uploads.afterUpd}
											showControls={true}
											preloader={this.props.preloader}
										/>
									)}
									
									<div className="form-group">
										<input
											id={'sounds'}
											type={'file'}
											accept={'audio/*'}
											ref={this.uploadRefs['sounds']}
											multiple={true}
										/>
									</div>
								
								</fieldset>
							
							</FoldableList>
							
							<FoldableList name={i18next.t("Video")}>
								
								<fieldset className={'videos'}>
									
									{model && model.videos.length > 0 && (
										<UploadsGrid
											uploads={model.videos}
											afterUpd={this.uploads.afterUpd}
											showControls={true}
											preloader={this.props.preloader}
										/>
									)}
									
									<div className="form-group">
										<input
											id={'videos'}
											type={'file'}
											accept={'video/mp4, view/webm'}
											ref={this.uploadRefs['videos']}
											multiple={true}
										/>
									</div>
								
								</fieldset>
							
							</FoldableList>
							
							<FoldableList name={i18next.t("Files")}>
								
								<fieldset className={'files'}>
									
									{/*<legend>{i18next.t("Documents")}</legend>*/}
									
									{model && model.files.length > 0 && (
										<UploadsGrid
											uploads={model.files}
											afterUpd={this.uploads.afterUpd}
											preloader={this.props.preloader}
											showControls={true}
										/>
									)}
									
									<div className="form-group">
										<input
											id={'files'}
											type={'file'}
											ref={this.uploadRefs['files']}
											multiple={true}
											accept={Config.getAcceptedFilesMimes()}
										/>
									</div>
									
									<AcceptedFilesList/>
								
								</fieldset>
							
							</FoldableList>
						
						</>
						
					}
					
					{(
						model.id
						&& model.restricted_access == 1
						&& user.can('manageMaterialsAccess')
					) && (
						
						<FoldableList
							className={'access-list groups-binds-list'}
							name={i18next.t("Access")}
							folded={false}
						>
							
							{model.groups_binds.map((access, accessIndex) => {
								
								// let folded = accessIndex != this.state.accessToEditIndex;
								
								let accessNum = accessIndex + 1;
								
								let name = access.group_id
									? i18next.t("Group") + ' ' + (
									access.group_name
										? access.group_name
										: '#' + access.group_id
								)
									: i18next.t("Access") + ' #' + accessNum;
								
								let key = 'access-' + accessIndex;
								
								let startDateFormatted = DateHelper.formatDate({date: access.access_start_date, format: 'd.m.y'})
								let endDateFormatted = DateHelper.formatDate({date: access.access_end_date, format: 'd.m.y'})
								
								return (
									
									<div
										key={key}
										className="list-item list-item-active"
										onClick={(event) => {
											this.access.edit(accessIndex);
										}}
										data-access-id={access.id}
										data-group-id={access.group_id}
									>
										
										<div className="row">
											
											<div className="col">
												
												<div className={`access-name-box`}>
													<span className={`access-name`} title={`#${access.group_id}`}>{name}</span>
												</div>
												
												<small className="access-summary text-muted">
													{startDateFormatted} {access.access_start_time}
													{(access.access_end_date) ? (
														<> – {endDateFormatted} {access.access_end_time}</>
													) : (
														<> – {i18next.t("Not restricted")}</>
													)}
													{/* <br/>#{access.id} */}
												</small>
											
											</div>
											
											<div className="col-auto text-right">
												
												{/*<button
													type={'button'}
													className={'my-btn my-btn-sm my-btn-primary'}
													onClick={(event) => {
														event.stopPropagation();
														this.access.edit(accessIndex);
													}}
												>edit</button>*/}
												
												<button
													type={'button'}
													className={'my-btn my-btn-sm my-btn-danger'}
													onClick={(event) => {
														event.stopPropagation();
														this.access.del(accessIndex);
													}}
												><Icon.X/></button>
											
											</div>
										
										</div>
									
									</div>
								);
								
							})}
							
							<div className={'controls access-contols text-center mt-3'}>
								<button
									type={'button'}
									className={[
										'my-btn',
										'my-btn-primary',
										'my-btn-sm',
									].join(' ')}
									onClick={(event) => {
										this.access.add();
									}}
								>+ {i18next.t("Add access")}</button>
							</div>
						
						</FoldableList>
					
					)}
					
					{(user.can('manageMaterials')) ? (
						
						<div className="controls">
							
							<hr/>
							
							<div className="row">
								
								<div className="col">
									{this.props.cancel && (
										<button
											type={'button'}
											className={'my-btn my-btn-danger'}
											onClick={(event) => {
												this.props.cancel(event);
											}}
										>{i18next.t("Cancel")}</button>
									)}
								</div>
								
								<div className="col text-right">
									<button
										type={'submit'}
										className={'my-btn my-btn-primary'}
										onClick={(event) => {
											// todo
										}}
									>{i18next.t("Save")}</button>
								</div>
							
							</div>
						
						</div>
					
					) : (
						
						<></>
					
					)}
				
				</form>
				
				<div className="modals">
					
					<Modal
						className={'access-modal wide-modal'}
						show={this.state.accessModalIsOpen}
						onHide={this.access.modalToggle}
						// dialogClassName={`modal-90w`}
						backdrop={'static'}
						keyboard={false}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{accessToEdit.id ? (
									i18next.t("Edit access") + ' #' + accessToEdit.id
								) : (
									i18next.t("New access")
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<MaterialGroupBindForm
								model={accessToEdit}
								preloader={this.props.preloader}
								afterSuccess={(response) => {
									
									const logName = 'MaterialGroupBindForm.afterAccess';
									const logAllow = 1;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									Logger.log(response, 'response', logAllow);
									
									if (Array.isArray(response.data)) {
										
										response.data.forEach((materialGroupBindData) => {
											let materialGroupBindModel = new MaterialGroupBind(materialGroupBindData)
											model.groups_binds.push(materialGroupBindModel)
										})
										
									} else {
										
										let access = new MaterialGroupBind(response.data);
										Logger.log(access, 'access', logAllow);
										
										if (accessToEdit.id) {
											model.groups_binds[this.state.accessToEditIndex] = access;
										} else if (access.id) {
											model.groups_binds.push(access);
										}
										
									}
									
									this.access.modalToggle();
									
									Logger.groupEnd(logAllow);
									
								}}
								cancel={() => {
									this.setState((prevState) => {
										return {
											accessToEditIndex: null,
											accessModalIsOpen: false,
										}
									});
								}}
							/>
						</Modal.Body>
					
					</Modal>
				
				</div>
			
			</>
		
		);
		
	}
	
}

MaterialForm.propTypes = {
	model: PropTypes.instanceOf(Material),
	typeAlias: PropTypes.string,
	themeId: PropTypes.any,
	afterCreate: PropTypes.func,
	afterUpdate: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
	user: PropTypes.instanceOf(User),
	course: PropTypes.instanceOf(Course),
};

MaterialForm.defaultProps = {
	model: null,
};