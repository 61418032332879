import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import FormDataHelper from "../../helpers/FormDataHelper";
import MaterialGroupBind from "../../models/MaterialGroupBind";
import DateHelper from "../../helpers/DateHelper";
import ModelsPage from "./ModelsPage";
import User from "../../models/User";

class MaterialGroupBindForm extends React.Component {
	
	modeCreate = 1;
	modeUpdate = 0;
	
	constructor(props) {
		
		const logName = 'MaterialGroupBindForm.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(props, 'props', logAllow);
		
		super(props);
		
		let model = props.model ? {...props.model} : new MaterialGroupBind();
		
		if (model.id) {
			this.modeCreate = 0;
			this.modeUpdate = 1;
		}
		
		this.state = {
			model: model,
			showSelectedGroupsOnly: !Boolean(this.modeCreate),
			selectUsersInsteadOfGroups: 0,
			showSelectedUsersOnly: 0,
		};

		// model.access_start_date = DateHelper.formatDate({});
		
		if (Utils.isDev() && !model.id) {
			model.access_start_date = DateHelper.formatDate({});
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const attr = target.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		event.preventDefault();
		event.stopPropagation();
		
		const logName = 'MaterialGroupBindForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(event.keyCode, 'event.keyCode', logAllow);
		Logger.log(Utils.keyCodes.enter, 'Utils.keyCodes.enter', logAllow);
		
		if (event.keyCode == Utils.keyCodes.enter) {
			return false;
		}
		
		const form = event.currentTarget;
		const model = this.state.model;
		
		Logger.log(model, 'model', logAllow)
		
		if (!model.group_id && model.groups_ids.length < 1 && model.users_ids.length < 1) {
			window.alert(i18next.t("Groups or users not selected"));
			return;
		}
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const formData = FormDataHelper.get(model);
			
			let url = Utils.apiUrl('material-group-bind') + (model.id ? '/' + model.id : '');
			Logger.log(url, 'url', logAllow);
			
			Logger.log(FormDataHelper.getValues(formData), 'FormDataHelper.getValues(formData)', logAllow)
			
			if (model.groups_ids?.length > 1) {
				url = Utils.apiUrl('material-group-bind/create-many')
			}
			
			if (this.state.selectUsersInsteadOfGroups) {
				url = Utils.apiUrl('material-group-bind/create-for-users')
			}
			
			Logger.log(url, 'url', logAllow)
			
			axios({
				method: this.modeUpdate ? 'put' : 'post',
				url: url,
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialGroupBindForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				let isSuccess = response.data.id || Array.isArray(response.data)
				
				if (isSuccess) {
					
					if (this.props.afterSuccess) {
						this.props.afterSuccess(response, model);
					}
					
				} else {
					
					window.alert(i18next.t(`Sorry, access not created`))
					
					return
					
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				const logName = 'MaterialGroupBindForm.submit.ajax.error';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(error, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (this.props.afterError) {
					this.props.afterError(error);
				}
				
				if (Utils.isDev(Config)) {
					Utils.axiosErrorAlert(error);
				}
				
				Logger.groupEnd(logAllow);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		const logName = 'MaterialGroupBindForm.render'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		// MaterialGroupBind
		const model = this.state.model;
		Logger.log(model, 'model', logAllow)
		
		// <<< groups
		
		let allowGroupsChange = 1
		
		let showSelectedGroupsOnly = Boolean(this.state.showSelectedGroupsOnly) || !allowGroupsChange
		
		let allowSelectManyGroups = Boolean(this.modeCreate)
		
		let groupsLoadParams = {
			'sort': 'name',
		};
		
		if (showSelectedGroupsOnly) {
			if (model.groups_ids?.length > 0 || model.group_id) {
				groupsLoadParams['filter[id][in]'] = (model.groups_ids || []).concat([model.group_id]);
			} else {
				groupsLoadParams['filter[id]'] = -1;
			}
		}
		
		// >>> groups
		
		// <<< users
		
		let allowSelectUsersInsteadOfGroups = Boolean(this.modeCreate)
		// let allowSelectUsersInsteadOfGroups = 1
		
		let usersLoadParams = {
			// 'sort': 'name',
			'filter[role_id]': User.studentRoleId,
			// 'filter[surname][neq]': 'Demo User',
			'filter[surname][nin]': ['Demo User', 'Импорт'],
		};
		
		if (this.state.showSelectedUsersOnly) {
			if (model.users_ids?.length > 0 || model.user_id) {
				usersLoadParams['filter[id][in]'] = (model.users_ids || []).concat([model.user_id]);
			} else {
				usersLoadParams['filter[id]'] = -1;
			}
		}
		
		// >>> users
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<form className={'MaterialGroupBindForm'} onSubmit={this.submit}>
				
				<div className={'form-group material_id required d-none'}>
					<label htmlFor={'material_id'}>{i18next.t("Material ID")}</label>
					<input
						id={'material_id'}
						name={'material_id'}
						type={'number'}
						className={'form-control'}
						value={model.material_id}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>
				
				{/*<div className={'form-group group_id required'}>
					<label htmlFor={'group_id'}>{i18next.t("Group ID")}</label>
					<input
						id={'group_id'}
						name={'group_id'}
						type={'number'}
						className={'form-control'}
						value={model.group_id}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>*/}
				
				{(Boolean(allowSelectUsersInsteadOfGroups)) && (
					
					<>
						<div className={`mb-3`}>
							
							<label htmlFor={'selected-users-instead-of-groups'}>
								<input
									id={'selected-users-instead-of-groups'}
									type={'checkbox'}
									checked={Boolean(this.state.selectUsersInsteadOfGroups)}
									onChange={(event) => {
										
										let checked = event.target.checked;
										
										this.setState((prevState) => {
											return {
												selectUsersInsteadOfGroups: checked,
											}
										});
										
									}}
								/> {i18next.t("Select users instead of groups")}
							</label>
							
							<div className={`form-text text-small text-muted`}>
								{i18next.t(`New group, containing selected users, will be created`)}
							</div>
							
						</div>
					</>
					
				)}
				
				{(allowSelectUsersInsteadOfGroups && this.state.selectUsersInsteadOfGroups) ? (
					
					<>
						<fieldset className={'user-selector'}>
						
							<legend>{i18next.t(`Users`)}</legend>
							
							<label htmlFor={'selected-users-only'}>
								<input
									id={'selected-users-only'}
									type={'checkbox'}
									checked={Boolean(this.state.showSelectedUsersOnly)}
									onChange={(event) => {
										
										let checked = event.target.checked;
										
										this.setState((prevState) => {
											return {
												showSelectedUsersOnly: checked,
											}
										});
										
									}}
								/> {i18next.t("Selected only")}
							</label>
							
							<ModelsPage
								model={User}
								loadUrl={Utils.apiUrl('users')}
								loadParams={usersLoadParams}
								pagerControlsConfig={'start'}
								pageSize={7}
								preloader={this.props.preloader}
								cssClass={'users-models-page'}
								gridViewCols={{
									id: {
										name: 'ID',
										filter: 'number',
									},
									surname: {
										name: i18next.t("Last name"),
										filter: 'text',
									},
									name: {
										name: i18next.t("First name"),
										filter: 'text',
									},
									patron: {
										name: i18next.t("Middle name"),
										filter: 'text',
									},
									role_id: {
										name: i18next.t("Role"),
										value: (model) => {
											return model.role_name;
										}
									},
									email: {
										name: 'E-mail',
										value: (model) => {
											return (
												<a href={'mailto:' + model.email}
												   onClick={(event) => {
													   event.stopPropagation();
												   }}
												   title={i18next.t("Отправить E-mail")}
												>{model.email}</a>
											);
										},
										filter: 'text',
										filterLike: true,
									},
								}}
								afterLoad={(response, models, page) => {
									this.setState((prevState) => {
										return {
											// users: models,
											// usersPage: page,
											// usersReload: false,
										}
									});
								}}
								rowOnClick={(user, event) => {
									
									let userIndex = model.users_ids.indexOf(user.id);
									
									if (userIndex >= 0) {
										// model.users.splice(userIndex, 1);
										model.users_ids.splice(userIndex, 1);
									} else {
										// model.users.push(user);
										model.users_ids.push(user.id);
									}
									
									this.setState((prevState) => {
										return {
											model: model,
										}
									});
									
								}}
								rowClassNameMaker={(user) => {
									return model.users_ids.includes(user.id)
										? 'table-success'
										: '';
								}}
								rowTitleMaker={(user) => {
									return i18next.t("Edit");
								}}
								// page={this.state.usersPage}
								// reload={this.state.loadTeachers}
							/>
							
						</fieldset>
					
					</>
					
				) : (
					
					<>
						
						<fieldset className="group-selector">
							
							<legend>{i18next.t(`Groups`)}</legend>
							
							<label htmlFor={'selected-groups-only'}>
								<input
									id={'selected-groups-only'}
									type={'checkbox'}
									checked={showSelectedGroupsOnly}
									onChange={(event) => {
										
										let checked = event.target.checked;
										
										this.setState((prevState) => {
											return {
												showSelectedGroupsOnly: checked,
											}
										});
										
									}}
									disabled={!allowGroupsChange}
								/> {i18next.t("Selected only")}
							</label>
							
							<ModelsPage
								loadUrl={Utils.apiUrl('groups')}
								loadParams={groupsLoadParams}
								gridViewCols={{
									// id: {
									// 	filter: 'number',
									// 	width: '200px',
									// },
									name: {
										name: i18next.t("Group name"),
										filter: this.modeCreate ? 'text' : null,
										filterLike: true,
										value: (group) => {
											return (
												<>
											
											<span className={`group-name`}>
												{group.name}
											</span>
													
													<small
														className={`group-id text-muted d-inline-block ml-2`}
														title={i18next.t("Group ID")}
													>#{group.id}</small>
												
												</>
											)
										}
									},
								}}
								preloader={this.props.preloader}
								// instantFilter={true}
								rowOnClick={(group, event) => {
									
									if (!allowGroupsChange) {
										return
									}
									
									let model = this.state.model
									
									let groupsIds = model.groups_ids
									
									let groupId = group.id
									
									let isSelected = model.group_id == groupId || groupsIds.includes(groupId)
									
									if (isSelected) {
										
										// deselect group
										
										model.group_id = null
										
										groupsIds.splice(groupsIds.indexOf(groupId), 1)
										
									} else {
										
										// select group
										
										model.group_id = group.id
										
										if (allowSelectManyGroups) {
											groupsIds.push(group.id)
										}
										
									}
									
									model.groups_ids = groupsIds
									
									this.setState((prevState) => {
										return {
											model: model,
											// groups_ids: groupsIds,
										}
									});
									
								}}
								rowClassNameMaker={(group) => {
									
									// return group.id == this.state.model.group_id ? 'table-success' : null;
									
									const model = this.state.model
									
									let isSelectedOne = model.group_id == group.id
									let isSelectedMany = model.groups_ids?.includes(group.id)
									
									// let isSelected = this.modeCreate && this.allowMultipleGroups
									// 	? isSelectedOne || isSelectedMany
									// 	: isSelectedOne
									
									let isSelected = isSelectedOne || isSelectedMany
									
									return isSelected ? 'table-success' : null
									
								}}
							/>
						
						</fieldset>
						
					</>
				
				)}
				
				<hr/>
				
				<div className={'form-group access_start_date required'}>
					<label htmlFor={'access_start_date'}>{i18next.t("Start date")}</label>
					<input
						id={'access_start_date'}
						name={'access_start_date'}
						type={'date'}
						className={'form-control'}
						value={model.access_start_date}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>
				
				<div className={'form-group access_start_time'}>
					<label htmlFor={'access_start_time'}>{i18next.t("Start time")}</label>
					<input
						id={'access_start_time'}
						name={'access_start_time'}
						type={'time'}
						className={'form-control'}
						value={model.access_start_time}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group access_end_date'}>
					<label htmlFor={'access_end_date'}>{i18next.t("End date")}</label>
					<input
						id={'access_end_date'}
						name={'access_end_date'}
						type={'date'}
						className={'form-control'}
						value={model.access_end_date}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group access_end_time'}>
					<label htmlFor={'access_end_time'}>{i18next.t("End time")}</label>
					<input
						id={'access_end_time'}
						name={'access_end_time'}
						type={'time'}
						className={'form-control'}
						value={model.access_end_time}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className="controls">
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button
									type={'button'}
									className={'my-btn my-btn-danger'}
									onClick={(event) => {
										this.props.cancel();
									}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button
								type={'submit'}
								// type={'button'}
								className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

MaterialGroupBindForm.propTypes = {
	model: PropTypes.object,
	afterSuccess: PropTypes.func,
	afterError: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
};

MaterialGroupBindForm.defaultProps = {
	model: null,
};

export default MaterialGroupBindForm;