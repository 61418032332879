import "./QuestionView.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import axios from "axios";
// import draftToHtml from 'draftjs-to-html';
import * as Icon from 'react-bootstrap-icons';

import Logger from "../../helpers/Logger";
import Utils from "../../helpers/Utils";
import Question from "../../models/Question";
import User from "../../models/User";
import Answer from "../../models/Answer";
import Config from "../../helpers/Config";
import {Editor} from "@tinymce/tinymce-react";
import Material from "../../models/Material";
import DateHelper from "../../helpers/DateHelper";
import Formula from "./Formula";
import FormDataHelper from "../../helpers/FormDataHelper";
import UploadsList from "./UploadsList";
import Rand from "../../helpers/Rand";
import PdfView from "./PdfView";
import Course from "../../models/Course";
import Modal from "react-bootstrap/Modal";
import Discuss from "./Discuss";
import FoldableList from "./FoldableList";


export default class QuestionView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		const logName = 'QuestionView.constructor';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let question = props.question;
		Logger.log(question.id, 'question.id', logAllow);
		Logger.log(question, 'question', logAllow);
		
		Logger.log(props, 'props', logAllow);
		
		// let accordancesArray = [];
		let shuffleAccordances = question.view_type_alias === 'completion' ? 0 : 1;
		Logger.log(shuffleAccordances, 'shuffleAccordances', logAllow);
		
		let accordancesArray = question.getAccordancesArray(shuffleAccordances);
		Logger.log(accordancesArray, 'accordancesArray', logAllow);
		
		let completionRightAnswers = Question.getCompletionAnswers(question.about);
		Logger.log(completionRightAnswers, 'completionRightAnswers (before shuffle)', logAllow);
		
		completionRightAnswers = Utils.arrayShuffle(completionRightAnswers);
		Logger.log(completionRightAnswers, 'completionRightAnswers (after shuffle)', logAllow);
		
		// если есть ответ, вставляем значения из него
		
		// let latestAnswer = props.answers[0];
		// let latestAnswer = props.material.answers[0];
		let latestAnswer = this.props.answer || question.latest_answer;
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		let completions = [];
		
		let answerText = '';
		
		let pollSelected = [];
		
		if (latestAnswer) {
			
			let latestAnswerData = latestAnswer.answer_data;
			Logger.log(latestAnswerData, 'latestAnswerData', logAllow);
			
			if (latestAnswerData) {
				
				if (latestAnswerData.accordances) {
					accordancesArray = latestAnswerData.accordances;
				}
				completions = latestAnswerData.completions;
				answerText = latestAnswerData.text;
				pollSelected = latestAnswerData.pollSelected;
				
				if (question.view_type_alias === 'completion_cloud' && !latestAnswer.is_right) {
					completions = [];
				}
				
			}
			
		}
		
		// todo refactor сделать названия полей и переменных более понятными
		
		this.state = {
			latestAnswer: latestAnswer,
			accordances: accordancesArray, //
			unusedClouds: completionRightAnswers,
			selectedVariantAlias: null, // ответ, выбранный в completion_select (выбор из вариантов)
			completions: completions || [], // ответы, введённые в поля внутри about (completion_write и тп)
			answerText: answerText, // ответ в свободной форме
			pollSelected: pollSelected,
			imgViewerIsOpen: false,
			variantsArray: question.getVariantsArray(question.use_shuffle == 1 && props.mode != 'check'),
			comment: latestAnswer && latestAnswer.comment != 'null' ? latestAnswer.comment : '',
			alertModalIsOpen: false,
			studentFiles: [],
			grade: null,
			saveAsTemplate: false,
			discussIsShown: props.showDiscuss,
		};
		
		this.answerStudentFilesInputRef = React.createRef();
		this.answerTeacherFilesInputRef = React.createRef();
		this.answerRichTextEditorRef = React.createRef();
		
		Logger.groupEnd(logAllow);
		
	}
	
	accordances = {
		
		up: (currentIndex) => {
			
			const logName = 'QuestionView.accordances.up';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let accordances = this.state.accordances;
			Logger.log(accordances, 'accordances (before move up)', logAllow);
			
			Utils.arrayMoveUp(accordances, currentIndex);
			Logger.log(accordances, 'accordances (after move up)', logAllow);
			
			this.setState((prevState) => {
				return {
					accordances: accordances,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		down: (currentIndex) => {
			
			const logName = 'QuestionView.down';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let accordances = this.state.accordances;
			Logger.log(accordances, 'accordances (before move up)', logAllow);
			
			Utils.arrayMoveDown(accordances, currentIndex);
			Logger.log(accordances, 'accordances (after move up)', logAllow);
			
			this.setState((prevState) => {
				return {
					accordances: accordances,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	clouds = {
		
		add: () => {
			// todo
		},
		
		del: () => {
			// todo
		},
		
	};
	
	clear = (event) => {
		
		let question = this.props.question;
		
		let completionAnswers = Question.getCompletionAnswers(question.about);
		// Logger.log(completionAnswers, 'completionAnswers', logAllow);
		
		completionAnswers = Utils.arrayShuffle(completionAnswers);
		
		let accordancesArray = question.getAccordancesArray(question.view_type_alias === 'completion' ? 0 : 1);
		
		let variantsArray = question.getVariantsArray(question.use_shuffle);
		
		this.setState((prevState) => {
			return {
				usedClouds: [],
				completions: [],
				unusedClouds: completionAnswers,
				accordances: accordancesArray,
				variantsArray: variantsArray,
			}
		});
		
	};
	
	getErrors = () => {
		
		const logName = 'QuestionView.getErrors';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		let errs = [];
		
		const question = this.props.question;
		
		const completionRightAnswers = Question.getCompletionAnswers(question.about);
		Logger.log(completionRightAnswers, 'completionRightAnswers', logAllow);
		Logger.log(this.state.completions, 'this.state.completions', logAllow);
		
		if (this.state.completions.length < completionRightAnswers.length) {
			errs.push(i18next.t("Not all fields are filled"));
		}
		
		// НЕ файлы
		
		Logger.log(question.view_type_alias, 'question.view_type_alias', logAllow)
		
		if (question.answers_type_alias !== 'files') {
		
			if (question.view_type_alias === 'completion_cloud') {
				if (this.state.unusedClouds.length > 0) {
					errs.push(i18next.t("Not all clouds are used"));
				}
			} else if (question.view_type_alias === 'poll') {
				if (this.state.pollSelected.length === 0) {
					errs.push(i18next.t("Not answer options selected"));
				}
			} else if (question.view_type_alias === 'completion') {
				let accordances = this.state.accordances;
				let accordancesWithAnswers = this.state.accordances.filter(accordance => accordance.answer);
				if (accordancesWithAnswers.length < accordances.length) {
					errs.push(i18next.t("Not all fields are filled"));
				}
			} else if (question.view_type_alias === 'string') {
				if (!this.state.answerText) {
					errs.push(i18next.t("Answer is empty"));
				}
			}
			
		}
		
		// файлы
		
		let studentFilesInput = this.answerStudentFilesInputRef.current;
		
		if (studentFilesInput && (['files', 'handwrite_ocr'].includes(question.view_type_alias))) {
			
			let files = studentFilesInput.files;
			
			if (files.length > 0) {
				if (question.files_as_answers_count && files.count > question.files_as_answers_count) {
					errs.push([i18next.t("Too many files selected. Maximum files count: {{count}}",
						{count: question.files_as_answers_count})]);
				}
			} else {
				errs.push([i18next.t("No files selected")])
			}
			
		}
		
		Logger.log(errs, 'errs', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return errs;
		
	};
	
	validate = () => {
		return this.getErrors().length === 0;
	};
	
	// отправить данные ответа на сервер
	sendAnswer = (data) => {
		
		const logName = 'QuestionView.sendAnswer';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(FormDataHelper.getValues(data), 'FormDataHelper.getValues(data)', logAllow);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		const questionData = {...this.props.question}
		
		// чтобы не было рекурсии
		questionData['latest_answer'] = null;
		
		axios({
			method: 'post',
			url: Utils.apiUrl('test-answer-student'),
			// data: {
			//     material_id: this.props.material_id,
			//     question_id: questionData.id,
			//     question_data: questionData,
			//     answer_data: {
			//         accordances: this.state.accordances,
			//         completions: this.state.completions,
			//         text: this.state.answerText,
			//         pollSelected: this.state.pollSelected,
			//         // clouds: this.state.usedClouds,
			//     },
			// },
			data: data,
			params: {
				'accessToken': Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'QuestionView.sendAnswer.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let answer = new Answer(response.data);
			
			if (this.props.afterSendAnswer) {
				this.props.afterSendAnswer(response, answer);
			}
			
			// "перезапускаем" облака, если ответ неверный
			if (!answer.is_right && questionData.view_type_alias === 'completion_cloud') {
				this.setState((prevState) => {
					return {
						completions: [],
						unusedClouds: Utils.arrayShuffle(Question.getCompletionAnswers(questionData.about)),
					}
				});
			}
			
			this.props.afterSendAnswerSuccess(answer)
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	// собарть данные ответа и отправить на сервер (sendAnswer)
	saveAnswer = (event) => {
		
		const logName = 'QuestionView.saveAnswer';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		// const question = this.props.question;
		
		let errs = this.getErrors();
		Logger.log(errs, 'errs', logAllow)
		
		if (errs.length > 0) {
			// errs.unshift(i18next.t("Пожалуйста, устраните следующие ошибки:\n\n"));
			// alert(errs.join("\n"));
			// let errsHtml = errs.map();
			if (this.props.alert) {
				this.props.alert.show(i18next.t("Not all fields are filled correctly") + ": " + errs.join("; "), null, false);
			}
			return;
		}
		
		const questionData = {...this.props.question};
		
		// чтобы не было рекурсии
		questionData['latest_answer'] = null;
		
		let answerData = {
			accordances: this.state.accordances,
			completions: this.state.completions,
			text: this.state.answerText,
			pollSelected: this.state.pollSelected,
			// clouds: this.state.usedClouds,
		};
		Logger.log(answerData, 'answerData', logAllow);
		
		let formData = new FormData();
		
		if (this.props.material_id) {
			formData.append('material_id', this.props.material_id.toString());
		}
		
		formData.append('question_id', questionData.id);
		formData.append('question_data', JSON.stringify(questionData));
		formData.append('answer_data', JSON.stringify(answerData));
		
		let studentFilesInput = this.answerStudentFilesInputRef.current;
		if (studentFilesInput) {
			let studentFiles = studentFilesInput.files;
			for (let fileIndex = 0; fileIndex < studentFiles.length; fileIndex++) {
				let file = studentFiles[fileIndex];
				formData.append('student_files[]', file);
			}
		}
		
		let teacherFilesInput = this.answerTeacherFilesInputRef.current;
		if (teacherFilesInput) {
			let teacherFiles = teacherFilesInput.files;
			for (let fileIndex = 0; fileIndex < teacherFiles.length; fileIndex++) {
				let file = teacherFiles[fileIndex];
				formData.append('teacher_files[]', file);
			}
		}
		
		// if (this.props.preloader) {
		//     this.props.preloader.show();
		// }
		
		// если можно ответить несколько раз (не является контрольной работой)
		if (this.props.reanswers) {
			
			this.sendAnswer(formData);
			
		} else {
			
			// if (!window.confirm(i18next.t("Сохранить ответ? Изменить его позже будет нельзя."))) {
			//     return;
			// }
			
			// this.props.alert.show(
			// 	i18next.t("Are you shure to submit this answer?"),
			// 	i18next.t("You won't be able to change your answer later"),
			// 	true,
			// 	(event) => {
			// 		this.sendAnswer(formData);
			// 	}
			// );
			
			this.sendAnswer(formData);
			
		}
		
		// this.sendAnswer(formData);
		
		Logger.groupEnd(logAllow);
		
	};
	
	// cохранить результат проверки преподавателем
	check = (answerId, isRight) => {
		
		const logName = 'QuestionView.check';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (!answerId) {
			return
		}
		
		if (!this.state.grade) {
			window.alert(i18next.t("Grade not selected"));
			return;
		}
		
		if (this.state.grade > 75) {
			isRight = 1;
		}
		
		// if (!window.confirm(i18next.t("Are you shure?"))) {
		// 	return;
		// }
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		let formData = new FormData();
		
		formData.append('is_waiting', '0');
		formData.append('is_right', isRight);
		formData.append('comment', this.state.comment);
		formData.append('grade', this.state.grade);
		formData.append('save_as_template', this.state.saveAsTemplate);
		
		let teacherFilesInput = this.answerTeacherFilesInputRef.current;
		
		if (teacherFilesInput) {
			let teacherFiles = teacherFilesInput.files;
			for (let fileIndex = 0; fileIndex < teacherFiles.length; fileIndex++) {
				let file = teacherFiles[fileIndex];
				formData.append('teacher_files[]', file);
			}
		}
		
		axios({
			method: 'put',
			url: Utils.apiUrl('test-answer-teacher') + '/' + answerId,
			data: formData,
			params: {
				'accessToken': Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'QuestionView.check.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			if (this.props.afterCheck) {
				this.props.afterCheck(isRight, response);
			}
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			Utils.axiosErrorAlert(error);
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	closeViewer = () => {
		this.setState((prevState) => {
			return {
				imgViewerIsOpen: false,
			}
		});
	}
	
	render() {
		
		const logName = 'QuestionView.render #' + this.props.question.id;
		const logAllow = 1;
		const logCollapsed = 1;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state, 'this.state', logAllow);
		Logger.log(this.props, 'this.props', logAllow);
		
		const user = this.props.user;
		Logger.log(user, 'user', logAllow);
		
		let question = {...this.props.question};
		Logger.log(question.id, 'question.id', logAllow);
		Logger.log(question, 'question', logAllow);
		
		let studentFilesMultiple = !['handwrite_ocr'].includes(question.view_type_alias);
		Logger.log(studentFilesMultiple, 'studentFilesMultiple', logAllow);
		
		let studentFilesAccept = Config.acceptedFiles;
		
		if (['handwrite_ocr'].includes(question.view_type_alias)) {
			studentFilesAccept = 'image/jpeg';
		}
		
		// вставляем поля ввода вместо заглушек
		
		let completionsUserAnswers = this.state.completions;
		let completionsRightAnswers = Question.getCompletionAnswers(question.about);
		let completionIndex = 0;
		
		let latestAnswer = new Answer(question.latest_answer || this.props.answer);
		Logger.log(latestAnswer, 'latestAnswer', logAllow);
		
		let latestAnswerId = latestAnswer ? latestAnswer.id : null;
		Logger.log(latestAnswerId, 'latestAnswerId', logAllow)
		
		// let variantsArray = question.getVariantsArray(question.use_shuffle);
		let variantsArray = this.state.variantsArray;
		Logger.log(variantsArray, 'variantsArray', logAllow);
		
		let rightVariants = question.getRightVariants();
		// Logger.log(rightVariants, 'rightVariants', logAllow);
		
		let answerTextFieldName = `question-${question.id}-answer`;
		
		const disabled =
			this.props.mode !== 'demo'
			&& (this.props.mode === 'check' || (latestAnswer.id ? (latestAnswer.is_right || !this.props.reanswers || latestAnswer.is_waiting) : false))
		;
		Logger.log(disabled, 'disabled', logAllow);
		
		// готовим к отображению текст задания полями ввода / формулами
		//
		// для этого:
		// - собираем данные о полях ввода (Question.getCompletionAnswers)
		// - разбиваем текст на массив, при помощи регулярок (Question.completionPlugRegex)
		// - получаем массив, состоящий только из "чистых" кусков обычного текста, без спец-конутрукций
		// - заводим новый массив, в который сложим компоненты, готовые к рендерингу
		// - заменяем каждый такой кусок на тег span с "живым" html внутри и добавляем в
		//      допустимы простые инлайн-теги типа b, i, u, small, strong, sub, sup и тд
		// - после каждого такого элемента добавляем в массив рекат-компонент с полем ввода,
		//      используя данные, полученные на первом шаге
		// - при рендеринге выводится итоговый массив компонентов
		//
		// ограничения такого подхода:
		// - недопустимо наличие тегов типа p, div, table и тд, так как они будут прерваны span-ами при вставке полей
		
		let aboutData = [];
		
		if (question.about) {
			
			let aboutText = question.about;
			Logger.log(aboutText, 'aboutText', logAllow);
			
			let isDraftEditorContentState = Utils.isJsonString(aboutText);
			Logger.log(isDraftEditorContentState, 'isDraftEditorContentState', logAllow);
			
			const addBrs = !isDraftEditorContentState;
			
			// if (isDraftEditorContentState) {
			// 	const rawContentState = JSON.parse(aboutText);
			// 	Logger.log(rawContentState, 'rawContentState', logAllow);
			// 	aboutText = draftToHtml(rawContentState);
			// 	Logger.log(aboutText, 'aboutText', logAllow);
			// }
			
			if (question.has_formulas > 0) {
				
				aboutData = Question.renderableFormulas(aboutText, addBrs);
				
			} else {
				
				let strings = aboutText.split("\n");
				// let strings = [question.about];
				// Logger.log(strings, 'strings', logAllow);
				
				strings.forEach((string) => {
					
					// Logger.groupStart('stringDataItem', logAllow);
					
					let stringData = string.split(Question.completionPlugRegex);
					Logger.log(stringData, 'stringData', logAllow);
					
					stringData.forEach((stringDataItem, stringIndex) => {
						
						// Logger.log(stringDataItem, 'stringDataItem', logAllow);
						
						// aboutData.push(stringDataItem);
						
						aboutData.push(
							<span
								dangerouslySetInnerHTML={{__html: stringDataItem}}
								// key={'string_' + stringIndex}
							></span>
						);
						
						let isNotLast = stringIndex < stringData.length - 1;
						let isEmpty = stringDataItem === '';
						let rightAnswer = completionsRightAnswers[completionIndex];
						
						if (isNotLast || (isNotLast && isEmpty)) {
							
							let input = <b>???</b>;
							
							if (question.view_type_alias === 'completion_select') {
								
								let variants = question.getVariantsByPosition(completionIndex + 1, this.state.variantsArray);
								// Logger.log(variants, 'variants', logAllow);
								
								// shuffle variants
								// Utils.arrayShuffle(variants)
								
								input =
									<select
										className={[
											'form-control',
											'completion-input',
										].join(' ')}
										onChange={(event) => {
											let input = event.target;
											let val = input.value;
											let index = parseInt(input.getAttribute('data-index'));
											completionsUserAnswers[index] = val;
											this.setState((prevState) => {
												return {
													completions: completionsUserAnswers,
												}
											});
										}}
										// value={this.state.completionsUserAnswers[completionIndex]}
										data-index={completionIndex}
										disabled={disabled}
									>
										<option value=""></option>
										{variants.map((variant) => {
											let value = variant.name;
											let selected = ['pass', 'check'].includes(this.props.mode)
												? completionsUserAnswers[completionIndex] == value
												: completionsRightAnswers[completionIndex] == value;
											return (
												<option selected={selected} value={value}>
													{value}
												</option>
											);
										})}
									</select>;
								
							} else if (question.view_type_alias === 'completion_cloud') {
								
								Logger.log(this.state.completions, 'this.state.completionsUserAnswers', logAllow);
								
								let cloudText = this.state.completions[completionIndex] || '';
								// Logger.log(cloudText, 'cloudText', logAllow);
								
								input = (
									<input
										type={'text'}
										className={[
											'form-control',
											'completion-input',
										].join(' ')}
										// onChange={(event) => {
										//     console.log(event.target.value);
										// }}
										defaultValue={cloudText}
										// defaultValue={latestAnswer && !latestAnswer.is_right ? '' : (cloudText ? cloudText.text : '')}
										readOnly={true}
										data-size={rightAnswer.size}
										style={{
											width: Question.completionInputMinSize * rightAnswer.size + 'px',
										}}
										// title={cloudText}
									/>
								);
								
							} else {
								
								input = <input
									
									type="text"
									
									className={[
										'form-control',
										'completion-input',
									].join(' ')}
									
									onChange={(event) => {
										let input = event.target;
										let val = input.value;
										let completions = this.state.completions;
										let index = parseInt(input.getAttribute('data-index'));
										completions[index] = val;
										this.setState((prevState) => {
											return {
												completions: completions,
											}
										});
									}}
									
									value={this.state.completions[completionIndex]}
									
									title={this.state.completions[completionIndex]}
									
									// !!! dev only
									// defaultValue={rightAnswer.text}
									
									placeholder={this.props.mode === 'demo' ? rightAnswer.text : ''}
									
									data-size={rightAnswer.size}
									
									style={{
										width: Question.completionInputMinSize * rightAnswer.size + 'px',
									}}
									
									readOnly={latestAnswer ? latestAnswer.is_right : false}
									
									data-index={completionIndex}
								
								/>;
								
							}
							
							aboutData.push(input);
							
							completionIndex++;
							
						}
						
					});
					
					if (addBrs && stringData.length > 0) {
						aboutData.push(<br/>);
					}
					
					// Logger.groupEnd(logAllow);
					
				});
				
			}
			
		}
		
		Logger.log(aboutData, 'aboutData', logAllow);
		
		let rightAnswer = null
		
		if (question.use_right_answer_templates == 1) {
			
			rightAnswer = Array.isArray(question.right_answer_templates) && question.right_answer_templates.length > 0 ? (
				
				<table className={`right-answer-templates table table-bordered table-striped table-hover`}>
					
					<thead>
						<tr>
							{/*<th>#</th>*/}
							<th>{i18next.t(`Content`)}</th>
							<th title={i18next.t("Percent of the max points")}>
								{i18next.t(`Grade`)}
							</th>
						</tr>
					</thead>
					
					<tbody>
					
						{question.right_answer_templates.map((rightAnswerTemplate, rightAnswerTemplateIndex) => {
							
							return (
								
								<tr className={`right-answer-template item`}>
									
									{/*<td>
										{rightAnswerTemplateIndex + 1}
									</td>*/}
									
									<td>
										{(question.has_formulas == 1) ? (
											
											<>
												<Formula
													text={rightAnswerTemplate.content}
												/>
											</>
											
										) : (
											
											<>{rightAnswerTemplate.content}</>
											
										)}
									</td>
									
									<td>{rightAnswerTemplate.grade}%</td>
									
								</tr>
								
							)
							
						})}
					
					</tbody>
				
				</table>
				
			) : (
				
				<small className={`text-muted`}>{i18next.t("No templates yet")}</small>
				
			)
		
		} else if (question.answer) {
			
			rightAnswer = question.answers_type_alias === 'asciiMath'
				? <Formula text={question.answer}/>
				: question.answer
				
		}
		
		Logger.log(rightAnswer, 'rightAnswer', logAllow);
		
		let accordancesArray = this.state.accordances;
		// let accordancesArray = this.state.accordances || [];
		Logger.log(accordancesArray, 'accordancesArray', logAllow);
		
		let accordancesArrayNoShuffle = question.getAccordancesArray(false);
		Logger.log(accordancesArrayNoShuffle, 'accordancesArrayNoShuffle', logAllow);
		
		let imgs = question.images.map((imageUploadData, imageIndex) => {
			return {
				src: imageUploadData.url,
			};
		});
		Logger.log(imgs, 'imgs', logAllow);
		
		let errors = this.getErrors();
		let isValid = errors.length === 0;
		
		let course = this.props.course;
		let lesson = this.props.lesson;
		let theme = this.props.theme;
		
		let debugInfoArray = [
			'id: ' + question.id,
			'bind_id: ' + question.bind_id,
			'course: ' + (course ? course.name + ' #' + course.id : '???'),
			'lesson: ' + (lesson ? lesson.name + ' #' + lesson.id : '???'),
			'theme: ' + (theme ? theme.name + ' #' + theme.id : '???'),
			'created_at: ' + question.created_at,
			// 'answer: ' + latestAnswerId,
			// 'disabled: ' + disabled,
			// 'variant_id: ' + question.variant_id,
		];
		
		if (latestAnswer) {
			debugInfoArray.push('answer: #' + latestAnswerId);
		}
		
		if (question.copy_of_id) {
			debugInfoArray.push('copy of #' + question.copy_of_id);
		}
		
		let debugInfoString = debugInfoArray.join(' | ');
		
		let teacherFilesList = latestAnswer && latestAnswer.teacher_files ? (
			<UploadsList uploads={latestAnswer.teacher_files}/>
		) : null;
		
		let hasTeacherComment = latestAnswer && latestAnswer.hasTeacherComment();
		Logger.log(hasTeacherComment, 'hasTeacherComment', logAllow);
		
		// отображение выбранных файлов // подготовка данных
		
		let studentFiles = this.state.studentFiles;
		Logger.log(studentFiles, 'studentFiles', logAllow);
		
		const setAnswerText = (event) => {
			let input = event.target;
			let val = input.value;
			this.setState((prevState) => {
				return {
					answerText: val,
				}
			});
		};
		
		let answerText = this.state.answerText;
		
		let mediaColSize = ['completion_cloud'].indexOf(question.view_type_alias) >= 0
			? 'col-12'
			: 'col-xl-6 col-md-12 col-sm-12 col-12'
		;
		
		const reanswers = this.props.reanswers;
		
		let saveAnswerBtnStyle =
			latestAnswer
				? reanswers
					? latestAnswer.is_right
						? 'success'
						: latestAnswer.is_waiting
							? 'primary'
							: 'danger'
					: 'primary'
				: 'default'
		
		const emptyFunction = () => {
		};
		
		let saveAnswerBtnOnClick =
			latestAnswer.id
				? reanswers
					? (latestAnswer.is_right || latestAnswer.is_waiting)
						? emptyFunction
						: reanswers
							? this.saveAnswer
							: emptyFunction
					: emptyFunction
				: this.saveAnswer
		;
		Logger.log(saveAnswerBtnOnClick, 'saveAnswerBtnOnClick', logAllow);
		
		let saveAnswerBtnIconSrc = null;
		
		let latestAnswerHasGrade = latestAnswer?.grade !== null && latestAnswer?.grade !== undefined
		
		let saveAnswerBtnText =
			latestAnswer.id
				? reanswers
					? latestAnswer.is_waiting
						? i18next.t("Waiting for check")
						: latestAnswer.is_right
							? latestAnswerHasGrade
								? (parseFloat(latestAnswer.grade) > 100 ? 100 : latestAnswer.grade) + '%' + ' | ' + i18next.t("Excellent") + '!'
								: i18next.t("Right")
							: latestAnswerHasGrade
								? latestAnswer.grade + '%' + ' | ' + i18next.t("Try again")
								: i18next.t("Try again")
					: i18next.t("Answer saved")
				: this.props.checkAnswerBtnText || i18next.t("Check")
		;
		
		let saveAnswerBtnClickable =
			latestAnswer.id
				? (this.props.reanswers && !latestAnswer.is_right && !latestAnswer.is_waiting)
				: true
		
		// let saveAnswerBtnText = 'save answer btn';
		
		let className = [
			'question',
			// 'bg-' + question.bg_style_alias,
			// 'border-' + question.border_style_alias,
		];
		
		if (this.props.showStateBorder) {
			className.push('show-state-border');
		}
		
		if (latestAnswerId) {
			
			className.push('has-answer');
			
			if (reanswers) {
				if (latestAnswer.is_waiting) {
					className.push('waiting');
				} else {
					if (latestAnswer.is_right) {
						className.push('right-answer');
					} else {
						className.push('wrong-answer');
					}
				}
			}
			
		}
		
		if (this.props.reanswers) {
			className.push('reanswers-on');
		} else {
			className.push('reanswers-off');
		}
		
		className = className.join(' ');
		
		let differentFilesSplitted = Utils.splitPdfsAndNotPdfs(question.different_files);
		let pdfs = differentFilesSplitted.pdfs;
		let differentFilesNotPdfs = differentFilesSplitted.notPdfs;
		// Logger.log(differentFilesNotPdfs, 'notPdfs', logAllow);
		
		let questionPathData = [];
		
		if (this.props.course) {
			questionPathData.push(this.props.course.name);
		}
		
		if (this.props.lesson) {
			questionPathData.push(this.props.lesson.name);
		}
		
		if (this.props.theme) {
			questionPathData.push(this.props.theme.name);
		}
		
		let maxPoints = parseFloat(question.weight)
		
		// let latestAnswerGrade = parseFloat(latestAnswer.grade)
		// Logger.log(latestAnswerGrade, 'latestAnswerGrade', logAllow)
		
		let studentPoints = latestAnswer?.getStudentPoints()
		Logger.log(studentPoints, 'studentPoints', logAllow)
		
		Logger.log(user.isStudent(), 'user.isStudent()', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div
				className={className}
				data-question-id={question.id}
				data-answer-id={latestAnswerId}
				data-has-errors={errors.length > 0}
				data-debug={debugInfoString}
				data-view-type-alias={question.view_type_alias}
			>
				
				{(user.can('debugInfo')) &&
					<div className={'debug-info'} title={i18next.t("Информация для отладки (студентам не видна)")}>
						{debugInfoString}
						<hr/>
					</div>
				}
				
				{/* данные о курсе и тд / путь к заданию */}
				{(this.props.mode == 'check' && this.props.showPath && (this.props.course.id || this.props.lesson.id || this.props.theme.id)) && (
					<div className={'question-path'}>
						
						{/*<div className="h4">
							{questionPathData.join(' / ')}
						</div>*/}
						
						{(this.props.course) &&
							<div className={'course-info h5 text-center'}
								 title={i18next.t("Course") + ' #' + this.props.course.id}>
								{/*<small className={'course-label text-muted'}>{i18next.t("Курс")}: </small>*/}
								<span className={'course-name'}>{this.props.course.name}</span>
								{/*<small className={'course-id text-muted'}> #{this.props.course.id}</small>*/}
							</div>
						}
						
						{(this.props.lesson) &&
							<div className={'lesson-info h6 text-center'}
								 title={i18next.t("Lesson") + ' #' + this.props.lesson.id}>
								{/*<small className={'lesson-label text-muted'}>{i18next.t("Урок")}: </small>*/}
								<span className={'lesson-name'}>{this.props.lesson.name}</span>
								{/*<small className={'lesson-id text-muted'}> #{this.props.lesson.id}</small>*/}
							</div>
						}
						
						{(this.props.theme) &&
							<div className={'theme-info h6 text-center'}
								 title={i18next.t("Theme") + ' #' + this.props.theme.id}>
								{/*<small className={'theme-label text-muted'}>{i18next.t("Тема")}: </small>*/}
								<span className={'theme-name'}>{this.props.theme.name}</span>
								{/*<small className={'theme-id text-muted'}> #{this.props.theme.id}</small>*/}
							</div>
						}
						
						<hr/>
					
					</div>
				)}
				
				{/* препод // проверка // данные о студенте */}
				{this.props.mode === 'check' && this.props.showStudentInfo && latestAnswer && (
					
					<div className={'student-info'}>
						
						<small
							className={'student-name'}
							title={'#' + latestAnswer.user.id}
						>{latestAnswer.user.getDisplayName()}</small>
						
						{latestAnswer.user.groups.map((group) => {
							return (
								<>
									&nbsp;&bull;&nbsp;
									<small
										className={'student-group-name' + (this.props.user.isRightGroup(group.id) ? ' text-success' : ' text-danger')}
										title={'#' + group.id}
									>{group.name}</small>
								</>
							);
						})}
						
						&nbsp;&bull;&nbsp;
						
						<small
							className={'datetime'}
							title={''}
						>{DateHelper.dateTime(latestAnswer.created_at, 'd.m.y', 'h:m')}</small>
					
					</div>
				)}
				
				{/* название / номер задания */}
				{(this.props.num || question.name) && (
					<div className="question-name" title={'#' + question.id}>
						{this.props.num ? (
							<>{i18next.t("Task")} {this.props.questionsCount > 1 ? i18next.t("{{num}} of {{count}}", {
								num: this.props.num,
								count: this.props.questionsCount
							}) : this.props.num}</>
						) : (
							question.name
						)}
					</div>
				)}
				
				{/* максимальный балл (вес) задания */}
				{(user.can('seeQuestionsPoints') || this.props.weightVisible) && (
					
					<div className={'weight text-muted'}>
						
						<div className={`max-points`}>
							{i18next.t("Max points")}: <span className={"bold"}>{maxPoints}</span>
						</div>
						
						{(this.props.mode == 'check') && (
							<div className={'student-points'}>
								{i18next.t("Student points")}: <span className={"bold"}>{studentPoints}</span>
							</div>
						)}
						
						{(user.isAny(['teacher', 'admin', 'owner'])) && (
							<>
								<div className={`question-id-box`}>
									<span
										className={`question-id small text-muted clickable`}
										onClick={() => {
											Utils.copyToClipboard(question.id)
										}}
										title={i18next.t("Copy ID to clipboard")}
									>#{question.id}</span>
								</div>
							</>
						)}
						
					</div>
					
				)}
				
				{/* кнопка "сброс" */}
				{(!latestAnswer && !user.isStudent() && this.props.showClearBtn) && (
					
					<div className="controls top-controls">
						
						<button
							type={'button'}
							className={[
								'clear-btn',
								'my-btn',
								'my-btn-sm',
							].join(' ')}
							onClick={(event) => {
								this.clear();
							}}
						>{i18next.t("Clear")}</button>
					
					</div>
				
				)}
				
				<div className={'body row'}>
					
					{/* файлы пдф в просмотровщике */}
					{(pdfs.length > 0) &&
						<div className="col-12 pdf-col">
							{pdfs.map((uploadBindData) => {
								return (
									<PdfView
										url={uploadBindData.url}
										name={uploadBindData.original_name_only}
										toolbar={true}
									/>
								);
							})}
						</div>
					}
					
					{/* картинки, звуки и прочие файлы */}
					{(question.hasFiles('images') || question.hasFiles('sounds') || differentFilesNotPdfs.length > 0) && (
						
						<div className={[
							'media',
							'media-col',
							mediaColSize,
							// 'col-md-12',
							// 'col-xl-6',
							'align-self-center',
						].join(' ')}>
							
							{question.hasFiles(['images']) && (
								<div className={'files-box images'}>
									{question.images.filter(uploadBind => uploadBind.active == 1).map((imageUploadData, imageIndex) => {
										return (
											<div className={'media-box img-box'}>
												<a href={imageUploadData.url}
												   className={'img-link'}
												   target={'_blank'}
												   key={'img_' + imageIndex}
												>
													<img src={imageUploadData.preview_url}
														 alt="img"
														 onClick={(event) => {
															 // todo
														 }}
														 className={[
															 'clickable',
														 ].join(' ')}
													/>
												</a>
												<small
													className={'upload-name text-muted'}>{imageUploadData.name}</small>
											</div>
										);
									})}
								</div>
							)}
							
							{question.hasFiles(['sounds']) && (
								<div className={'files-box sounds'}>
									{question.sounds.filter(uploadBind => uploadBind.active == 1).map((soundUploadData) => {
										return (
											<div className="media-box sound-box">
												<audio
													src={soundUploadData.url}
													controls={true}
												/>
												<br/><small
												className={'upload-name text-muted'}>{soundUploadData.name}</small>
											</div>
										);
									})}
								</div>
							)}
							
							{question.hasFiles(['different_files']) && (
								<UploadsList uploads={differentFilesNotPdfs}/>
							)}
						
						</div>
					
					)}
					
					{/* текст задания и поле ответа  */}
					<div className={"task-and-answer col-md align-self-center" + (user.role_alias === 'student' ? ' nocopy' : '')}>
						
						<div className="task task-about">
							{aboutData}
						</div>
						
						{(question.hasTables()) &&
							<div
								className={'tables table-responsive'}
								dangerouslySetInnerHTML={{__html: question.tables}}
							></div>
						}
						
						<div className="answer">
							
							{(this.props.mode === 'check' && latestAnswerId && !['completion_write', 'completion_select'].includes(question.view_type_alias)) && (
								<div className="head bold mb-2">
									<hr/>
									{i18next.t("Student's answer")}
								</div>
							)}
							
							{(!(latestAnswerId && this.props.hideSavedAnswer)) && (
								
								<div className={`answer-tools`}>
								
									{/* свободный ввод */}
									{['string'].indexOf(question.view_type_alias) >= 0 && (
										
										<div className={'student-answer-input-box'}>
											
											{(['string'].indexOf(question.answers_type_alias) >= 0) && (
												<input
													id={'answer'}
													name={answerTextFieldName}
													type={'text'}
													className={'form-control'}
													onChange={setAnswerText}
													value={answerText}
													disabled={disabled}
													placeholder={i18next.t("Your answer")}
												/>
											)}
											
											{(['text'].indexOf(question.answers_type_alias) >= 0) && (
												<textarea
													name={answerTextFieldName}
													rows={5}
													className={[
														'form-control',
														'completion',
													].join(' ')}
													value={answerText}
													onChange={setAnswerText}
													disabled={disabled}
													placeholder={i18next.t("Your answer")}
												></textarea>
											)}
											
											{(['rich_text'].indexOf(question.answers_type_alias) >= 0) && (
												<>{
													<Editor
														id={'tinymce_' + Rand.id()}
														onInit={(evt, editor) => {
															this.answerRichTextEditorRef.current = editor;
														}}
														initialValue={this.state.tables}
														init={Config.tinyMceMainConfig}
														// init={Config.tinyMceMainConfig}
													/>
													/*<TextEditor
														text={answerText}
														onChange={(text) => {
															this.setState((prevState) => {
																return {
																	answerText: text,
																}
															});
														}}
														disabled={disabled}
													/>*/
												}</>
											)}
											
											{(['int'].indexOf(question.answers_type_alias) >= 0) && (
												<input
													name={answerTextFieldName}
													type={'number'}
													step={1}
													className={'form-control'}
													value={answerText}
													onChange={setAnswerText}
													disabled={disabled}
													placeholder={i18next.t("Your answer")}
												/>
											)}
											
											{(['float'].indexOf(question.answers_type_alias) >= 0) && (
												<input
													name={answerTextFieldName}
													type={'number'}
													step={0.1}
													className={'form-control'}
													value={answerText}
													onChange={setAnswerText}
													disabled={disabled}
													placeholder={i18next.t("Your answer")}
												/>
											)}
											
											{(['date'].indexOf(question.answers_type_alias) >= 0) && (
												<input
													name={answerTextFieldName}
													type={'date'}
													step={0.1}
													className={'form-control'}
													value={answerText}
													onChange={setAnswerText}
													disabled={disabled}
													placeholder={i18next.t("Your answer")}
												/>
											)}
											
											{(['asciiMath'].indexOf(question.answers_type_alias) >= 0) && (
												<Formula
													text={answerText}
													showEditor={true}
													onChange={(text) => {
														this.setState((prevState) => {
															return {
																answerText: text,
															}
														});
													}}
													disabled={disabled}
												/>
											)}
											
											{(['asciiMath_and_text'].indexOf(question.answers_type_alias) >= 0) && (
												<>
													<textarea
														name={answerTextFieldName}
														rows={5}
														className={[
															'form-control',
															'completion',
														].join(' ')}
			
														value={answerText}
														onChange={setAnswerText}
														disabled={disabled}
														placeholder={i18next.t("Your answer")}
													></textarea>
													<div className="help">{Question.formulaHelp}</div>
													<div className="formula-preview-box mt-1">
														<div className="title">
															{i18next.t("Formula editor with preview")}
														</div>
														<Formula showEditor={true}/>
													</div>
												</>
											)}
										
										</div>
									
									)}
									
									{/* выбор из вариантов */}
									{['poll'].indexOf(question.view_type_alias) >= 0 && (
										
										<div className={'variants'}>
											
											{(rightVariants.length > 1) &&
												<div className={'form-text text-muted'}>
													{i18next.t("Check all right options")}
												</div>
											}
											
											{variantsArray.map((variant, variantIndex) => {
												
												let isPositive = parseFloat(variant.percent) > 0
												
												let isChecked = this.state.pollSelected.indexOf(variant.alias) >= 0
												
												return (
													<div
														className={'variant'}
														key={'variant-' + variant.alias}
													>
														
														<label>
															
															<input
																type={rightVariants.length > 1 ? 'checkbox' : 'radio'}
																name={answerTextFieldName}
																value={variant.alias}
																checked={isChecked}
																onChange={(event) => {
																	let input = event.target;
																	let val = input.value;
																	let pollSelected = this.state.pollSelected;
																	if (input.getAttribute('type') === 'radio') {
																		pollSelected = [val];
																	} else {
																		if (input.checked) {
																			pollSelected.push(val);
																		} else {
																			pollSelected.splice(pollSelected.indexOf(val), 1);
																		}
																	}
																	this.setState((prevState) => {
																		return {
																			pollSelected: pollSelected,
																		}
																	});
																}}
																// readOnly={latestAnswer ? latestAnswer.is_right : false}
																disabled={disabled || (this.state.pollSelected.length == rightVariants.length && !isChecked)}
															/>
															
															<span className={'variant-name d-inline-block ml-2'}>
																{question.has_formulas ? Question.renderableFormulas(variant.name, 0, true) : variant.name}
															</span>
															
															{(user.can('seeRightAnswer') && variant.percent) && (
																<>
																	<small className={'variant-value d-inline-block ml-2 bold' + (isPositive ? ' text-success' : ' text-danger')}>
																		[{variant.percent ? (isPositive ? '+' + variant.percent : variant.percent) : '-'}%]
																	</small>
																</>
															)}
														
														</label>
														
													</div>
													
												);
											})}
										</div>
									
									)}
									
									{/* сортировка */}
									{['order'].indexOf(question.view_type_alias) >= 0 && (
										
										<div className={'accordances'}>
											
											{accordancesArray.map((accordance, index) => {
												
												return (
													
													<div className="input-group list-item" data-alias={accordance.alias}>
														
														{/*<input
															className={[
																'form-control',
																// 'list-item',
															].join(' ')}
															value={accordance.left}
															readOnly={true}
														></input>*/}
														
														<div className="form-control">
															{question.has_formulas
																? Question.renderableFormulas(accordance.left, 0, true)
																: accordance.left}
														</div>
														
														<div className="input-group-append">
															
															<button type={'button'}
																	className={'up-btn btn btn-light'}
																	onClick={(event) => {
																		this.accordances.up(index);
																	}}
																	disabled={disabled}
															><Icon.ArrowUp/></button>
															
															<button type={'button'}
																	className={'up-btn btn btn-light'}
																	onClick={(event) => {
																		this.accordances.down(index);
																	}}
																	disabled={disabled}
															><Icon.ArrowDown/></button>
														
														</div>
													
													</div>
												
												);
												
											})}
										
										</div>
									
									)}
									
									{/* соответствие v2 */}
									{['accordance'].indexOf(question.view_type_alias) >= 0 && (
										
										<div className={'accordances'}>
											
											{accordancesArrayNoShuffle.map((accordance, index) => {
												
												let left = accordance.left;
												let right = accordancesArray[index].right;
												
												let controls = (
													
													<div className="input-group-append">
														
														<button type={'button'}
																className={'up-btn btn btn-light'}
																onClick={(event) => {
																	this.accordances.up(index);
																}}
														><Icon.ArrowUp/></button>
														
														<button type={'button'}
																className={'up-btn btn btn-light'}
																onClick={(event) => {
																	this.accordances.down(index);
																}}
														><Icon.ArrowDown/></button>
													
													</div>
												
												);
												
												return (
													
													<div className="row my-3">
														
														<div className="col-6 left-col">
															<div className="list-item">
																{question.has_formulas
																	? Question.renderableFormulas(left, 0, true)
																	: left}
															</div>
														</div>
														
														<div className="col-6 right-col">
															
															<div className="input-group list-item">
																
																<div
																	className={[
																		'form-control',
																		// 'list-item',
																	].join(' ')}
																>
																	{question.has_formulas
																		? Question.renderableFormulas(right, 0, true)
																		: right}
																</div>
																
																{this.props.mode === 'demo' && (
																	controls
																)}
																
																{!latestAnswer && this.props.mode !== 'demo' && (
																	controls
																)}
																
																{latestAnswer && !latestAnswer.is_right && (
																	controls
																)}
															
															</div>
														
														</div>
													
													</div>
												
												);
												
											})}
										
										</div>
									
									)}
									
									{/* подстановка (таблица) */}
									{['completion'].indexOf(question.view_type_alias) >= 0 && (
										
										<div className={'completion-table'}>
											
											{accordancesArray.map((accordance, accordanceIndex) => {
												return (
													<div className={'row accordance-row'}>
														<div className="col-sm-6 col1">
															<div className="list-item">
																{question.has_formulas
																	? Question.renderableFormulas(accordance.left, 0, true)
																	: accordance.left}
															</div>
														</div>
														<div className="col-sm-6 col2">
															<div className={'input-group list-item'}>
																<input
																	type="text"
																	className={'form-control'}
																	placeholder={this.props.mode === 'demo' ? accordance.right : i18next.t("Your answer")}
																	onChange={(event) => {
																		
																		const logName = 'QuestionView.accordance.input.onChange';
																		const logAllow = 0;
																		const logCollapsed = 0;
																		
																		Logger.groupStart(logName, logAllow, logCollapsed);
																		
																		const input = event.target;
																		const val = input.value;
																		const accordances = this.state.accordances;
																		
																		accordances[accordanceIndex].answer = val;
																		
																		this.setState((prevState) => {
																			return {
																				accordances: accordances,
																			}
																		});
																		
																		Logger.groupEnd(logAllow);
																		
																	}}
																	value={this.state.accordances[accordanceIndex].answer || ''}
																	readOnly={latestAnswer ? latestAnswer.is_right : false}
																	disabled={disabled}
																/>
															</div>
														</div>
													</div>
												);
											})}
											
										</div>
									
									)}
									
									{/* прикрепление файлов в качестве ответа */}
									{((this.props.mode === 'pass' || this.props.mode === 'demo') && !disabled && (['files', 'handwrite_ocr'].includes(question.view_type_alias) || question.files_as_answers > 0)) ? (
										
										<div className="files-input-box student-files-input-box">
											
											{(['handwrite_ocr'].includes(question.view_type_alias)) &&
												<div className={'form-text text-muted small'}>
													{i18next.t("Only one JPEG file accepted")}
												</div>
											}
											
											<div className="input-group">
												<div className="custom-file">
													<input
														id={'student_files'}
														ref={this.answerStudentFilesInputRef}
														type={'file'}
														multiple={studentFilesMultiple}
														accept={studentFilesAccept}
														className={'custom-file-input'}
														onChange={(event) => {
															this.setState((prevState) => {
																return {
																	studentFiles: event.target.files,
																}
															});
														}}
													/>
													<label className="custom-file-label" htmlFor="student_files">
														{i18next.t("Choose files")}
													</label>
												</div>
											</div>
											
											{(studentFiles.length > 0) ? (
												<div className={'choosen-files-list-box'}>
													<ol className="choosen-files-list">
														{Array.from(studentFiles).map(file => {
															return (
																<li>{file.name}</li>
															);
														})}
													</ol>
												</div>
											) : ''}
											
										</div>
									
									) : ''}
									
								</div>
								
							)}
							
							{/* сообщение "нет ответа" */}
							{(this.props.mode === 'check' && !latestAnswerId) && (
								<>
									<div className="head bold mb-2">
										<hr/>
										{i18next.t("Student's answer")}
									</div>
									<div className={`no-answer-msg empty-msg text-danger`}>
										{i18next.t('No answer')}
									</div>
								</>
							)}
						
						</div>
					
					</div>
					
					{/* облака */}
					{(question.view_type_alias === 'completion_cloud' && !disabled) && (
						
						<div className={'clouds col-md align-self-center'}>
							
							{this.state.unusedClouds.map((cloud, index) => {
								// if (disabled) {
								//     return;
								// }
								return (
									<button
										className={'cloud'}
										onClick={(event) => {
											
											const logName = 'QuestionView.cloud.click';
											const logAllow = 1;
											const logCollapsed = 0;
											
											Logger.groupStart(logName, logAllow, logCollapsed);
											
											let completions = this.state.completions;
											// Logger.log(completionsUserAnswers, 'completionsUserAnswers (before push)', logAllow);
											completions.push(cloud.text);
											// Logger.log(completionsUserAnswers, 'completionsUserAnswers (after push)', logAllow);
											
											let unusedClouds = this.state.unusedClouds;
											Logger.log(unusedClouds, 'unusedClouds (before splice)', logAllow);
											
											unusedClouds.splice(index, 1);
											Logger.log(unusedClouds, 'unusedClouds (after splice)', logAllow);
											
											this.setState((prevState) => {
												return {
													// completionsUserAnswers: completionsUserAnswers,
													unusedClouds: unusedClouds,
													completions: completions
												}
											});
											
											Logger.groupEnd(logAllow);
											
										}}
										disabled={disabled}
									>{cloud.text}</button>
								);
							})}
						
						</div>
					
					)}
				
				</div>
				
				{/* файлы студента */}
				{(latestAnswer && Array.isArray(latestAnswer.student_files) && latestAnswer.student_files.length > 0) && (
					
					<div className={'uploads-list-box student-uploads-list-box student-files'}>
						<UploadsList
							uploads={['handwrite_ocr'].includes(question.view_type_alias) ? [latestAnswer.student_files[0]] : latestAnswer.student_files}
							originalNames={false}
						/>
					</div>
				
				)}
				
				{/* распознанный текст */}
				{(latestAnswer && ['handwrite_ocr'].includes(question.view_type_alias) && latestAnswer?.answer_data?.text) && (
					<div className={'right-answer'}>
						<div className="title">{i18next.t("Распознанный текст")}</div>
						<p>{latestAnswer?.answer_data?.text}</p>
					</div>
				)}
				
				{/* комментарий преподавателя */}
				{(this.props.mode !== 'check' && hasTeacherComment) && (
					
					<div className="comment teachers-comment">
						
						<div className={'title bold'}>
							{i18next.t("Teacher's comment")}
						</div>
						
						{(
							latestAnswer.comment
							&& latestAnswer.comment != 'null'
						) ? (
							<p>{latestAnswer.comment}</p>
						) : ''}
						
						{teacherFilesList}
						
					</div>
				
				)}
				
				{/* сохранение ответа + сообщения */}
				<div className={'controls pass-controls question-pass-controls'}>
					
					{(question.use_comments == 1 && user.can('commentQuestions')) &&
						<button
							type={'button'}
							className={[
								'msg-btn',
								'comment-btn',
								'question-comment-btn',
								'my-btn',
								'my-btn-light',
							].join(' ')}
							onClick={(event) => {
								this.setState({
									discussIsShown: !this.state.discussIsShown,
								})
							}}
						>&nbsp;</button>
					}
					
					{(user.can('answerQuestions')) &&
						<button
							type={'button'}
							className={[
								'save-answer-btn',
								'my-btn',
								'my-btn-' + saveAnswerBtnStyle,
								saveAnswerBtnClickable ? 'clickable' : 'not-clickable',
							].join(' ')}
							onClick={saveAnswerBtnOnClick}
						>{i18next.t(saveAnswerBtnText)}</button>
					}
				
				</div>
				
				{/* ручная проверка */}
				{(
					this.props.mode === 'check'
					&& latestAnswerId
					//&& latestAnswer.is_right != 1
					//&& (question.need_manual_check == 1 || ['string'].includes(question.view_type_alias))
				) && (
					
					<div className={`teacher-comment-box`}>
						
						<hr/>
						
						<div className="controls bottom-controls check-controls check-bottom-controls mt-3">
							
							<div className="grade-picker-box">
								
								<div className="row">
									
									<div className="main-col col">
										
										<select
											className={'grade-picker form-control'}
											onChange={(event) => {
												this.setState((prevState) => {
													return {
														grade: event.target.value,
													}
												});
											}}
											value={this.state.grade}
										>
											<option value="">{i18next.t("Select grade")}</option>
											{Config.grades.map((grade, gradeIndex) => {
												return (
													<option value={grade.percent}>{grade.percent}%</option>
												);
											})}
										</select>
										
										<div className={`form-group mb-0`}>
											<label htmlFor={`saveAsTemplate_question_${question.id}`}>
												<input
													id={`saveAsTemplate_question_${question.id}`}
													type={'checkbox'}
													checked={this.state.saveAsTemplate}
													onChange={(event) => {
														this.setState((prevState) => {
															return {
																saveAsTemplate: event.target.checked,
															}
														});
													}}
												/> {i18next.t("Save as template")}
											</label>
										</div>
									
									</div>
									
									<div className="save-btn-col col-auto">
										
										<button
											type={'button'}
											className={[
												'save-btn',
												'my-btn',
												// 'my-btn-sm',
												'my-btn-primary',
											].join(' ')}
											onClick={(event) => {
												if (this.props.answer) {
													this.check(this.props.answer?.id, 0);
												} else {
													window.alert('no answer')
												}
											}}
										>{i18next.t("Save")}</button>
									
									</div>
								
								</div>
								
							</div>
							
						</div>
						
						<hr/>
						
						<FoldableList name={i18next.t("Teacher's comment")}>
							
							<div className="form-group">
								
								{/*
								<label htmlFor={'teacher-comment'} className={`bold`}>
									{i18next.t("Teacher's comment")}
								</label>
								*/}
								
								<textarea
									id={'teacher-comment'}
									className={'form-control'}
									value={this.state.comment}
									onChange={(event) => {
										let input = event.target;
										let val = input.value;
										this.setState((prevState) => {
											return {
												comment: val,
											}
										});
									}}
									required={false}
									rows={5}
									placeholder={i18next.t("Teacher's comment")}
								/>
							
							</div>
							
							{teacherFilesList}
							
							<div className="files-input-box teacher-files-input-box">
								<input
									ref={this.answerTeacherFilesInputRef}
									type="file"
									multiple={true}
								/>
							</div>
						
						</FoldableList>
					
					</div>
				
				)}
				
				{/* правильный ответ */}
				{(user.can('seeRightAnswer') && !!rightAnswer && ['string'].includes(question.view_type_alias)) && (
					
					<>
						
						<hr/>
						
						{(question.use_right_answer_templates == 1) ? (
							
							<>
								<FoldableList
									extraClassName={['right-answer', 'right-answer-templates']}
									name={i18next.t("Answers templates")}
								>
									{rightAnswer}
								</FoldableList>
							</>
						
						) : (
							
							<>
								<div className={'right-answer'}>
									<div className={'head right-answer-title bold mb-2'}>
										{i18next.t("Right answer")}
									</div>
									<div className={'body right-answer-content'}>
										{rightAnswer}
									</div>
								</div>
							</>
						
						)}
					
					</>
				
				)}
				
				{/* статус ответа (иконка) */}
				{(latestAnswerId) &&
					<div
						className={'state-icon'}
						title={i18next.t(saveAnswerBtnText)}
					></div>
				}
				
				{/* сообщения */}
				{(question.use_comments == 1 && this.state.discussIsShown) && (
					<>
						<div className={`discuss-box`}>
							<Discuss
								preloader={this.props.preloader}
								targetModelAlias={'question'}
								targetModelId={question.id}
								targetUserId={this.props.discussTargetUserId || this.props.user.id}
								user={this.props.user}
							/>
						</div>
					</>
				)}
				
				{/* сообщения (модал) */}
				{/*
				<div className={`modals`}>
					
					<Modal
						className={[
							'comments-modal',
							// 'wide-modal',
						].join(' ')}
						show={this.state.discussIsShown}
						onHide={() => {
							this.setState({
								commentsModalIsShown: !this.state.discussIsShown,
							})
						}}
						size={'lg'}
						// keyboard={false}
						// backdrop={'static'}
					>
						
						<Modal.Body>
							
							<div className={`comments`}>
								
								<Discuss
									preloader={this.props.preloader}
									targetModelAlias={'question'}
									targetModelId={question.id}
									targetUserId={this.props.user.id}
								/>
								
							</div>
							
						</Modal.Body>
					
					</Modal>
					
				</div>
				*/}
			
			</div>
		
		);
		
	}
	
}

QuestionView.propTypes = {
	question: PropTypes.instanceOf(Question).isRequired,
	user: PropTypes.instanceOf(User).isRequired,
	answers: PropTypes.arrayOf(PropTypes.instanceOf(Answer)),
	material_id: PropTypes.number,
	material: PropTypes.instanceOf(Material),
	mode: PropTypes.oneOf(['demo', 'pass', 'check']),
	afterCheck: PropTypes.func,
	afterSendAnswer: PropTypes.func,
	preloader: PropTypes.object,
	path: PropTypes.string,
	num: PropTypes.any, // порядковый номер в списке
	reanswers: PropTypes.bool, // возможность ответить несколько раз
	showAnswerState: PropTypes.bool, // отображать статус верный / неверный ответ
	alert: PropTypes.object,
	weightVisible: PropTypes.bool,
	questionsCount: PropTypes.number,
	course: PropTypes.instanceOf(Course),
	showPath: PropTypes.bool,
	showStudentInfo: PropTypes.bool,
	showStateBorder: PropTypes.bool,
	showDiscuss: PropTypes.bool,
	discussTargetUserId: PropTypes.number,
	showClearBtn: PropTypes.bool,
	checkAnswerBtnText: PropTypes.string, // текст кнопки "проверить ответ"
	afterSendAnswerSuccess: PropTypes.func,
	hideSavedAnswer: PropTypes.any,
};

QuestionView.defaultProps = {
	answers: [],
	reanswers: true,
	showPath: true,
	showStudentInfo: true,
	showStateBorder: false,
	// checkAnswerBtnText: i18next.t("Check"), // ?! doesn't work
	showClearBtn: true,
	afterSendAnswerSuccess: () => {},
	hideSavedAnswer: false,
};