import "./Aside.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import {Link} from "react-router-dom";

import Config from "../../helpers/Config";
import LangMenu from "./LangMenu";

export default class Aside extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		return (
			
			<aside className={'Aside kfu-aside'}>
				
				<a
					className={'logo-box'}
					href={Config.kfuUrl}
					target={'_blank'}
					title={i18next.t("Kazan Federal University")}
				></a>
				
				<div className="controls">
					
					{this.props.items.map((asideItem) => {
						let url = asideItem.url || '/' + asideItem.alias;
						let isCurrent = asideItem.alias === this.props.asideItemsCurrentAlias;
						return asideItem.external ? (
							<a
								key={'aside-item_' + asideItem.alias}
								href={asideItem.url}
								className={[
									'aside-control',
									asideItem.alias,
									isCurrent ? 'current' : '',
								].join(' ')}
								target={'_blank'}
								title={asideItem.name}
							></a>
						) : (
							<Link
								key={'aside-item_' + asideItem.alias}
								className={[
									'aside-control',
									asideItem.alias,
									isCurrent ? 'current' : '',
								].join(' ')}
								to={url}
								onClick={(event) => {
									if (asideItem.onClick) {
									    asideItem.onClick(event);
									}
								}}
								title={asideItem.name}
							>
								{(asideItem.badgeText) &&
									<span className={'badge'}>
                                        {asideItem.badgeText}
                                    </span>
								}
							</Link>
						);
					})}
				
				</div>
				
				<div className="bottom-controls">
					<LangMenu
						langs={this.props.langs}
						showCurrentLangName={false}
						changeLang={this.props.changeLang}
					/>
				</div>
			
			</aside>
		
		);
		
	}
	
}

Aside.propTypes = {
	items: PropTypes.any,
	langs: PropTypes.any,
	langCode: PropTypes.string,
	changeLang: PropTypes.func,
	badgeText: PropTypes.string,
	asideItemsCurrentAlias: PropTypes.string,
};

Aside.defaultProps = {};