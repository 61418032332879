import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import i18next from "i18next";
import Question from "../../models/Question";
import axios from "axios";
import Utils from "../../helpers/Utils";
import "./TrainerQuestionForm.css";
import Material from "../../models/Material";
// import 'draft-js/dist/Draft.css';
import FormDataHelper from "../../helpers/FormDataHelper";
import UploadsGrid from "./UploadsGrid";

export default class TrainerQuestionForm extends React.Component {
	
	mode = 'create';
	
	constructor(props) {
		
		super(props);
		
		const logName = 'TrainerQuestionForm.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(props, 'props', logAllow);
		
		let model = props.model ? {...props.model} : new Question();
		
		this.mode = model.id ? 'update' : 'create';
		
		model.material_id = props.materialId;
		
		if (this.mode === 'create') {
			model.weight = 100;
			model.view_type_alias = 'completion_cloud';
			model.use_shuffle = 1;
			model.need_manual_check = 0;
			model.files_as_answers = 0;
			model.has_formulas = 0;
			model.is_weight_visible = 1;
		}
		
		this.state = {
			model: model,
		};
		
		// << UPLOADS REFS
		
		this.uploadRefs = {};
		
		Question.uploadAttrs.forEach((attr) => {
			this.uploadRefs[attr] = React.createRef();
		});
		
		// >> UPLOADS REFS
		
		Logger.groupEnd(logAllow);
		
	}
	
	inputChange = (event) => {
		
		const logName = 'TrainerQuestionForm.inputChange';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const target = event.target;
		const attr = target.getAttribute('name');
		const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
		
		const model = this.state.model;
		model[attr] = value;
		
		this.setState((prevState) => {
			return {
				model: model,
			}
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	getFormData = () => {
		
		const logName = 'TrainerQuestionForm.getFormData';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const formData = new FormData();
		
		// props
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		let modelProps = Object.getOwnPropertyNames(model);
		Logger.log(modelProps, 'modelProps', logAllow);
		
		modelProps.forEach((prop) => {
			
			if (Question.uploadAttrs.indexOf(prop) < 0 && !(model[prop] instanceof Function)) {
				
				let val = model[prop];
				
				if (Question.jsonAttrs.indexOf(prop) >= 0) {
					val = JSON.stringify(val);
				}
				
				formData.append(prop, val);
			}
			
		});
		
		// files
		
		Object.getOwnPropertyNames(this.uploadRefs).forEach((prop) => {
			
			const ref = this.uploadRefs[prop];
			
			if (ref) {
				
				const refCurrent = ref.current;
				
				if (refCurrent) {
					
					const files = refCurrent.files;
					Logger.log(files, 'files', logAllow);
					
					for (let i = 0; i < files.length; i++) {
						let file = files[i];
						formData.append(prop + '[]', file);
					}
					
				}
				
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
		return formData;
		
	};
	
	submit = (event) => {
		
		const logName = 'TrainerQuestionForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		event.preventDefault();
		
		const form = event.currentTarget;
		Logger.log(form, 'form', logAllow);
		
		if (form.checkValidity()) {
			
			let formData = this.getFormData();
			Logger.log(formData, 'formData', logAllow);
			
			let formDataValues = FormDataHelper.getValues(formData);
			Logger.log(formDataValues, 'formDataValues', logAllow);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: this.mode === 'update' ? 'put' : 'post',
				url: Utils.apiUrl('questions' + (this.mode === 'update' ? '/' + this.props.model.id : '')),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				data: formData,
			})
				.then((response) => {
					
					const logName = 'TrainerQuestionForm.submit.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					if (this.props.afterSubmit) {
						this.props.afterSubmit(response, this.mode);
					}
					
					if (this.props.preloader) {
						this.props.preloader.hide();
					}
					
					Logger.groupEnd(logAllow);
					
				}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	uploads = {
		
		del: (uploadBindId) => {
			
			const logName = 'TrainerQuestionForm.images.del';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (!window.confirm(i18next.t("Are you shure?"))) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('upload-binds/' + uploadBindId),
				params: {
					'accessToken': Utils.getUserToken(),
				},
				data: {
					'active': 0,
				},
			}).then((response) => {
				
				const logName = 'TrainerQuestionForm.images.del';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let newUpload = response.data;
				let newModel = this.state.model;
				
				Question.uploadAttrs.forEach((attr) => {
					newModel[attr].forEach((uploadBind, index) => {
						if (uploadBind.id == uploadBindId) {
							newModel[attr][index] = newUpload;
						}
					});
				});
				
				this.setState((prevState) => {
					return {
						model: newModel,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				// todo catch
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		afterUpd: (response, id, data) => {
			
			let newUpload = response.data;
			let newModel = this.state.model;
			
			Question.uploadAttrs.forEach((attr) => {
				newModel[attr].forEach((upload, index) => {
					if (upload.id == id) {
						newModel[attr][index] = newUpload;
					}
				});
			});
			
			this.setState((prevState) => {
				return {
					model: newModel,
				}
			});
			
		}
		
	};
	
	render() {
		
		const logName = 'TrainerQuestionForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const model = this.state.model;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<form className={'TrainerQuestionForm'} onSubmit={this.submit}>
				
				<div className={'form-group material_id required'}>
					<label htmlFor={'material_id'}>{i18next.t("Level")}</label>
					<select
						id="material_id"
						className={[
							'form-control',
						].join(' ')}
						value={model.material_id}
						onChange={this.inputChange}
						required={true}
					>
						<option value=""></option>
						{this.props.materials.map((material, materialIndex) => {
							return (
								<option value={material.id}>{i18next.t("Level")} {materialIndex + 1}</option>
							);
						})}
					</select>
				</div>
				
				<div className={'form-group weight required'}>
					<label htmlFor={'weight'}>{i18next.t("Points")}</label>
					<input
						id={'weight'}
						name={'weight'}
						type="number"
						min={1}
						max={255}
						step={1}
						className={'form-control'}
						value={model.weight}
						onChange={this.inputChange}
						required={true}
					/>
				</div>
				
				<div className={'form-group about required'}>
					
					<label htmlFor={'about'}>{i18next.t("Task text")}</label>
					
					<textarea
						className={[
							'form-control',
						].join(' ')}
						id={'about'}
						name={'about'}
						value={model.about}
						onChange={this.inputChange}
						rows={10}
						// required={true}
					/>
					
					<div>
						<small className={'form-text text-muted'}>
							{i18next.t("To make the input field inside of the text, please type the code like this")} <code>_значение\</code>
							<br/><code>{i18next.t("right answer")}</code> - {i18next.t("the expected content of the input field")}
							<br/>{i18next.t("Count of the underscore symbols regulates the width of the input field")}
							<br/>{i18next.t("Example: input letters")}: с<code>_о\</code>бак<code>_а\</code>
							<br/>{i18next.t("Example: input words")}: мама с <code>___мылом\</code> мыла <code>___раму\</code>
						</small>
					</div>
				
				</div>
				
				<fieldset className={'sounds'}>
					
					<legend>{i18next.t("Audio file")}</legend>
					
					{model && model.sounds.length > 0 && (
						<UploadsGrid
							uploads={model.sounds}
							afterUpd={this.uploads.afterUpd}
							showControls={true}
							preloader={this.props.preloader}
						/>
					)}
					
					<div className="form-group">
						<input
							id={'sounds'}
							type="file"
							accept="audio/*"
							ref={this.uploadRefs['sounds']}
							multiple={false}
						/>
					</div>
				
				</fieldset>
				
				<hr/>
				
				<div className="controls">
					<div className="row">
						<div className="col">
							{this.props.cancel && (
								<button type={'button'} onClick={this.props.cancel} className={'my-btn my-btn-danger'}>
									{i18next.t("Cancel")}
								</button>
							)}
						</div>
						<div className="col text-right">
							<button type={'submit'}
							        className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					</div>
				</div>
			
			</form>
		
		);
	}
	
}

TrainerQuestionForm.propTypes = {
	materials: PropTypes.arrayOf(PropTypes.instanceOf(Material)),
	materialId: PropTypes.any,
	model: PropTypes.instanceOf(Question),
	afterSubmit: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
};

TrainerQuestionForm.defaultProps = {
	materials: [],
};