import "./TeacherHomePage.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import Calendar from "react-calendar";
import Logger from "../../../helpers/Logger";
import axios from "axios";
import Utils from "../../../helpers/Utils";
import Answer from "../../../models/Answer";
import Question from "../../../models/Question";
import QuestionView from "../../common/QuestionView";
import User from "../../../models/User";
import DateHelper from "../../../helpers/DateHelper";
import Sched from "../../../models/Sched";
import SchedsSmallTable from "../../common/SchedsSmallTable";
import * as Icon from 'react-bootstrap-icons';
import {Modal} from "react-bootstrap";
import UserMsgQuestions from "../../common/UserNotAnsweredQuestions";
import PreloaderProgressBar from "../../common/PreloaderProgressBar";

export default class TeacherHomePage extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			notifications: [
				// 'Tomorrow full-time lesson at 13.00',
				// 'The teacher gave grades for the test work “Глагол”',
				// 'The teacher gave grades for the test work “Существительное”',
				// 'Added a new test task on the topic “Знаки препинания”',
			],
			
			checklist: [],
			checklistTotal: null,
			checklistLimit: 10,
			checklistOffset: 0,
			checklistUsePagination: true,
			// checklistUsePagination: false,
			checklistIsLoading: 0,
			
			scheds: [],
			
			currentDate: new Date(),
			
			msgNotAnsweredStudents: [],
			msgCurrentStudent: null,
			
		};
		
	}
	
	// задания для ручной проверки
	checklist = {
		
		load: (limit=this.state.checklistLimit, offset=this.state.checklistOffset) => {
			
			const logName = 'TeacherHomePage.checklist.load';
			const logAllow = 0;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				// this.props.preloader.show();
			}
			
			this.setState((prevState) => {
				return {
					checklistIsLoading: 1,
					checklist: [],
					checklistTotal: 0,
				}
			})
			
			axios({
				method: 'get',
				url: Utils.apiUrl('test-answer-teacher/get-unchecked'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'limit': limit,
					'offset': offset,
				},
			}).then((response) => {
				
				const logName = 'TeacherHomePage.checklist.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let checklistAnswers = [];
				
				if (response.data.answers) {
					
					checklistAnswers = response.data.answers.map((answerData) => {
						return new Answer(answerData);
					});
					
					this.setState((prevState) => {
						return {
							checklist: checklistAnswers,
							checklistTotal: parseInt(response.data.total),
						}
					});
					
				} else {
					
					checklistAnswers = response.data.map((answerData) => {
						return new Answer(answerData);
					});
					
					this.setState((prevState) => {
						return {
							checklist: checklistAnswers,
						}
					});
					
				}
				
				this.setState((prevState) => {
					return {
						checklistIsLoading: 0,
					}
				})
				
				if (this.props.preloader) {
					// this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				Utils.axiosErrorAlert(error);
				
				if (this.props.preloader.hide) {
					// this.props.preloader.hide()
				}
				
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	// расписание
	scheds = {
		
		load: (date) => {
			
			const logName = 'TeacherHomePage.scheds.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const dateFormatted = DateHelper.formatDate({
				date: date,
				format: 'y-m-d',
			});
			Logger.log(dateFormatted, 'dateFormatted', logAllow);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('scheds'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[date]': dateFormatted,
					'sort': 'time',
				},
			}).then((response) => {
				
				const logName = 'TeacherHomePage.scheds.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.log(response, 'response', logAllow);
				
				let scheds = response.data.map(schedData => new Sched(schedData));
				Logger.log(scheds, 'scheds', logAllow);
				
				this.setState((prevState) => {
					return {
						scheds: scheds,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	msg = {
		
		loadNotAnsweredStudents: () => {
			
			if (this.props.preloader.show) {
				this.props.preloader.show()
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('msg/not-answered-students'),
				data: {},
				params: {
					accessToken: Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'TeacherHomePage.msg.loadNotAnsweredUsers'
				const logAllow = 1
				const logCollapse = 0
				
				Logger.groupStart(logName, logAllow, logCollapse)
				
				Logger.log(response, 'response', logAllow)
				
				let students = response.data.map((userData) => {
					return new User(userData)
				})
				
				this.setState({
					msgNotAnsweredStudents: students,
				})
				
				if (this.props.preloader.hide) {
					this.props.preloader.hide()
				}
				
				Logger.groupEnd(logAllow)
				
			}).catch((axiosError) => {
				
				if (this.props.preloader.hide) {
					this.props.preloader.hide()
				}
				
				window.alert(axiosError)
				
			})
			
		}
		
	};
	
	componentDidMount() {
		this.checklist.load();
		this.scheds.load(this.state.currentDate);
		this.msg.loadNotAnsweredStudents();
	}
	
	render() {
		
		const logName = 'TeacherHomePage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		// Logger.log(this.state, 'this.state', logAllow)
		Logger.log(this.state.checklistTotal, 'this.state.checklistTotal', logAllow)
		
		let checklist = this.state.checklist;
		Logger.log(checklist, 'checklist', logAllow, logName);
		
		let checklistMaxTaskNum = this.state.checklistOffset + parseInt(this.state.checklistLimit);
		checklistMaxTaskNum = checklistMaxTaskNum > this.state.checklistTotal ? this.state.checklistTotal : checklistMaxTaskNum;
		
		let checklistControls = (
			
			<div className="checklist-controls my-3">
				
				{/*<button
					className="my-btn"
					onClick={() => {
						
						this.setState((prevState) => {
							return {
								checklistOffset: parseInt(prevState.checklistOffset) - parseInt(this.state.checklistLimit),
							}
						});
						
						this.checklist.load();
						
					}}
					disabled={this.state.checklistOffset == 0}
				>Prev page</button>*/}
				
				{(this.state.checklistTotal > this.state.checklistLimit && this.state.checklistOffset <= this.state.checklistTotal) &&
					<button
						className="my-btn"
						onClick={() => {
							
							let newChecklistOffset = parseInt(this.state.checklistOffset) + parseInt(this.state.checklistLimit);
							
							if (newChecklistOffset > this.state.checklistTotal) {
								newChecklistOffset = 0;
							}
							
							this.setState((prevState) => {
								return {
									checklistOffset: newChecklistOffset,
								}
							});
							
							this.checklist.load(this.state.checklistLimit, newChecklistOffset);
							
						}}
					>{i18next.t('Next page')}</button>
				}
				
				<button
					className={'my-btn my-bt-sm'}
					onClick={() => {
						
						let newChecklistOffset = 0;
						
						this.setState((prevState) => {
							return {
								checklistOffset: newChecklistOffset,
							}
						});
						
						this.checklist.load(this.state.checklistLimit, newChecklistOffset);
						
					}}
				><Icon.ArrowCounterclockwise/></button>
				
			</div>
			
		);
		
		let msgCurrentStudent = this.state.msgCurrentStudent;
		
		let checklistHasPages = this.state.checklistTotal > this.state.checklistLimit;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'teacher-home-page'}>
				
				<div className="row">
					
					<div className="col-xl-8 col-lg-7 col-md-6">
						
						<div className={`section-set`}>
							
							<div className={`row`}>
								
								<div className={`col`}>
									
									<section className={`msg`}>
								
										<h2>
											{i18next.t("New messages")}&nbsp;
											<button
												className={'my-btn my-bt-sm reload-btn msg-reload-btn'}
												onClick={this.msg.loadNotAnsweredStudents}
											><Icon.ArrowCounterclockwise/></button>
										</h2>
										
										<div className={`list`}>
											
											{(this.state.msgNotAnsweredStudents.length > 0) ? (
												<>
													{this.state.msgNotAnsweredStudents.map((student) => {
														return (
															<div 
																className={`list-item list-item-active`} 
																onClick={(event) => {
																	this.setState({
																		msgCurrentStudent: student,
																	})
																}}
																data-debug={`#${student.id}`}
															>
																<span className={`red-dot mr-2`}></span> {student.getDisplayName()}
															</div>
														)
													})}
												</>
											) : (
												<>
													<div className="list-item">
														{i18next.t("No messages yet")}
													</div>
												</>
											)}
											
										</div>
										
									</section>
									
								</div>
								
								<div className={`col-xl-4`}>
								
									<section className="notifications">
								
										<h2>{i18next.t("Notifications")}</h2>
										
										<div className={`list`}>
											
											{this.state.notifications.length > 0 ? (
												
												this.state.notifications.map((text) => {
													return (
														<div className="list-item list-item-active notification">
															{text}
														</div>
													);
												})
											
											) : (
												
												<div className="list-item">
													{i18next.t("No notifications yet")}
												</div>
											
											)}
										
										</div>
										
									</section>
								
								</div>
								
							</div>
							
						</div>
						
						<section id="checklist" className="checklist teacher-checklist-view">
							
							{(this.state.checklistTotal) ? (
								
								<>
									
									{(this.state.checklistUsePagination) ? (
										
										<>
											
											<h2>
												
												{i18next.t("Tasks to check")}&nbsp;
												
												{(checklistHasPages) ? (
													<>
														({this.state.checklistOffset > 0 ? this.state.checklistOffset + 1 : 1} - {checklistMaxTaskNum} / {this.state.checklistTotal})
													</>
												) : (
													<>
														({this.state.checklistTotal})
													</>
												)}
												
											</h2>
											
											{checklistControls}
											
										</>
									
									) : (
										
										<>
											
											<h2>
												{i18next.t("Tasks to check")} ({this.state.checklist.length} / {this.state.checklistTotal})
												<button className={'my-btn my-bt-sm'} onClick={() => {this.checklist.load()}}>
													<Icon.ArrowCounterclockwise/>
												</button>
											</h2>
										
										</>
									
									)}
									
								</>
								
							) : (
								
								<h2>
									{i18next.t("Tasks to check")}&nbsp;
									<button className={'my-btn my-bt-sm'} onClick={() => {this.checklist.load()}}>
										<Icon.ArrowCounterclockwise/>
									</button>
								</h2>
								
							)}
							
							{(Boolean(this.state.checklistIsLoading)) && (
								<div className={`checklist-preloader-box my-4`}>
									<PreloaderProgressBar/>
								</div>
							)}
							
							{this.state.checklist.length > 0 ? (
								
								this.state.checklist.map((checklistItem, checklistItemIndex) => {
									
									const logName = 'TeacherHomePage.render.checklist.map';
									const logAllow = 0;
									const logCollapsed = 0;
									
									Logger.groupStart(logName, logAllow, logCollapsed);
									
									const answer = checklistItem;
									Logger.log(answer, 'answer', logAllow);
									
									const path = answer.path;
									Logger.log(path, 'path', logAllow);

									const questionData = answer.question_data;
									Logger.log(questionData, 'questionData', logAllow);
									
									const question = new Question(questionData);
									Logger.log(question, 'question', logAllow);
									
									Logger.groupEnd(logAllow);
									
									return (
										
										<div className="list-item question-box" key={'uncheked-answer_' + answer.id}>
											
											<QuestionView
												key={`checklist-question-${question.id}`}
												question={question}
												user={this.props.user}
												answer={answer}
												answers={[answer]}
												course={path.course}
												lesson={path.lesson}
												theme={path.theme}
												mode={'check'}
												preloader={this.props.preloader}
												afterCheck={(isRight, response) => {
													
													const logName = 'TeacherHomePage.afterCheck';
													const logAllow = 1;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													let checklist = this.state.checklist;
													checklist.splice(checklistItemIndex, 1);
													
													this.setState((prevState) => {
														return {
															checklist: checklist,
														}
													});
													
													Logger.groupEnd(logAllow);
													
												}}
											/>
											
											{/*<div className="locker">
												<button
													type={'button'}
													className={[
														'unlock-btn',
														'my-btn',
														'my-btn-success',
														// 'btn-primary',
													].join(' ')}
													onClick={(event) => {
														// todo
													}}
													// disabled={true}
												>{i18next.t("Начать проверку")}</button>
											</div>*/}
											
										</div>
										
									);
									
								})
							
							) : (
								
								<>
									{(!Boolean(this.state.checklistIsLoading)) && (
										<div className={'list'}>
											<div className={'list-item'}>
												{i18next.t("No tasks yet")}
											</div>
										</div>
									)}
								</>
								
							)}
							
							{(checklistHasPages) && (
								<>{checklistControls}</>
							)}
						
						</section>
					
					</div>
					
					<div className="col-xl-4 col-lg-5 col-md-6">
						
						<section className="calendar-box">
							
							<div className="h2">{i18next.t("Schedule")}</div>
							
							<div className="list">
								<Calendar
									value={this.state.currentDate}
									onChange={(date) => {
										this.setState((prevState) => {
											return {
												currentDate: date,
											}
										});
										this.scheds.load(date);
									}}
								/>
							</div>
						
						</section>
						
						<section className="sched">
							
							<div className="list scheds-list">
								
								{this.state.scheds.length > 0 ? (
									
									<SchedsSmallTable
										scheds={this.state.scheds}
									/>
								
								) : (
									
									<div className={'list-item'}>{i18next.t("No schedule for selected date")}</div>
								
								)}
							
							</div>
						
						</section>
					
					</div>
				
				</div>
				
				<div className={`modals`}>
					
					{(msgCurrentStudent) && (
						
						<>
						
							<Modal
								className={[
									'msg-modal',
									// 'wide-modal',
								].join(' ')}
								show={this.state.msgCurrentStudent}
								onHide={() => {
									this.setState({
										msgCurrentStudent: null,
									})
								}}
								size={'lg'}
								// keyboard={false}
								// backdrop={'static'}
							>
								
								<Modal.Header closeButton>
									<Modal.Title>
										{/* <span className={`red-dot`}></span> &nbsp; */}
										{i18next.t('New messages')} | {msgCurrentStudent.getDisplayName()}
									</Modal.Title>
								</Modal.Header>
								
								<Modal.Body>
									
									<UserMsgQuestions
										preloader={this.props.preloader}
										targetUserId={msgCurrentStudent.id}
										user={this.props.user}
									/>
									
								</Modal.Body>
							
							</Modal>
						
						</>
						
					)}
					
				</div>
			
			</div>
		
		);
	}
	
}

TeacherHomePage.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	breadcrumbs: PropTypes.object,
	preloader: PropTypes.object,
};

TeacherHomePage.defaultProps = {};