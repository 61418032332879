import './LandingPageOldLMA.css'

import { useState } from 'react'

import arrow from'../../../img/icons/arrow.svg'
import media_player from '../../../img/media-player.png'
import feedback_img from '../../../img/feedback-img.png'
import demopc from '../../../img/DemoPC1.png'
import hand_with_mail from '../../../img/hand-with-mail.svg'
import lexrus_main_screen from '../../../img/lexrus-main-screen.png'
import lexrus_right_screen from '../../../img/lexrus-right-out.png'
import lexrus_left_screen from '../../../img/screen-left-out.png'
import meetup from '../../../img/meeting.png'
import user_flow from '../../../img/user-flow.png'
import focus_mode from '../../../img/focus-mode.png'
import buisness_plan from '../../../img/buisness-plan.png'
import back_to_school from '../../../img/back-to-school.png'
import yellow_dots from '../../../img/yellow-dots-background.svg'
import purple_dots from '../../../img/purple-dots-background.svg'
import purple_blob from '../../../img/purpleblob.svg'

// import { SystemFeatures } from './SystemFeatures';
// import { Background } from './Background';

export default function LandingPage({
	appUserManager,
	showDemoBtn = 0,
}){
	
	const [login, setLogin] = useState()
	const [psw, setPsw] = useState()
	
	return (

		<>
			
			<div className="landing-page">
				
				<div className="row auth-menu">
					
					<div className="col main-pic">
						<div className="yellow-blob"/>
						<img src = {media_player}/>
					</div>
					
					<div className="col-4 full-screen">
						
						<div className = "info">
							
							<div className="text-block">
								<div className="label-main fs-1 fw-800 text-uppercase">
									STUDERUS
								</div>
								<hr className="line mb-0"/>
								<div className="main-text fs-5 text-uppercase mb-5">
									КОМПЛЕКС ИНТЕРАКТИВНОГО ОНЛАЙН-ОБУЧЕНИЯ И АДАПТАЦИИ К УЧЕБНОЙ СРЕДЕ ДЛЯ ИНОСТРАННЫХ ОБУЧАЮЩИХСЯ 
								</div>
							</div>

							<form
								className="input-block"
								onSubmit={(event) => {
									event.preventDefault()
									appUserManager?.login({
										login: login,
										password: psw,
									})
								}}
							>
								
								<input
									type = "text"
									className="form-control mb-3 p-4"
									placeholder="E-mail"
									onChange={(event) => {
										setLogin(event.target.value)
									}}
									required
								/>
								
								<input
									type = "password"
									className="form-control mb-4 p-4"
									placeholder='Пароль'
									onChange={(event) => {
										setPsw(event.target.value)
									}}
									required
								/>
								
								<div className={`controls`}>
									
									{(Boolean(showDemoBtn)) && (
										<>
											<button
												className="btn btn-primary fs-6 d-inline-block mr-3 demo-auth-btn"
												type="button"
												onClick={(event) => {
													appUserManager?.startDemo()
												}}
											>
												Демо
											</button>
										</>
									)}
									
									
									<button
										className="btn btn-primary fs-6 d-inline-block"
										type="submit"
									>
										Войти
										<img className = "pl-4" src={arrow}/>
									</button>
									
								</div>
								
							</form>
							
						</div>

					</div>
					
				</div>
				
				<div className="row pb-5">
					<div className="col intern d-flex justify-content-center align-items-center">
						<div className = "text-block pr-5">
							<div className="label-main fs-2 font-weight-bold pb-2">
								Интернационализация
							</div>
							<div className={"main-text fs-5 font-weight-normal"}>
								Университет стремится стать международным центром образования, приглашая зарубежных абитуриентов.
								Чтобы их обучение проходило комфортно и эффективно, особое внимание уделяется адаптации и интеграции студентов, что способствует повышению уровня  интернационализации университета.
							</div>
						</div>
					</div>
					<div className="col-4 intern-pic">
						<img src={feedback_img}/>
					</div>
				</div>
				
				<div className="demopc">
					<div className="row">
						<div className="col d-flex flex-center justify-content-center align-items-center">
							<img src={demopc}/>
						</div>
						<div className="col-4">

							<div className="text-block purple-background mb-4">
								<div className="label-main fs-3 font-weight-bold">
									Организация
								</div>
								<hr className="line inverse"/>
								<div className="main-text fs-6 font-weight-light">
									Организация эффективного очного и гибридного обучения по предметным профилям и русскому языку для иностранных обучающихся
								</div>
							</div>

							<div className="text-block purple-background mb-4">
								<div className="label-main fs-3 font-weight-bold">
									Координация
								</div>
								<hr className="line inverse"/>
								<div className="main-text fs-6 font-weight-light">
									Координация обучения иностранных студентов на протяжении всего периода обучения
								</div>
							</div>

							<div className="text-block purple-background">
								<div className="label-main fs-3 font-weight-bold inverse">
									Поддержка
								</div>
								<hr className="line inverse"/>
								<div className="main-text fs-6 font-weight-light">
									Поддержка эффективного удаленного обучения, в том числе на площадках подготовительных факультетов других республик и стран
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="icon-text-column">
					<div className="row d-flex align-items-center">

						<div className="vertical-rectangle left"/>
						<div className="col">
							<div className ="icon-text d-flex flex-column align-items-center justify-content-center pb-5">

								<div className = "icon-with-background">
									<img src ={hand_with_mail}/>
								</div>

								<div className="text-block">

									<div className="main-text">
										Предоставление альтернативы сторонних площадок и сервисов
										для проведения образовательного процесса, обладающей более широким функционалом
									</div>

								</div>
							</div>
							<div className ="icon-text d-flex flex-column align-items-center justify-content-center">

								<div className = "icon-with-background">
									<img src ={hand_with_mail}/>
								</div>

								<div className="text-block">
									<div className="main-text">
										Обеспечение обучения в различных предметных областях, включая естественные науки, чтобы каждый студент мог выбрать именно то направление, которое его интересует
									</div>
								</div>

							</div>
						</div>
						
						<div className="vertical-rectangle middle"/>

						<div className="col">
							<div className ="icon-text d-flex flex-column align-items-center justify-content-center pb-5">

								<div className = "icon-with-background">
									<img src ={hand_with_mail}/>
								</div>

								<div className="text-block">
									<div className="main-text">
										Предоставление альтернативы сторонних площадок и сервисов
										для проведения образовательного процесса, обладающей более широким функционалом
									</div>
								</div>

							</div>

							<div className ="icon-text d-flex flex-column align-items-center justify-content-center">
								<div className = "icon-with-background">
									<img src ={hand_with_mail}/>
								</div>
								<div className="text-block">
									<div className="main-text">
										Обеспечение обучения в различных предметных областях, включая естественные науки, чтобы каждый студент мог выбрать именно то направление, которое его интересует
									</div>
								</div>
							</div>
						</div>

						<div className="vertical-rectangle right"/>

					</div>
				</div>

				<div className="system-features">
					
					<div className="row d-flex justify-content-center align-items-center pb-5">
						<div className="text-block fs-1 font-weight-bold">
							<div className="label-main fw-800">
								Возможности платформы
							</div>
						</div>
					</div>

					<div className="three-pics-gallery pb-5">
						<div className="row d-flex justify-content-center align-items-center">
								<img className="left"src={lexrus_left_screen}/>
								<img className="main"src={lexrus_main_screen}/>
								<img className="right"src={lexrus_right_screen}/>
						</div>
					</div>
					
					<div className="features-columns">

						{/* <div className="yellow-bg-dots"/> */}
						{/* <div className="purple-bg-dots"/> */}

						<div className="feature-element">
							<img src={buisness_plan} alt ="Buisness Plan"/>
							<div className="main-text fs-4">
								Автоматизированные проверка выполнения заданий и учет успеваемости студента
							</div>
						</div>

						<div className="feature-element">
							<img src={user_flow} alt ="User Flow"/>
							<div className="main-text fs-4">
								Формирование цифровых образовательных ресурсов с использованием встроенного редактора для формирования учебных материалов
							</div>
						</div>

						<div className="feature-element">
							<img src={focus_mode} alt ="Focus Mode"/>
							<div className="main-text fs-4">
								Динамическая адаптация домашних заданий, зависящая от успеваемости учебной группы
							</div>
						</div>

						<div className="feature-element">
							<img src={meetup} alt ="Meetup"/>
							<div className="main-text fs-4">
								Автоматизированное проведение контрольных работ с возможностью отслеживания результатов для преподавателя
							</div>
						</div>

						<div className="feature-element">
							<img src={back_to_school} alt ="Back to School"/>
							<div className="main-text fs-4">
								Адаптация учебных материалов и возможность предиктивной аналитики благодаря базе данных, содержащей информацию о процессе обучения студентов
							</div>
						</div>
						
					</div>
					
				</div>
				
			</div>

			{/*
			<div className = "landing-page-footer">
				<div className="purple-blob"></div>
				<div className="footer-body"></div>
			</div>
			*/}
			
			<br/>
			<br/>
			
		</>
	
	)
}