import "./main.css";
import "./main_xs.css";
import "./skin/light.css";
import "./skin/dark.css";
import "./skin/purple.css";

import React, {Component} from "react";
import {Link, Route, Switch} from "react-router-dom";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

import Config from "./helpers/Config";
import Logger from "./helpers/Logger";
import Utils from "./helpers/Utils";
import User from "./models/User";

import BootstrapBreakpointIndicator from "./components/common/BootstrapBreakpointIndicator";
import AdminHomePage from "./components/roles/admin/AdminHomePage";
import Preloader from "./components/common/preloader/Preloader";
import CalendarPage from "./components/common/CalendarPage";
import TeamsPage from "./components/roles/admin/TeamsPage";
import StudentHomePage from "./components/roles/student/StudentHomePage";
import TeacherHomePage from "./components/roles/teacher/TeacherHomePage";
import CoursesPage from "./components/common/CoursesPage";
import StudentsPage from "./components/common/StudentsPage";
import Aside from "./components/common/Aside";
import LandingPageOldLMA from "./components/common/LandingPage/LandingPageOldLMA";
import AlertModal from "./components/common/AlertModal";
import UserMenuBox from "./components/common/UserMenuBox";
import TrainersPage from "./components/common/TrainersPage";
import MobileMenu from "./components/common/MobileMenu";
import ChatPage from "./components/common/ChatPage";
import {Modal} from "react-bootstrap";

import iconLangEn from "./img/eng.svg";
import iconLangRu from "./img/rus.svg";
import menuBtn from "./img/main-menu-btn.svg";
import closeBtnIcon from "./img/exit-black.svg";
import kfuLogoSmall from "./img/kfu-logo-small.svg";
import homeIcon from "./img/home.svg";
import lessonsIcon from "./img/iconLessons.png";
import trainerIcon from "./img/puzzle-black.svg";
import studentsIcon from "./img/students.svg";
import chatIcon from "./img/chat.svg";
import calendarIcon from "./img/calendar.svg";
import infoIcon from "./img/info.svg";
import SkinTestPage from "./components/common/SkinTestPage";
import EditorsPage from "./components/common/EditorsPage";
import QuestionPage from "./components/common/QuestionPage";
import AnkaraLoginPage from "./components/institutes/ankara/AnkaraLoginPage";
import AnkaraAside from "./components/institutes/ankara/AnkaraAside";
import StudentForm from "./components/common/StudentForm";
import MaterialPage from "./components/common/MaterialPage";
import StudentViewPage from "./components/common/StudentViewPage";
import UserMaterialPage from "./components/common/UserMaterialPage";
import QuestionManager from "./components/common/QuestionManager";
import VideoLinkHelper from "./helpers/VideoLinkHelper";
import SberGigaChatModal from "./components/sber-giga-chat/SberGigaChatModal";
import LoginPage from "./components/common/LoginPage";

class App extends Component {
	
	constructor(props) {
		
		super(props);
		
		const logName = 'App.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const defaultLangAlias = Config.defaultLangAlias;
		Logger.log(defaultLangAlias, 'defaultLangAlias', logAllow);
		
		const storedLangAlias = localStorage.getItem('langAlias');
		Logger.log(storedLangAlias, 'storedLangAlias', logAllow);
		
		const storedLang = this.langs[storedLangAlias];
		Logger.log(storedLang, 'storedLang', logAllow);
		
		const browserLangAlias = navigator.language;
		Logger.log(browserLangAlias, 'browserLangAlias', logAllow);
		
		const browserLang = this.langs[browserLangAlias];
		Logger.log(browserLang, 'browserLang', logAllow);
		
		const currentLangAlias = storedLangAlias
			? storedLang
				? storedLangAlias
				: defaultLangAlias
			: browserLang
				? browserLangAlias
				: defaultLangAlias
		;
		Logger.log(currentLangAlias, 'currentLangAlias', logAllow);
		
		Logger.log(i18next.language, 'i18next.language [before]', logAllow);
		
		this.changeLang(currentLangAlias);
		
		Logger.log(i18next.language, 'i18next.language [after]', logAllow);
		
		const defaultSkinAlias = 'light';
		const storedSkinAlias = localStorage.getItem('skinAlias');
		const currentSkinAlias = storedSkinAlias ? storedSkinAlias : defaultSkinAlias;
		
		this.state = {
			
			user: null,
			preloaderIsShown: false,
			// lang: 'en',
			
			testPass: null,
			testPassModalIsOpen: false,
			editProfileFormModalIsOpen: false,
			breadcrumbs: [
				// {
				//     'url': 'https://google.com',
				//     'name': 'Google',
				// },
				// {
				//     'url': 'https://ya.ru',
				//     'name': 'Yandex',
				// },
			],
			
			// menuIsOpen: false,
			
			currentMenuItemAlias: 'home',
			
			alertModalIsOpen: false,
			alertTitle: null,
			alertBody: null,
			alertAfterConfirm: null,
			alertAfterCancel: null,
			alertUseCancel: null,
			alertConfirmText: i18next.t("Proceed"),
			alertCancelText: i18next.t("Cancel"),
			
			// mobileMenuShown: true,
			mobileMenuShown: false,
			
			currentSkinAlias: currentSkinAlias,
			
			// showBreakpointIndicator: Utils.isDev(),
			showBreakpointIndicator: false,
			
			// useAlertModal: !Utils.isLoc(),
			useAlertModal: true,
			
			sberGigaChatModalIsOpen: 0,
			
		};
		
		Logger.groupEnd(logAllow);
		
	}
	
	alert = {
		
		show: (
			title,
			body,
			useCancel,
			afterConfirm,
			afterCancel,
			confirmText = i18next.t("Proceed"),
			cancelText = i18next.t("Cancel")
		) => {
			
			if (this.state.useAlertModal) {
				
				this.setState((prevState) => {
					return {
						alertModalIsOpen: true,
						alertAfterConfirm: afterConfirm,
						alertAfterCancel: afterCancel,
						alertTitle: title,
						alertBody: body,
						alertUseCancel: useCancel,
						alertConfirmText: confirmText,
						alertCancelText: cancelText,
					}
				})
				
			} else {
				
				window.alert(title)
				
			}
			
		},
		
		confirm: () => {
			
			this.setState((prevState) => {
				return {
					alertModalIsOpen: false,
				}
			});
			
			if (this.state.alertAfterConfirm) {
				this.state.alertAfterConfirm();
			}
			
		},
		
		cancel: () => {
			
			this.setState((prevState) => {
				return {
					alertModalIsOpen: false,
				}
			});
			
			if (this.state.alertAfterCancel) {
				this.state.alertAfterCancel();
			}
			
		},
		
	};
	
	preloader = {
		
		show: () => {
			
			const logName = 'App.preloader.show';
			const logAllow = 0;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			this.setState((prevState) => {
				return {
					preloaderIsShown: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		hide: () => {
			
			const logName = 'App.preloader.hide';
			const logAllow = 0;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			this.setState((prevState) => {
				return {
					preloaderIsShown: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	// todo move login, logout etc into structure
	user = {
		
		login: (data) => {
			
			const logName = 'App.user.login';
			const logAllow = 1;
			const logCollapsed = 1;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(data, 'data', logAllow);
			
			this.preloader.show();
			
			fetch(Config.backendUrl + '/api/auth2/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				
				.then(response => {
					
					const logName = 'App.login.fetch.then';
					const logAllow = 0;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					if (response.ok) {
						
						Logger.groupEnd(logAllow);
						
						return response.json();
						
					} else {
						
						Logger.groupEnd(logAllow);
						
						throw new Error(response.statusText);
						
					}
					
				})
				
				.then(json => {
					
					const logName = 'App.login.ajax.done';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					this.preloader.hide();
					
					Logger.log(json, 'json', logAllow);
					
					if (json.user_token && json.user_data) {
						
						localStorage.setItem('user_token', json.user_token);
						
						const user = new User(json.user_data);
						Logger.log(user, 'user', logAllow);
						
						this.setState({
							user: user,
						});
						
					}
					
					Logger.log(this.state, 'this.state', logAllow);
					
					Logger.groupEnd(logAllow);
					
				})
				
				.catch((err) => {
					this.preloader.hide();
					alert(err);
				});
			
			Logger.groupEnd(logAllow);
			
		},
		
		load: () => {
			
			const logName = 'App.user.load';
			const logAllow = 1;
			const logCollapsed = 1;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			const token = Utils.getUserToken();
			Logger.log(token, 'token', logAllow);
			
			if (token) {
				
				fetch(Config.backendUrl + '/api/auth2/get', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						'token': token,
					}),
				})
					
					.then(response => {
						
						const logName = 'App.loadSignedUser.fetch.then';
						const logAllow = 1;
						const logCollapsed = 1;
						
						Logger.groupStart(logName, logAllow, logCollapsed);
						
						Logger.log(response, 'response', logAllow);
						
						Logger.groupEnd(logAllow);
						
						if (response.ok) {
							return response.json();
						} else {
							// this.hidePreloader();
							throw new Error(response.statusText);
						}
						
					})
					
					.then(json => {
						
						const logName = 'App.loadSignedUser.fetch.then.then';
						const logAllow = 1;
						const logCollapsed = 0;
						
						Logger.groupStart(logName, logAllow, logCollapsed);
						
						Logger.log(json, 'json', logAllow);
						
						if (json.user_data) {
							
							let user = new User(json.user_data);
							
							this.setState({
								user: user,
							});
							
						}
						
						Logger.groupEnd(logAllow);
						
					})
					
					.catch(err => {
						
						const logName = 'App.loadSignedUser.fetch.catch';
						const logAllow = 1;
						const logCollapsed = 0;
						
						Logger.groupStart(logName, logAllow, logCollapsed);
						
						Logger.log(err, 'err', logAllow);
						
						this.logout();
						
						Logger.groupEnd(logAllow);
						
					});
				
			}
			
			Logger.groupEnd(logAllow);
			
		},
		
		logout: () => {
			
			localStorage.removeItem('user_token');
			
			this.setState({
				user: null,
			});
			
		},
		
		startDemo: () => {
			this.user.login({
				demo: 1,
			})
		}
		
	};
	
	login = (data) => {
		
		const logName = 'App.login';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(data, 'data', logAllow);
		
		this.preloader.show();
		
		fetch(Config.backendUrl + '/api/auth2/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			
			.then(response => {
				
				const logName = 'App.login.fetch.then';
				const logAllow = 0;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (response.ok) {
					
					window.open('/', '_self')
					
					Logger.groupEnd(logAllow);
					
					return response.json();
					
				} else {
					
					Logger.groupEnd(logAllow);
					
					throw new Error(response.statusText);
					
				}
				
			})
			
			.then(json => {
				
				const logName = 'App.login.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				this.preloader.hide();
				
				Logger.log(json, 'json', logAllow);
				
				if (json.user_token && json.user_data) {
					
					localStorage.setItem('user_token', json.user_token);
					
					const user = new User(json.user_data);
					Logger.log(user, 'user', logAllow);
					
					this.setState({
						user: user,
					});
					
					Utils.setTitle(null, user);
					
				}
				
				Logger.log(this.state, 'this.state', logAllow);
				
				Logger.groupEnd(logAllow);
				
			})
			
			.catch((fetchError) => {
				
				const logName = 'App.login.ajax.err';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				this.preloader.hide();
				
				let msg = fetchError.message || "Invalid login or password";
				
				Logger.log(msg, 'msg', logAllow);
				
				this.alert.show(i18next.t(msg));
				
				Logger.groupEnd(logAllow);
				
			});
		
		Logger.groupEnd(logAllow);
		
	};
	
	logout = () => {
		
		localStorage.removeItem('user_token');
		
		this.setState({
			user: null,
		});
		
		Utils.setTitle(null, null);
		
		window.open('/', '_self')
		
	};
	
	loadSignedUser = () => {
		
		const logName = 'App.loadSignedUser';
		const logAllow = 1;
		const logCollapsed = 1;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const token = Utils.getUserToken();
		Logger.log(token, 'token', logAllow);
		
		if (token) {
			
			this.preloader.show();
			
			fetch(Config.backendUrl + '/api/auth2/get', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					'token': token,
				}),
			})
				.then(response => {
					
					const logName = 'App.loadSignedUser.fetch.then';
					const logAllow = 1;
					const logCollapsed = 1;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					Logger.log(response, 'response', logAllow);
					
					Logger.groupEnd(logAllow);
					
					if (response.ok) {
						Utils.setTitle(null, this.state.user);
						return response.json();
					} else {
						// this.hidePreloader();
						throw new Error(response.statusText);
					}
					
				})
				
				.then(json => {
					
					const logName = 'App.loadSignedUser.fetch.then.then';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					this.preloader.hide();
					
					Logger.log(json, 'json', logAllow);
					
					if (json.user_data) {
						
						let user = new User(json.user_data);
						
						this.setState({
							user: user,
						});
						
					}
					
					Utils.setTitle(null, this.state.user);
					
					Logger.groupEnd(logAllow);
					
				})
				
				.catch(err => {
					
					const logName = 'App.loadSignedUser.fetch.catch';
					const logAllow = 1;
					const logCollapsed = 0;
					
					Logger.groupStart(logName, logAllow, logCollapsed);
					
					this.preloader.hide();
					
					Logger.log(err, 'err', logAllow);
					
					this.logout();
					
					Logger.groupEnd(logAllow);
					
				});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	changeLang = (langAlias) => {
		i18next.changeLanguage(langAlias);
		localStorage.setItem('langAlias', langAlias);
	};
	
	getCurrentLangAlias = () => {
		return i18next.language;
	};
	
	breadcrumbs = {
		
		push: (data) => {
			
			let allow = true;
			
			this.state.breadcrumbs.forEach((breadcrumb) => {
				if (breadcrumb.url == data.url) {
					allow = false;
				}
			});
			
			if (allow) {
				
				let breadcrumbs = this.state.breadcrumbs;
				breadcrumbs.push(data);
				
				this.setState((prevState) => {
					return {
						breadcrumbs: breadcrumbs,
					}
				});
				
			}
			
		},
		
		pop: () => {
			
			// delete last breadcrumb
			
			let breadcrumbs = this.state.breadcrumbs;
			breadcrumbs.pop();
			
			this.setState((prevState) => {
				return {
					breadcrumbs: breadcrumbs,
				}
			});
			
		},
		
		set: (data) => {
			this.setState((prevState) => {
				return {
					breadcrumbs: data,
				}
			});
		},
		
		wipe: () => {
			this.setState((prevState) => {
				return {
					breadcrumbs: [],
				}
			});
		}
		
	};
	
	testPass = {
		
		modalToggle: () => {
			this.setState((prevState) => {
				return {
					testPassModalIsOpen: !prevState.testPassModalIsOpen,
				}
			});
		},
		
	};
	
	menuItems = {
		
		'owner': [
			{
				alias: 'home',
				url: '/',
				name: i18next.t("Account"),
				icon: homeIcon,
				onClick: () => {
					this.breadcrumbs.wipe();
				},
			},
			{
				alias: 'courses',
				name: i18next.t("Courses"),
				icon: lessonsIcon,
			},
			{
				alias: 'trainers',
				// name: i18next.t("Trainers"),
				name: 'Studerus Game',
				badgeText: 'New',
				icon: trainerIcon,
			},
			{
				alias: 'sber-giga-chat',
				name: i18next.t("AI Chat"),
				badgeText: 'New',
				icon: chatIcon,
				url: null,
				onClick: (event) => {
					event.preventDefault()
					this.sberGigaChat.show()
				},
			},
			// {
			//     alias: 'teams',
			//     name: 'Microsoft Teams',
			//     // url: Utils.TEAMS_URL,
			//     // external: 1,
			// },
			{
				alias: 'students',
				name: i18next.t("Students"),
				icon: studentsIcon,
			},
			{
				alias: 'calendar',
				name: i18next.t("Schedule"),
				icon: calendarIcon,
			},
			// {
			// 	alias: 'chat',
			// 	name: i18next.t("Chat"),
			// 	icon: chatIcon,
			// }
			// {
			//     alias: 'settings',
			//     name: 'Аккаунт',
			// },
		],
		
		'admin': [
			{
				alias: 'home',
				url: '/',
				onClick: () => {
					this.breadcrumbs.wipe();
				},
				name: i18next.t("Account"),
			},
			{
				alias: 'courses',
				name: i18next.t("Courses"),
			},
			{
				alias: 'trainers',
				name: i18next.t("Trainers"),
				badgeText: 'New'
			},
			{
				alias: 'sber-giga-chat',
				name: i18next.t("AI Chat"),
				badgeText: 'New',
				onClick: (event) => {
					event.preventDefault()
					this.sberGigaChat.show()
				},
			},
			// {
			//     alias: 'teams',
			//     name: 'Microsoft Teams',
			//     // url: Utils.TEAMS_URL,
			//     // external: 1,
			// },
			{
				alias: 'students',
				name: i18next.t("Students"),
			},
			{
				alias: 'calendar',
				name: i18next.t("Schedule"),
			},
			// {
			//     alias: 'settings',
			//     name: 'Аккаунт',
			// },
		],
		
		'teacher': [
			{
				alias: 'home',
				url: '/',
				onClick: () => {
					this.breadcrumbs.wipe();
				},
				name: i18next.t("Account")
			},
			{
				alias: 'courses',
				name: i18next.t("Courses"),
			},
			{
				alias: 'trainers',
				name: i18next.t("Trainers"),
				badgeText: 'New'
			},
			{
				alias: 'sber-giga-chat',
				name: i18next.t("AI Chat"),
				badgeText: 'New',
				onClick: (event) => {
					event.preventDefault()
					this.sberGigaChat.show()
				},
			},
			// {
			//     alias: 'teams',
			//     name: 'Microsoft Teams',
			//     // url: Utils.TEAMS_URL,
			//     // external: 1,
			// },
			{
				alias: 'students',
				name: i18next.t("Students"),
			},
			{
				alias: 'calendar',
				name: i18next.t("Schedule"),
			},
			// {
			//     alias: 'settings',
			//     name: 'Аккаунт',
			// },
		],
		
		'student': [
			{
				alias: 'home',
				url: '/',
				onClick: () => {
					this.breadcrumbs.wipe();
				},
				name: i18next.t("Account")
			},
			{
				alias: 'courses',
				name: i18next.t("Courses"),
			},
			{
				alias: 'trainers',
				name: i18next.t("Trainers"),
				badgeText: 'New'
			},
			{
				alias: 'sber-giga-chat',
				name: i18next.t("AI Chat"),
				badgeText: 'New',
				onClick: (event) => {
					event.preventDefault()
					this.sberGigaChat.show()
				},
			},
			// {
			//     alias: 'teams',
			//     name: 'Microsoft Teams',
			// },
			// {
			//     alias: 'settings',
			//     name: i18next.t("Аккаунт"),
			// },
		],
		
	};
	
	langs = {
		'en-US': {
			name: 'English',
			img: iconLangEn,
			code: 'en-US',
		},
		'ru-RU': {
			name: 'Русский',
			img: iconLangRu,
			code: 'ru-RU',
		},
	};
	
	mobileMenu = {
		
		toggle: () => {
			this.setState((prevState) => {
				return {
					mobileMenuShown: !prevState.mobileMenuShown,
				}
			});
		},
		
	};
	
	skins = {
		
		list: [
			{
				alias: 'light',
				name: 'Light',
			},
			{
				alias: 'dark',
				name: 'Dark',
			},
			{
				alias: 'purple',
				name: 'Purple',
			},
		],
		
		set: (alias) => {
			
			this.setState((prevState) => {
				return {
					currentSkinAlias: alias,
				}
			});
			
			localStorage.setItem('skinAlias', alias);
			
		},
		
	};
	
	videoLinkHelperTest = (videoSourceId = 'youku') => {
		
		const logName = 'App.videoLInkHelperTest'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let videoSource = VideoLinkHelper.sources[videoSourceId]
		Logger.log(videoSource, 'videoSource', logAllow)
		
		let sampleVideoUrl = videoSource.sampleVideoUrl
		Logger.log(sampleVideoUrl, 'sampleVideoUrl', logAllow)
		
		let sampleVideoId = videoSource.sampleVideoId
		Logger.log(sampleVideoId, 'sampleVideoId', logAllow)
		
		let videoId = videoSource.extractVideoId(sampleVideoUrl)
		Logger.log(videoId, 'videoId', logAllow)
		
		let testIsOk = videoId == sampleVideoId
		Logger.log(testIsOk, 'testIsOk', logAllow)
		
		Logger.groupEnd(logAllow)
		
	}
	
	sberGigaChat = {
		
		show: () => {
			this.setState((prevState) => {
				return {
					sberGigaChatModalIsOpen: 1,
				}
			})
		},
		
		hide: () => {
			this.setState((prevState) => {
				return {
					sberGigaChatModalIsOpen: 0,
				}
			})
		}
		
	}
	
	componentDidMount() {
		
		Utils.setTitle(null, this.state.user);
		
		let userToken = Utils.getUserToken();
		
		if (!this.state.user && userToken) {
			this.loadSignedUser();
		}
		
		// if (Utils.isLoc()) {
		// 	this.videoLinkHelperTest()
		// }
		
	}
	
	render() {
		
		const logName = 'App.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state, 'this.state', logAllow);
		Logger.log(kfuLogoSmall, 'kfuLogoSmall', logAllow);
		Logger.log(navigator.language, 'navigator.language', logAllow);
		Logger.log(i18next.language, 'i18next.language', logAllow);
		
		const user = this.state.user;
		
		const menuItems = user ? this.menuItems[user.role_alias] : [];
		
		const currentLangAlias = this.getCurrentLangAlias();
		const lang = this.langs[currentLangAlias];
		const langCode = lang ? lang.code : Config.defaultLangAlias;
		
		const classNameArray = ['App'];
		
		classNameArray.push('lang-' + langCode);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={classNameArray.join(' ')}>
				
				<Preloader show={this.state.preloaderIsShown}/>
				
				{(this.state.showBreakpointIndicator) &&
					<BootstrapBreakpointIndicator/>
				}
				
				{user ?
					
					<div className={'skin-' + this.state.currentSkinAlias}>
						
						<div className={'signed-user-page'} data-user-role-alias={user.role_alias}>
							
							<div className={`container-fluid role-${user.role_alias}`}>
								
								<div className="row">
									
									<div className="aside-col col-auto d-none d-md-block">
										
										<div className="aside-box">
											
											{(Config.instituteAlias == Config.INSTITUTE_ALIAS_KFU) &&
												<>
													<Aside
														items={menuItems}
														langCode={this.state.lang}
														langs={this.langs}
														changeLang={this.changeLang}
													/>
													
													{/*<Aside2
														items={menuItems}
														langCode={this.state.lang}
														langs={this.langs}
														changeLang={this.changeLang}
													/>*/}
												</>
											}
											
											{(Config.instituteAlias == Config.INSTITUTE_ALIAS_ANKARA) &&
												<AnkaraAside
													items={menuItems}
													langCode={this.state.lang}
													langs={this.langs}
													changeLang={this.changeLang}
												/>
											}
										
										</div>
									
									</div>
									
									<div className="page-content-col col">
										
										<div className="top-panel">
											
											<div className="row">
												
												<div className="col-auto breadcrumbs-col">
													
													<div className="breadcrumbs d-none d-md-block">
														
														<Link
															to={'/'}
															onClick={(event) => {
																this.breadcrumbs.wipe();
															}}
															className={[
																'item',
																'breadcrumbs-item',
																'home-link',
															].join(' ')}
														>
															{/*<img src={homeCrumbs} alt="home-crumbs"/>*/}
														</Link>
														
														{this.state.breadcrumbs.map((data) => {
															return (
																<span className={'item'}>
                                                                    <span className={'sep'}>&mdash;</span>
                                                                    <Link
																		className={'link'}
																		to={data['url']}
																		onClick={data['onClick']}
																	>{i18next.t(data['name'])}</Link>
                                                                </span>
															);
														})}
													
													</div>
													
													<img
														src={menuBtn}
														className={'mobile-menu-btn d-md-none'}
														alt={'mobile-menu-btn'}
														onClick={this.mobileMenu.toggle}
													/>
												
												</div>
												
												<div className="col user-col text-right">
													
													<UserMenuBox
														edit={() => {
															this.setState((prevState) => {
																return {
																	editProfileFormModalIsOpen: true,
																}
															});
														}}
														user={user}
														logout={this.logout}
														skins={this.skins.list}
														setSkin={this.skins.set}
														toggleBreakpointIndicator={() => {
															this.setState((prevState) => {
																return {
																	showBreakpointIndicator: !prevState.showBreakpointIndicator,
																}
															});
														}}
													/>
												
												</div>
											
											</div>
										
										</div>
										
										<Switch>
											
											<Route path={'/editor'}>
												<EditorsPage/>
											</Route>

											<Route path = {'/chat'}>
												<ChatPage/>
											</Route>
											
											<Route path={'/question/:questionId'}>
												<QuestionPage
													user={user}
													preloader={this.preloader}
												/>
											</Route>
											
											<Route path={'/material/:materialId'}>
												<MaterialPage
													user={user}
													preloader={this.preloader}
												/>
											</Route>
											
											<Route path={'/student/:id'}>
												<StudentViewPage
													user={user}
													preloader={this.preloader}
												/>
											</Route>
											
											<Route path={'/answer/:id'}>
												<StudentViewPage
													user={user}
													preloader={this.preloader}
												/>
											</Route>
											
										</Switch>
										
										{['owner'].includes(user.role_alias) && (
											
											<Switch>
												
												<Route path={'/skin-test'}>
													<SkinTestPage/>
												</Route>
												
												<Route path={'/question-manager'}>
													
													<QuestionManager
														alert={this.alert}
														preloader={this.preloader}
													/>
													
												</Route>
												
											</Switch>
											
										)}
										
										{['owner', 'admin'].includes(user.role_alias) &&
											
											<Switch>
												
												<Route path={'/'} exact>
													<AdminHomePage
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/theme/:themeId'}>
													{/*	todo materials page with routing */}
												</Route>
												
												<Route path={'/courses'}>
													<CoursesPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
														skins={this.skins.list}
													/>
												</Route>
												
												<Route path={'/trainers'}>
													<TrainersPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/students'}>
													<StudentsPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/teams'}>
													<TeamsPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
													/>
												</Route>
												
												<Route path={'/calendar'}>
													<CalendarPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														langCode={langCode}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/material-:materialId-by-user-:userId'}>
													<UserMaterialPage
														preloader={this.preloader}
														user={user}
													/>
												</Route>
											
											</Switch>
											
										}
										
										{['teacher'].includes(user.role_alias) &&
											
											<Switch>
												
												<Route path={'/'} exact>
													<TeacherHomePage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/courses'}>
													<CoursesPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
														skins={this.skins.list}
													/>
												</Route>
												
												<Route path={'/trainers'}>
													<TrainersPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/students'}>
													<StudentsPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/calendar'}>
													<CalendarPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														langCode={langCode}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/material-:materialId-by-user-:userId'}>
													<UserMaterialPage
														preloader={this.preloader}
														user={user}
													/>
												</Route>
											
											</Switch>
											
										}
										
										{['student'].includes(user.role_alias) &&
											
											<Switch>
												
												<Route path={'/'} exact>
													<StudentHomePage
														user={user}
														preloader={this.preloader}
														alert={this.alert}
													/>
												</Route>
												
												<Route path={'/courses'}>
													<CoursesPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
														skins={this.skins.list}
													/>
												</Route>
												
												<Route path={'/trainers'}>
													<TrainersPage
														preloader={this.preloader}
														breadcrumbs={this.breadcrumbs}
														user={user}
														alert={this.alert}
													/>
												</Route>
											
											</Switch>
											
										}
										
									</div>
								
								</div>
							
							</div>
							
							{/*<SberGigaChatSummoner
								onClick={() => {
									this.sberGigaChat.show()
								}}
							/>*/}
							
							<SberGigaChatModal
								user={this.state.user}
								preloader={this.preloader}
								isOpen={this.state.sberGigaChatModalIsOpen}
								onHide={() => {
									this.sberGigaChat.hide()
								}}
							/>
							
						</div>
						
					</div>
					
					:
					
					<div className={'unsigned-user-page'}>
						
						<div className="container-fluid">
							
							<Switch>
								
								<Route path={'/'} exact>
									
									{(Config.instituteAlias == Config.INSTITUTE_ALIAS_KFU) && (
										<LandingPageOldLMA
											appUserManager={this.user}
										/>
									)}
									
									{(Config.instituteAlias == Config.INSTITUTE_ALIAS_ANKARA) && (
										<AnkaraLoginPage
											login={this.login}
											langs={this.langs}
											changeLang={this.changeLang}
											currentLangAlias={this.state.lang}
										/>
									)}
								
								</Route>
								
								<Route path={'/login'}>
									
									{(Config.instituteAlias == Config.INSTITUTE_ALIAS_KFU) && (
										<LoginPage
											login={this.login}
											langs={this.langs}
											changeLang={this.changeLang}
											currentLangAlias={this.state.lang}
											startDemo={() => {
												this.user.startDemo()
											}}
										/>
									)}
									
									{(Config.instituteAlias == Config.INSTITUTE_ALIAS_ANKARA) && (
										<AnkaraLoginPage
											login={this.login}
											langs={this.langs}
											changeLang={this.changeLang}
											currentLangAlias={this.state.lang}
										/>
									)}
									
								</Route>
								
							</Switch>
						
						</div>
					
					</div>
					
				}
				
				{(this.state.mobileMenuShown) &&
					
					<MobileMenu
						
						items={menuItems}
						currentItemAlias={this.state.currentMenuItemAlias}
						toggle={this.mobileMenu.toggle}
						logo={kfuLogoSmall}
						logoUrl={Config.kfuUrl}
						langs={this.langs}
						
						infoIcon={infoIcon}
						closeIcon={closeBtnIcon}
					
					/>
					
				}
				
				<div className="modals">

					{user && (user.role_alias === 'student' &&

						<Modal
							className={[
								'user-profile-modal',
							].join(' ')}
							show={this.state.editProfileFormModalIsOpen}
							onHide={() => {
								this.setState((prevState) => {
									return {
										editProfileFormModalIsOpen: !prevState.editProfileFormModalIsOpen,
									}
								});
							}}
							size={'lg'}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{i18next.t("Edit profile")}
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
								<StudentForm
									groups={[]}
									cancel={() => {
									this.setState((prevState) => {
										return {
											editProfileFormModalIsOpen: !prevState.editProfileFormModalIsOpen,
										}
									});
								}}
									model={user}
								/>
							</Modal.Body>
							
						</Modal>

					)}

					<AlertModal
						isOpen={this.state.alertModalIsOpen}
						title={this.state.alertTitle}
						body={this.state.alertBody}
						cancel={this.alert.cancel}
						confirm={this.alert.confirm}
						useCancel={this.state.alertUseCancel}
						confirmText={this.state.alertConfirmText}
						cancelText={this.state.alertCancelText}
					/>
				
				</div>
				
				{/*{footer}*/}
				
			</div>
		
		);

	}
	
}

export default withTranslation()(App);